import React, { useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleSidebar } from '../../../features/sideNav/sideNavSlice';


const MobileSideNav = () => {

    const dispatch = useDispatch();
    const { logo } = useSelector(state => state.siteInfo);
    const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;
    const { user } = useSelector(state => state.auth);
    const showNav = useSelector((state) => state.sideNav.showNav);



    useEffect(() => {
        if (
            showNav === true 
        ) {
            document.body.style.overflow = "hidden";
            return () => (document.body.style.overflow = "scroll");
        }
    });

    return (
        <>
            <div className={` ${showNav ? 'scale-x-100':'scale-x-0'} duration-300 origin-left fixed z-50 bg-white top-0 w-screen h-screen md:hidden rounded-b-lg `}>
                <div className={`mt-7 mb-7 flex justify-between px-10`}>
                    <img src={siteLogo} alt="" className='h-10 w-15 mr-2' />
              
                    <button>
                        <MdOutlineClose onClick={() => dispatch(handleSidebar())} className='w-8 h-8 duration-300' />
                    </button>
                </div>
                <div className={` text-center`}>
                    <ul className=' space-y-5 mt-7'>
                    {
                                user !== undefined 
                                ?
                                <>
                                    <Link to="/user/account/dashboard" className="py-2 px-2 font-medium text-white bg-[#292d60] rounded-md hover:bg-[#1e2158] transition duration-300">Dashboard</Link>
                                </>
                                :
                                <>
                                 <div className='px-5 space-x-5'>
                                 <a href="/login" className="py-2 px-2 font-medium  rounded-md hover:bg-[#292d60] hover:text-white transition duration-300">Login</a>
                                    <a className="py-2 px-2 font-medium text-white bg-[#292d60] rounded-md hover:bg-[#1e2158] transition duration-300" href="/register">Sign Up</a>
                                 </div>
                                </>
                            }
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MobileSideNav;