import React from 'react';
import { useGetExamYearsQuery } from '../../../../features/exams/examsAPI';
import ExamFilterSkeleton from '../../../ui/Skeleton/ExamFilterSkeleton';
import ExamYear from './ExamYear';

const ExamYears = ({filterHandler}) => {

    const { data, isLoading, isError } = useGetExamYearsQuery();
    const { data: responseData } = data || {};
    const { data: examYears } = responseData || {};

    let content = null;

    if (isLoading) {
        content = <>
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                </>
    } else if (!isLoading && !isError && examYears?.length == 0) {
        content = <li className="flex flex-col mb-4 text-red-500 font-medium">No Years Found!</li>;
    } else if (!isLoading && !isError && examYears?.length > 0) {
        content = examYears.map(examYear => <ExamYear key={examYear.id} examYear={examYear} />);
    }

    return (
        <ul className='mt-3 -mb-4 max-h-[300px] overflow-y-scroll'>
            {
                content
            }
        </ul>
    );
};

export default ExamYears;