import axios from 'axios';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import AlertMessage from '../../../components/ui/AlertMessage';
import { useState } from 'react';



const VerifyOtp = () => {
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');
    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { atob } = window;

    const { phone } = useParams();
    const phoneNumber = atob(phone);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const firstDigit = document.getElementById('first').value;
        const secondDigit = document.getElementById('second').value;
        const thirdDigit = document.getElementById('third').value;
        const fourthDigit = document.getElementById('fourth').value;
        const otpCode = firstDigit + secondDigit + thirdDigit + fourthDigit;

        const data = { code: otpCode };
        try {
            const response = await axios.put(`${baseUrl}/api/v1/verify-otp/${phoneNumber ?? ''}`, data);
            // console.log('Registration successful:', response.data);
            document.getElementById('first').value = "";
            document.getElementById('second').value = "";
            document.getElementById('third').value = "";
            document.getElementById('fourth').value = "";

          if(response.data?.status === 1){
            setAlertMessage(response.data?.message);
            setAlertMessageType('success');
            window.location.href = `/login`;
          }else{
            setAlertMessage(response.data?.message);
            setAlertMessageType('error');
            setTimeout(() => setAlertMessage(''), 5000);
          }

        } catch (error) {
            console.error('Registration failed:', error);
            setAlertMessage(error?.response?.data?.message);
            setAlertMessageType('error');
            setTimeout(() => setAlertMessage(''), 5000);
        }
    };

    const handleResendOtp = async () => {
        const data = { phone: phoneNumber };
        try {
            const response = await axios.put(`${baseUrl}/api/v1/resend-otp/${phoneNumber ?? ''}`, data);
            // console.log('resend otp successful:', response.data);
            document.getElementById('first').value = "";
            document.getElementById('second').value = "";
            document.getElementById('third').value = "";
            document.getElementById('fourth').value = "";

            setAlertMessage(response.data?.message);
            setAlertMessageType('success');
            setTimeout(() => setAlertMessage(''), 5000);
        } catch (error) {
            console.error('resend otp failed:', error);
            setAlertMessage(error?.response?.data?.message);
            setAlertMessageType('error');
            setTimeout(() => setAlertMessage(''), 5000);
        }
    }

    return (
        <div>
            <div className="h-screen bg-blue-500 py-20 px-3">
                <div className="container mx-auto">
                    <div className="max-w-sm mx-auto md:max-w-lg">
                        <div className="w-full">
                            <div className="bg-white h-fit py-4 rounded text-center">
                                <h1 class="text-2xl font-bold">OTP Verification</h1>
                                {
                                    alertMessage !== '' && <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage} /></div>
                                }
                                <div className="flex flex-col mt-4">
                                    <span>Enter the OTP you received on your phone</span>
                                    <span class="font-bold">+880{phoneNumber}</span>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div id="otp" class="flex flex-row justify-center text-center px-2 mt-5">
                                        <input class="m-2 border h-10 w-10 text-center form-control rounded" type="text" onInput={()=> document.getElementById('second').focus()} id="first" maxlength="1" />
                                        <input class="m-2 border h-10 w-10 text-center form-control rounded" type="text" onInput={()=> document.getElementById('third').focus()} id="second" maxlength="1" />
                                        <input class="m-2 border h-10 w-10 text-center form-control rounded" type="text" onInput={()=> document.getElementById('fourth').focus()} id="third" maxlength="1" />
                                        <input class="m-2 border h-10 w-10 text-center form-control rounded" type="text" onInput={()=> document.getElementById('submit').focus()}id="fourth" maxlength="1" />

                                    </div>
                                    <button type='submit' id='submit' className='text-gray-100 font-bold bg-green-600 hover:bg-green-500 p-2 rounded ' >Submit</button>
                                </form>

                                <div className="flex justify-center text-center mt-5">
                                    <button onClick={handleResendOtp} class="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer"><span class="font-bold">Resend OTP</span><i class='bx bx-caret-right ml-1'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyOtp;