import moment from 'moment';
import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetCourseDetailsQuery } from '../../../../features/courses/coursesAPI';
import SEO from '../../../ui/SEO';
import TextSkeleton from '../../../ui/Skeleton/TextSkeleton';
import VideoSkeleton from '../../../ui/Skeleton/VideoSkeleton';
import VideoSkeletonNotFound from '../../../ui/Skeleton/VideoSkeletonNotFound';
import ReactPlayer from 'react-player';

const Intro = () => {
    
    const { slug } = useParams();

    const { data, isLoading, isError } = useGetCourseDetailsQuery(slug);
    const { data: course } = data || {};
    const {
        title,
        intro_video: introVideo,
        short_description: shortDescription,
        published_at: publishedAt
    } = course || {};


    const playerConfig = {
        file: {
          attributes: {
            controlsList: 'nodownload'
          }
        },
        autoplay: false
      };
      


    let introContent = null;

    if (isLoading) {
        introContent =  <>
                            <VideoSkeleton />
                            <div className='flex flex-col gap-3'>
                                <TextSkeleton height='30px' width='100%' />
                                <TextSkeleton height='15px' />
                            </div>
                            <TextSkeleton height='20px' width='100%' />
                            <TextSkeleton height='20px' width='100%' />
                        </>
    } else if (!isLoading && isError) {
        introContent =  <>
                            <VideoSkeletonNotFound />
                        </>
    } else if (!isLoading && !isError && data?.status === 1) {
        introContent =  <>
                            <SEO 
                                title={title}
                                description={`Playing ${title}`}
                                url={`/user/account/courses/${slug}/playlist`}
                            />
                            <div className='flex flex-col gap-5'>
                            <ReactPlayer
                            url={`${introVideo}`}
                            playing={false}
                            controls={true}
                            config={playerConfig}
                            h-400
                            width=""
                            />
                                {/* <iframe height="400" src={introVideo} title={title} frameBorder="0" allowFullScreen allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe> */}
                                <div className='flex flex-col gap-3'>
                                    <h2 className='text-2xl font-medium'>{title}</h2>
                                    <small className='font-medium bg-gray-200 w-fit py-1 px-2 rounded-xl'>{ moment(publishedAt).format('MMMM D YYYY, h:mm A') }</small>
                                </div>
                                <p>
                                    {shortDescription}
                                </p>
                            </div>
                        </>;
    }

    return introContent;
}

export default Intro