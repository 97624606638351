import React from 'react'
import CertificateItem from './CertificateItem';

const Certificates = ({certificates}) => {
console.log(certificates);
    let tableRows = null;

    if (certificates === undefined) {
        tableRows = <tr><td colSpan={8} className='text-center text-blue-200 text-lg text-amaranth p-4 font-medium'>Loading...</td></tr>;
    } else if (certificates?.length === 0) {
        tableRows = <tr><td colSpan={8} className='text-center text-red-200 text-lg text-amaranth p-4 font-medium'>No Enrollments Found!</td></tr>;
    } else if(certificates?.length > 0) {
        tableRows = certificates.map((certificate, index) => <CertificateItem key={certificate.id} snNo={index + 1} certificate={certificate} />);
    }





    return (
        <table className="min-w-full">
            <thead className="border-b bg-slate-200">
                <tr>
                    <th width="4%" scope="col" className="text-sm font-semibold text-gray-900 py-4 text-center">
                        SN
                    </th>
                    <th  scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-center">
                        Student
                    </th>
                    <th  scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-center">
                        Course Name
                    </th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-center">
                        Certificates
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    tableRows
                }
            </tbody>
        </table>
    )
}

export default Certificates