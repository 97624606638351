import React from 'react'
import SEO from '../../../components/ui/SEO'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Pagination from '../../../components/ui/Pagination';
import Search from '../../../components/account/enrollment/Search';
import debounceHander from '../../../utils/debounceHandler';
import { certificatesAPI, useGetCertificatesQuery } from '../../../features/certificates/certificatesAPI';
import Certificates from '../../../components/account/certificate/Certificates';

const CertificateList = () => {

    const [search, setSearch] = useState('');
    const [paginatePage, setPaginatePage] = useState(1);
    const dispatch = useDispatch();

    const { certificates, currentPage, perPage, totalCertificates } = useGetCertificatesQuery({search, page: paginatePage}, {
        selectFromResult: ({data}) => ({
            certificates: data?.certificates,
            currentPage: data?.currentPage,
            perPage: data?.perPage,
            totalCertificates: data?.totalCertificates,
        })
    });


    // console.log(totalCertificates)





    const doSearch = (query) => {
        setSearch(query);
        dispatch(certificatesAPI.endpoints.getCertificates.initiate({search: query, page: 1}));
    }

    const searchHandler = debounceHander(doSearch, 50);

    



    const handlePaginate = (page) => {
        setPaginatePage(page);
        dispatch(certificatesAPI.endpoints.getCertificates.initiate({search, page}));
    }





    return (
        <>
            <SEO 
                title="Enrollment List"
                description="Enrollment List"
                url="/user/account/lesson/certificates"
            />
            <div className='flex flex-col gap-4'>
                <h3 className='text-xl text-black font-medium'>Certificates List</h3>
                <Search search={search} searchHandler={searchHandler} />
                <Certificates certificates={certificates} />
                {
                     perPage < totalCertificates &&
                    <Pagination currentPage={currentPage} perPage={perPage} totalItems={totalCertificates} handlePaginate={handlePaginate} />
                }
               
            </div>
        </>
    )
}

export default CertificateList