import { apiSlice } from "../api/apiSlice";


export const blogsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBlogs: builder.query({
            query: (slug) => `/api/v1/blogs?page=${slug}`,
        }),
        getSingleBlogs : builder.query({
            query: (slug) => `/api/v1/blogs/${slug}`,
        })
    })
});

export const { 
    useGetBlogsQuery,
    useGetSingleBlogsQuery
} = blogsAPI;