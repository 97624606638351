import React from 'react'
import { BiErrorAlt } from "react-icons/bi";

const VideoSkeletonNotFound = ({ height = '400px', width = '100%' }) => {
    return (
        <div className='bg-gray-300 flex flex-col items-center justify-center animate-pulse' style={{ height, width }}>
            <BiErrorAlt className='text-[70px] text-gray-400' />
            <p className='text-gray-500'>Someting went wrong!</p>
        </div>
    )
}

export default VideoSkeletonNotFound