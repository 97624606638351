import React, { useState } from 'react'
import { GrClose } from "react-icons/gr";

const AlertMessage = ({type, message}) => {
    const [visibility, setVisibility] = useState(true);

    type = type.toLowerCase();

    return visibility && (
        <div 
            className={`
                font-medium rounded-lg mb-6 p-3 w-full relative mt-20 md:mt-0 
                ${!visibility && 'hidden'}
                ${
                    type === 'success' 
                    ? 'bg-teal-100 text-teal-700' 
                    : (type === 'info' 
                    ? 'bg-indigo-100 text-indigo-700' 
                    : (type === 'warning' 
                    ? 'bg-orange-100 text-orange-700' 
                    : (type === 'error' 
                    ? 'bg-red-100 text-red-700' 
                    : '')))
                }
            `}
        >
            {message}
            <GrClose 
                onClick={() => setVisibility(false)} 
                className='absolute top-4 right-2.5 cursor-pointer' 
            />
        </div>
    )
}

export default AlertMessage