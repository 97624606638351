import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetCourseInstructorsQuery } from '../../../../features/courses/coursesAPI';
import Instructor from './Instructor';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';

const Instructors = () => {

    
    const { slug } = useParams();
  

    

    const { data, isLoading, isError } = useGetCourseInstructorsQuery(slug);
    const { data: courseInstructors } = data || {};


    let instructorContent = null;

    if (isLoading) {
        instructorContent = <>
        
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
       
         
        
         </>
    } else if (!isLoading && isError) {
        instructorContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseInstructors?.length === 0) {
        instructorContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No instructors found!</div>;
    } else if (!isLoading && !isError && courseInstructors?.length > 0) {
        instructorContent = courseInstructors.map(courseInstructor => <Instructor key={courseInstructor.id} courseInstructor={courseInstructor}  />)
    }
    


    return (
        <div>
            <h1 className='text-2xl mb-1'>Course Instructor</h1>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
                {
                    instructorContent
                }
            </div>
        </div>
    )
}

export default Instructors