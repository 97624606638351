import React from 'react';
import { useGetAboutInfoQuery } from '../../features/about/aboutAPI';
import LatestCourseItem from '../Home/Course/Latest/LatestCourseItem';
import SEO from '../ui/SEO';
import AboutItem from './AboutItem';
import ReactPlayer from 'react-player';


const About = () => {

    const { data, isLoading, isError } = useGetAboutInfoQuery();
    const { data: responseData } = data || {};
    const { about_counts: aboutCounts, title, short_description, image } = responseData || {};

    const aboutImage = process.env.REACT_APP_BASE_URL + '/' + image;





    let aboutItems = null;

    if (!isLoading && !isError && aboutCounts?.length > 0) {
        aboutItems = aboutCounts.map(aboutCount => <AboutItem key={aboutCount.id} aboutCount={aboutCount} />);
    }





    return (
        <>
            <div className='pt-10 mb-14 mx-auto max-w-[1265px]'>
                <div>
                {/* <ReactPlayer url="https://edu-tv-videos.s3.ap-south-1.amazonaws.com/video_646de6298ca03.mp4" controls /> */}

                    {/* using grid */}
                    <div className="grid lg:grid-cols-2 gap-10">
                        <div className='m-auto'>
                            <h1 className='text-2xl text-[#292d60] font-semibold mb-2 text-center uppercase'>{title}</h1>
                            <p className='mb-10 text-gray-400'>{short_description}</p>
                            <div className='w-full'>
                                <div className='grid grid-cols-2 gap-10 mb-4'>
                                    {
                                        aboutItems
                                    }
                                </div>
                            </div>
                        </div>



                        <div>
                            <img src={aboutImage} alt="about" className=' h-full w-[80%] m-auto' />
                        </div>

                    </div>





                    {/* <div className='lg:flex space-x-10'>
                        <div className="lg:w-1/2 m-auto">
                            <h1 className='text-2xl font-semibold mb-2 text-center'>{title}</h1>
                            <p className='mb-10 text-gray-400'>{short_description}</p>
                            <div className='w-full'>
                                <div className='grid grid-cols-2 gap-10 mb-4'>
                                    {
                                        aboutItems
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-1/2 m-auto'>
                            <img src={aboutImage} alt="about" className=' max-h-fit w-full float-right' />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default About;