import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { userLoggedIn } from "../features/auth/authSlice";

export default function useAuthCheck() {

    const [cookies] = useCookies(['EduTvAuth']);
    const dispatch = useDispatch();
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        const auth = cookies?.EduTvAuth;

        if (auth) {

            if (auth?.accessToken && auth?.user) {
                dispatch(userLoggedIn({
                    accessToken: auth.accessToken,
                    user: auth.user
                }));
            }
        }

        setAuthChecked(true);
    }, [dispatch, setAuthChecked]);

    return authChecked;
}