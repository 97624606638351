import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useGetCourseTagsQuery } from '../../../../features/courses/coursesAPI';
import TagCourses from './TagCourses';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';

const Tags = () => {

    const { data, isLoading, isError } = useGetCourseTagsQuery();
    const { data: courseTags } = data || {};

    let tagContent = null;

    if (isLoading) {
        tagContent =
            <>
                <div className="max-w-[1265px] mx-auto grid grid-cols-4 gap-10">
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                </div>
            </>


    } else if (!isLoading && !isError && courseTags?.length > 0) {
        tagContent = courseTags.map(courseTag => {
            const { id, name } = courseTag?.tag;

            return (
                <div key={id} className='pb-7 mx-auto max-w-[1265px]'>
                    <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5 text-[#292d60]'>
                        {name} Courses
                        <a className='float-right mr-5 font-normal normal-case text-[#292d60]' href={`${id}-courses`}>
                            view all
                            <FaArrowRight className='inline h-3 mb-1' />
                        </a>
                    </h1>
                    <TagCourses tagId={id} />
                </div>
            )
        })
    }


    return tagContent;
}

export default Tags