import { apiSlice } from "../api/apiSlice";


export const searchAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSearch: builder.query({
            query: (title) => `/api/v1/courses/search/${title}`,
        }),
    })
});

export const { 
    useGetSearchQuery
} = searchAPI;