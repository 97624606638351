import { useCookies } from "react-cookie";
import { apiSlice } from "../api/apiSlice";
import { calculateCartAmounts, clearCartItemsAfterCheckoutSuccess, setCouponInfo } from "../cart/cartSlice";
import { setInvoiceNo } from "./checkoutSlice";
import { useEffect } from "react";


export const checkoutAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPaymentMethods: builder.query({
            query: () => '/api/v1/payment-methods',
        }),
        storeEnrollment: builder.mutation({
            query: (data) => ({
                url: '/api/v1/enrollments/store',
                method: "POST",
                body: data,
               
            }),
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const { data } = await queryFulfilled || {};
                    const { status, data: enrollmentData } = data || {};
                    
                    if (status) {
                        dispatch(clearCartItemsAfterCheckoutSuccess());
                        dispatch(calculateCartAmounts());
                        dispatch(setCouponInfo({id: null, code: null, amount: 0, percentage:0}));
                        dispatch(setInvoiceNo(enrollmentData));
                    }
                } catch (error) {
                    // CATCH FROM UI
                }
            }
        }),
        updateEnrollment: builder.mutation({
            query: (id,data) => ({
                url: `/api/v1/enrollments/update/${id}`,
                method: 'PUT',
                body: data,
              
            }),
            
        }),
    })
});

export const { 
    useGetPaymentMethodsQuery,
    useStoreEnrollmentMutation,
    useUpdateEnrollmentMutation
} = checkoutAPI;