import React from 'react';

const StudentFeadback = ({comments}) => {
    const {comment, student } = comments || {};

    const {first_name, last_name} = student || {};
    const fullName = first_name + ' ' + last_name ;
    return (
        <div className='py-2 px-2'>
            <h1 className='font-semibold'>{fullName}</h1>
            <p>{comment}</p>
        </div>
    );
};

export default StudentFeadback;