import React from 'react'

const InvoiceItem = ({snNo, enrollmentItem}) => {

    const { id, course, regular_fee: regularFee, course_fee: courseFee, discount_amount: discountAmount, quantity, total_fee: totalFee } = enrollmentItem || {};


    return (
        <tr className="border-b">
            <td className="py-4 whitespace-nowrap text-sm font-medium text-center text-gray-900">
                {snNo}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap">
                {course.title}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {regularFee}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {discountAmount}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {courseFee}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {quantity}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {totalFee}
            </td>
         
        </tr>
    )
}

export default InvoiceItem