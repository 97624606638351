import React, { useState } from 'react'
import Slider from 'react-slick';
import TagCourseItem from './TagCourseItem';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useGetCoursesByTagQuery } from '../../../../features/courses/coursesAPI';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';

const TagCourses = ({ tagId }) => {

    const [sliderSettings] = useState({
        touchThreshold: 100,
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <FaAngleRight />,
        prevArrow: <FaAngleLeft />,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots__hottype ">
                <div className="loading" />
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 2,
                    arrows: true,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },

        ]
    });





    const { data, isLoading, isError } = useGetCoursesByTagQuery(tagId);
    const { data: responseData } = data || {};
    const { data: tagCourses } = responseData || {};


    let tagCoursesItems = null;

    if (isLoading) {
        tagCoursesItems =   <>
        <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
        </div>
    </>
        sliderSettings.infinite = false;
    } else if (!isLoading && isError) {
        tagCoursesItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && tagCourses?.length === 0) {
        tagCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && tagCourses?.length > 0) {
        tagCoursesItems = tagCourses.map(tagCourse => <TagCourseItem key={tagCourse.id} tagCourse={tagCourse} />);

        if (
            (tagCourses.length <= 4 && sliderSettings.responsive[0].breakpoint === 1024) ||
            (tagCourses.length <= 3 && sliderSettings.responsive[1].breakpoint === 1023) ||
            (tagCourses.length <= 2 && sliderSettings.responsive[2].breakpoint === 899)
        ) {
            sliderSettings.infinite = false
        }
       

    }



    return (
        <Slider className='px-5' {...sliderSettings}>
            {
                tagCoursesItems
            }
        </Slider>
    );
}

export default TagCourses