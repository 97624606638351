import { apiSlice } from "../api/apiSlice";

export const accountAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardCountings: builder.query({
            query: () => `/api/v1/account/dashboard-countings`,
        }),
    })
});

export const { 
    useGetDashboardCountingsQuery
} = accountAPI;