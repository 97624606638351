import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Swal from 'sweetalert2';

const Chapters = ({examSlug, chapters, quizzes}) => {

    const [cookies] = useCookies(['EduTvAuth']);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    const { user } = useSelector(state => state.auth);

   

    return (
        <div className='block w-full overflow-x-auto'>
            <table className='items-center bg-transparent w-full'>
                <thead>
                    <tr>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left w-2">
                            Chapter
                        </th>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left">
                            Chapter Title
                        </th>
                        <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-1 whitespace-nowrap font-semibold text-left w-4">
                            No of Question
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        chapters?.length > 0 &&
                        chapters.map((chapter, index) => {
                            localStorage.setItem('examDuration', JSON.stringify(chapter?.duration));
                       
                            const {id, name, slug, totalChapterQuiz} = chapter;
                            const chapterId = id;
                            const numberOfQuiz = totalChapterQuiz;
                            const auth = cookies?.EduTvAuth;
                        

                            function handleLinkClick(event) {
                                event.preventDefault();
                                const data = {
                                    chapter_id: chapterId,
                                    total_quiz: numberOfQuiz,
                                    quizzes :quizzes
                                }
                                localStorage.setItem('QuizTestData', JSON.stringify(data));
                                const token = auth?.accessToken;
                                fetch(`${baseUrl}/api/v1/exam/exam-quiz-test`, {
                                  method: 'POST',
                                  headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                  },
                                  body: JSON.stringify(data)
                                })
                                  .then(response => response.json())
                                  .then(result => {
                             
                                    localStorage.setItem('QuizTestId', JSON.stringify(result.user.id));
                                  })
                                  .catch(error => {
                                    console.error(error);
                                  });
                              }


                              const handleClick = () => {
                                Swal.fire({

                                    icon: 'error',
                                    html: '<p>Please <a className="bg-red-600" href="/login">log in</a> or <a href="/register">register</a> to continue.</p>',
                                    showCloseButton: true,
                                  })
                              };


                            return (
                                <tr key={id}>
                                    <td className="border px-6 align-middle text-xs text-center whitespace-nowrap p-4 ">
                                        {index + 1}
                                    </td>
                                    <td className="border px-6 align-middle text-xs whitespace-nowrap p-4 text-left text-blueGray-700 ">
                                   {
                                    user !== undefined ?
                                    <>
                                    <button onClick={handleLinkClick}>
                                      <Link to={`/exams/${examSlug}/chapters/${slug}`}>{name}</Link>
                                      </button>
                                    </>
                                    :
                                    <>
                                    <button onClick={handleClick}>
                                      {name}
                                      </button>
                                    </>
                                   }
                                     
                                   
                                    </td>
                                    <td className="border px-6 align-right text-xs text-center whitespace-nowrap p-4">
                                        {totalChapterQuiz}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Chapters