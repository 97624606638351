import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Chapters from '../../components/Exam/Chapter/Chapters';
import SEO from '../../components/ui/SEO';
import { useGetExamDetailsQuery } from '../../features/exams/examsAPI';
import ExamdetailsSkeleton from '../../components/ui/Skeleton/ExamdetailsSkeleton';

const ExamDetails = () => {

    const { slug } = useParams();
    const [toggleButton, setToggleButton] = useState(1);


    const toggleTabButton = (index) => {
        setToggleButton(index);
    }


    const { data, isLoading, isError } = useGetExamDetailsQuery(slug);
    const { data: exam, status } = data || {};


    const {
        title,
        description,
        exam_fee: examFee,
        exam_category: category,
        institute,
        exam_year: examYear,
        exam_chapters: examChapters,
        totalExamQuiz
    } = exam || {};


    let examDetailsContent = null;
    if(isLoading){
        examDetailsContent =  <ExamdetailsSkeleton/>
    }

    if (!isLoading && !isError && status === 1) {
        examDetailsContent =
          <>
             <SEO
                title={`Exam Details - ${title}`}
                url={`/exams/${slug}`}
            />
            <div key={exam.id} className='lg:mx-20'>
                <div className='bg-[#29303B] text-white'>
                    <div className='container'>
                        <p className='lg:w-[65%] p-10 leading-8'>{description}</p>
                    </div>
                </div>
                <div className='flex gap-5'>
                    <div className='w-full lg:w-[72%] border overflow-x-auto'>
                        <ul className='uppercase flex space-x-4 p-4 border-b'>
                            <button onClick={() => toggleTabButton(1)} className={`${toggleButton === 1 ? 'text-blue-700' : ''}`}>Content</button>
                            <button onClick={() => toggleTabButton(2)} className={`${toggleButton === 2 ? 'text-blue-700' : ''}`}>Ranking</button>
                            <button onClick={() => toggleTabButton(3)} className={`${toggleButton === 3 ? 'text-blue-700' : ''}`}>Result</button>
                        </ul>
                        <div className={`${toggleButton === 1 ? 'block' : 'invisible hidden'} p-4`}>
                            <Chapters examSlug={slug} chapters={examChapters} quizzes={exam?.exam_quizzes} />
                        </div>
                        <div className={`${toggleButton === 2 ? 'block' : 'invisible hidden'} p-4`}>
                            <h2>No Ranking Show Here</h2>
                        </div>
                        <div className={`${toggleButton === 3 ? 'block' : 'invisible hidden'} p-4`}>
                            <h2>No Result Show Here</h2>
                        </div>
                    </div>
                    <div className='w-full lg:w-[28%] -mt-10 bg-white p-5 mr-0.5'>
                        <div className='relative h-[380px] mt-8'>
                            <Link to={`/exams/${slug}`} className='absolute w-full z-10 flex justify-between h-full bg-white border-2 border-black'>
                                <div className='w-3 h-full bg-slate-800'></div>
                                <div className='flex flex-col items-center justify-between w-full relative'>
                                    <small className={`absolute top-2 text-[10px] right-2 font-semibold py-1 px-2.5 rounded-xl ${examFee === 0 ? 'bg-teal-300 text-teal-900' : 'bg-rose-500 text-white'}`}>
                                        {
                                            examFee === 0
                                                ? 'Free'
                                                : '৳ ' + examFee
                                        }
                                    </small>
                                    <div className='h-full w-full flex items-center justify-center'>
                                        <h1 className='text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-l from-indigo-900 to-cyan-600'>{category?.name}</h1>
                                    </div>
                                    <div className='flex w-full h-[200px] mb-20'>
                                        <div className='w-3 h-full bg-gradient-to-tr from-indigo-900 to-cyan-600 -ml-3'></div>
                                        <div className='flex flex-col items-center justify-center text-white bg-gradient-to-tr from-indigo-900 to-cyan-600 py-2 px-0.5 w-full'>
                                            <p className='text-center text-xl font-semibold'>{institute?.short_form}</p>
                                            <p className='text-center text-md font-semibold'>{title} &mdash; {examYear?.year}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className='absolute -skew-y-6 bg-white border-2 border-black -top-3.5 h-20 w-[90%] z-0'></div>
                        </div>
                        <h4 className='text-xl font-semibold my-3'>Overview</h4>
                        <hr />
                        <div className='flex justify-between py-1 mt-3 text-[13px]'>
                            <p className='text-slate-700'>Chapter</p>
                            <p>{examChapters.length}</p>
                        </div>
                        <div className='flex justify-between py-1 text-[13px]'>
                            <p className='text-slate-700'>Number of Question</p>
                            <p>
                                {
                                    totalExamQuiz
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </>
    }


    return examDetailsContent;
};

export default ExamDetails;