import React from 'react'
import { Link } from 'react-router-dom';
import ChildCategories from './ChildCategories';
import style from './Category.module.css';
import { IoIosArrowForward } from 'react-icons/io';

const ChildCategory = ({ category }) => {

    const { id, name,slug, childCount } = category || {};
  

    return (
        <li className={`py-1 px-2 ${style.subparent} ${style.parent}`} >
            <Link to={`/${slug}/courses`}>{name}</Link>
            {
                childCount > 0 ?
                   <> 
                   <ChildCategories parentId={id} />
                  <IoIosArrowForward className='inline '/>
                    </>
                    :
                    <></>

            }


        </li>
    )
}

export default ChildCategory