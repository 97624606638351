import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BsEye } from "react-icons/bs";
import demoAvater from '../../../assets/images/avatar.png';

import { useCookies } from 'react-cookie';


const EnrollmentItem = ({ snNo, enrollment }) => {
    const [enrolldata, setEnrollData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');


    const {
        id,
        invoice_no: invoiceNo,
        date,
        student,
        payment_method: paymentMethod,
        grand_total: grandTotal,
        // status,
        payment_status: paymentStatus
    } = enrollment || {};

  

    const [cookies] = useCookies(['EduTvAuth']);
  
    const auth = cookies?.EduTvAuth;
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;
   
       

        const handleUpdateClick = async () => {
            setIsLoading(true);
        
            try {
              const response = await fetch(`${baseUrl}/api/v1/enrollments/update/${id ?? ''}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  // Update your data here
                id :id,
                payment_status : 'Due'
                }),
              });
        
              const updatedData = await response.json();
              setEnrollData(updatedData);
              window.location.replace(`${process.env.REACT_APP_BASE_URL}/payment?invoice_no=${updatedData?.data}`);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false);
            }
          };


    const studentAvater = process.env.REACT_APP_BASE_URL + '/' + student?.image;



    return (
        <tr className="border-b">
            <td className="py-4 whitespace-nowrap text-sm font-medium text-center text-gray-900">
                {snNo}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap">
                <Link to={`/user/account/enrollments/${invoiceNo}`}>
                    {invoiceNo}
                </Link>
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-left">
                {date}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-left">
                <div className='flex items-center gap-2'>
                    <img src={(student?.image === 'assets/img/avatar.png' ||student?.image === null ) ? demoAvater : studentAvater} alt={student?.username} className='w-8 h-8 rounded-full' />
                    <div className='flex flex-col' style={{ lineHeight: '15px' }}>
                        <p>{student?.first_name} {student?.last_name}</p>
                        <small className='text-indigo-800 font-medium'>
                            {student?.username}
                        </small>
                    </div>
                </div>
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-left">
                {paymentMethod}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-right">
                {grandTotal}
            </td>
            <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-center">
                <span
                    className={`
                        py-1 px-2 text-[10px] rounded-full font-medium
                        ${paymentStatus === "Due"
                            ? 'bg-orange-200 text-orange-900'
                            : (paymentStatus === "Paid"
                                ? 'bg-teal-300 text-teal-900'
                                : '')
                        }
                    `}
                >
                    {
                        paymentStatus === "Due"

                            ? <>
                                {
                                    isLoading ?
                                        <>
                                            <button
                                                type="button"
                                                className=" text-rose-600"
                                                disabled
                                            >

                                                <span> Submitting ... </span>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button disabled={isLoading} onClick={handleUpdateClick} className=''>
                                                Unpaid (Click to Pay)
                                            </button>
                                        </>
                                }
                            </>
                            : (paymentStatus === "Paid"
                                ? 'Paid'
                                : '')
                    }
                </span>
            </td>
            <td className="py-4 px-1 flex items-center justify-center">
                <Link to={`/user/account/enrollments/${invoiceNo}`}>
                    <BsEye />
                </Link>
            </td>
        </tr>
    )
}

export default EnrollmentItem