import React from 'react'

const PlayVideo = () => {

    const handleContextMenu = (event) => {
        event.preventDefault();
    };
    

    return (
        <div className='flex items-center justify-center p-10'>
            {/* <video onContextMenu={handleContextMenu} controls>
                <source src="https://drive.google.com/file/d/17MMcKRZNJqBE8QTUJDC7eGuFygDQsgXG/preview" type="video/mp4" />
            </video> */}

            {/* <div style={{ width: '640px', height: '480px', position: 'relative' }}>
                <iframe src="https://drive.google.com/file/d/17MMcKRZNJqBE8QTUJDC7eGuFygDQsgXG/preview"   width="640" height="480" frameborder="0" scrolling="no" seamless=""></iframe>
                <div style={{ width: '80px', height: '80px', position: 'absolute', opacity: '0', right: '0px', top: '0px' }}>&nbsp;</div>
            </div> */}

            {/* GOOGLE DRIVE VIDEO */}
            {/* <iframe src="https://drive.google.com/file/d/17MMcKRZNJqBE8QTUJDC7eGuFygDQsgXG/preview" width="640" height="480" allow="autoplay" allowFullScreen></iframe> */}
        </div>
    )
}

export default PlayVideo