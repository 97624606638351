import React from 'react';
import { Link } from 'react-router-dom';

const Exam = ({ exam }) => {
    const { category, institute, year, title, slug, examFee, type } = exam || {};

    return (
        <div className='relative h-[380px] mb-10'>
            <Link to={`/exams/${slug}`} className='absolute w-full z-10 flex justify-between h-full bg-white border-2 border-black'>
                <div className='w-3 h-full bg-slate-800'></div>
                <div className='flex flex-col items-center justify-between w-full relative'>
                    <small className={`absolute top-2 text-[10px] right-2 font-semibold py-1 px-2.5 rounded-xl ${ examFee > 0 ? 'bg-rose-500 text-white' : 'bg-teal-300 text-teal-900' }`}>
                        {
                            examFee > 0
                            ? '৳ ' + examFee
                            : 'Free'
                        }
                    </small>
                    <div className='h-full w-full flex items-center justify-center'>
                        <h1 className='text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-l from-indigo-900 to-cyan-600'>{category?.name}</h1>
                    </div>
                    <div className='flex w-full h-[200px] mb-20'>
                        <div className='w-3 h-full bg-gradient-to-tr from-indigo-900 to-cyan-600 -ml-3'></div>
                        <div className='flex flex-col items-center justify-center text-white bg-gradient-to-tr from-indigo-900 to-cyan-600 py-2 px-0.5 w-full'>
                            <p className='text-center text-xl font-semibold'>{institute?.shortForm}</p>
                            <p className='text-center text-md font-semibold'>{title} &mdash; {year?.year}</p>
                        </div>
                    </div>
                </div>
            </Link>
            <div className='absolute -skew-y-6 bg-white border-2 border-black -top-3.5 h-20 w-[90%] z-0'></div>
        </div>
    );
};

export default Exam;