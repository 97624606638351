import React, { useState } from 'react';
import { AiOutlineSwapRight } from 'react-icons/ai';
import LoadingBar from 'react-top-loading-bar';
import Sidebar from './Sidebar/AccountSidebar';
import MobileMenu from './MobileMenu/MobileMenu';

const AccountMaster = ({children}) => {
    const [toggleMenu, setToggleMenu] = useState(true);





    return (
        <>
            <LoadingBar
                color='#292d60'
                progress={100}
                height={4}
            />
            <MobileMenu />
            <div className='flex flex-row'>
                <div>
                    <Sidebar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
                    <button 
                        className={`${toggleMenu ? "hidden" : 'block'} mt-10 rounded-t-lg hover:opacity-[0.8] cursor-pointer rotate-90 bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white px-3 py-1 fixed top-20 -left-5`} 
                        onClick={() => setToggleMenu(!toggleMenu)}
                    >
                        Menu
                    </button>
                </div>
                <div className='w-full min-h-[100vh] overflow-y-auto p-4 bg-gray-100 '>
                    {
                        children
                    }
                </div>
            </div>
        </>
    )
}

export default AccountMaster