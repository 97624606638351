import { apiSlice } from "../api/apiSlice";

export const playlistsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        playLesson: builder.query({
            query: (id) => `/api/v1/account/courses/topics/lessons/${id}`,
        }),
    })
});

export const { 
    usePlayLessonQuery
} = playlistsAPI;