import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    siteName: '',
    siteTitle: '',
    favIcon: null,
    logo: null,
    transparentLogo: null,
    address: '',
    phone: '',
    email: '',
    shortDescription: '',
    description: '',
    mapUrl: '',
};

const siteInfoSlice = createSlice({
    name: "siteInfo",
    initialState,
    reducers: {
        setSiteInfo: (state, action) => {
            state.siteName          = action.payload?.siteName;
            state.siteTitle         = action.payload?.siteTitle;
            state.favIcon           = action.payload?.favIcon;
            state.logo              = action.payload?.logo;
            state.transparentLogo   = action.payload?.transparentLogo;
            state.address           = action.payload?.address;
            state.phone             = action.payload?.phone;
            state.email             = action.payload?.email;
            state.shortDescription  = action.payload?.shortDescription;
            state.description       = action.payload?.description;
            state.mapUrl            = action.payload?.mapUrl;
        }
    }
});

export const { 
    setSiteInfo, 
} = siteInfoSlice.actions;

export default siteInfoSlice.reducer;