import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isVideoFinished: false,
    lessonId: null,
};

const vedioStatusSlice = createSlice({
    name: "videoStatus",
    initialState,
    reducers: {
        setVideoFinished: (state, action) => {
            state.isVideoFinished = action.payload;
        },
        setLessonId:(state,action)=>{
            state.lessonId = action.payload;
        }
    }
});

export const {
    setVideoFinished, setLessonId
} = vedioStatusSlice.actions;

export default vedioStatusSlice.reducer;