import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../features/api/apiSlice';
import siteInfoSliceReducer from '../features/siteInfo/siteInfoSlice';
import supportSliceReducer from '../features/support/supportSlice';
import authSliceReducer from '../features/auth/authSlice';
import subscriberSliceReducer from '../features/subscribers/subscribersSlice';
import cartSliceReducer from '../features/cart/cartSlice';
import checkoutSliceReducer from '../features/checkout/checkoutSlice';
import sideNavReducer from '../features/sideNav/sideNavSlice';
import showSearchReducer from '../features/mobileSearch/mobileSearchSlice';
import showPrimarySearchReducer from '../features/search/showPrimarySearchSlice';
import mobileCategoryReducer from '../features/mobileCategory/mobileCategorySlice';
import mobileTakeExamReducer from '../features/mobileTakeExam/mobileTakeExamSlice';
import showModalFreeVideoReducer from '../features/showModal/FreeVideoShowSlice';
import examReducer from '../features/exams/examsSlice';
import courseSliceReducer from '../features/courses/coursesSlice';
import vedioStatusSliceReducer from '../components/account/course/playing/VideoStatusSlice';



export const store = configureStore({
    reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		siteInfo: siteInfoSliceReducer,
		support: supportSliceReducer,
		auth: authSliceReducer,
		subscriber: subscriberSliceReducer,
		cart: cartSliceReducer,
		checkout: checkoutSliceReducer,
		sideNav: sideNavReducer,
		showSearch: showSearchReducer,
		showPrimarySearch: showPrimarySearchReducer,
		mobileCategory: mobileCategoryReducer,
		mobileTakeExam: mobileTakeExamReducer,
		showModalFreeVideo: showModalFreeVideoReducer,
		exam: examReducer,
		course:courseSliceReducer,
		videoStatus:vedioStatusSliceReducer,
    },
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(apiSlice.middleware)
});