import { apiSlice } from "../api/apiSlice";

export const slidersAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSliders: builder.query({
            query: () => '/api/v1/sliders',
        }),
    })
});

export const { 
    useGetSlidersQuery, 
} = slidersAPI;