import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Navbar from './Header/Navbar';

const Header = () => {

    const [nav, setNav] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showBottomCategoryMenu, setshowBottomCategoryMenu] = useState(false);
    const [showBottomExamMenu, setshowBottomExamMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showSearchPrimary, setShowSearchPrimary] = useState(false);



    // show mobile menu
    const handleShowMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu)

    }
    // show mobile bottom category menu
    const handleshowBottomCategoryMenu = () => {
        setshowBottomCategoryMenu(!showBottomCategoryMenu)

    }
    // show mobile bottom Take Exam menu
    const handleshowBottomExamMenu = () => {
        setshowBottomExamMenu(!showBottomExamMenu)

    }

    // show search menu 
    const handleShowSearchMenu = () => {
        setShowSearch(!showSearch);
    }

    // show primary search
    const handleShowSearchPrimary = () => {
        setShowSearchPrimary(!showSearchPrimary);
    }



    useEffect(() => {
        if (
            showMobileMenu === true ||
            showSearch === true ||
            showBottomCategoryMenu === true ||
            showBottomExamMenu === true
        ) {
            document.body.style.overflow = "hidden";
            return () => (document.body.style.overflow = "scroll");
        }
    });


    // bg transparent nav
    const changeBackground = () => {

        if (window.scrollY >= 80) {
            setNav(true);
        } else {
            setNav(false);
        }

    };

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <Navbar nav={nav} />
        </>
    )
}

export default Header