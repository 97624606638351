import React from 'react'

const Pagination = ({currentPage, perPage, totalItems, handlePaginate}) => {

    const totalPage = totalItems > 0 && perPage > 0 ? Math.ceil(totalItems / perPage) : 0;
    let pageNumbers = [];
    for (let p = 1; p <= totalPage; p++) {
        pageNumbers.push(p)
    }

    return (
        <div className="flex justify-center mt-5 mb-2">
            <nav aria-label="Page navigation example">
                <ul className="flex list-style-none">
                    <li className="page-item disabled">
                        <span
                            className={`${currentPage === 1 ? 'text-[#c85024] pointer-events-none' : 'text-[#d04c1c] hover:text-white hover:bg-[#d04c1c] cursor-pointer'} page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 focus:shadow-none`}
                            aria-disabled={currentPage === 1 ? 'true' : 'false'}
                            onClick={() => handlePaginate(Number(currentPage) - 1)}
                        >
                            Previous
                        </span>
                    </li>
                    {
                        pageNumbers.map(page => {
                            return (
                                <li key={page} className="page-item">
                                    <span
                                        className={` ${currentPage === page ? 'bg-[#d04c1c] text-white hover:text-white hover:bg-[#d04c1c] shadow-md focus:shadow-md' : 'bg-transparent text-[#d04c1c] hover:text-[white] hover:bg-[#d04c1c] focus:shadow-none'} page-link cursor-pointer relative block py-1.5 px-3 rounded border-0 outline-none transition-all`}
                                        onClick={() => handlePaginate(page)}
                                    >
                                        {page}
                                    </span>
                                </li>
                            )
                        })
                    }
                    <li className="page-item disabled">
                        <span
                            className={`${currentPage === totalPage ? 'text-[#d04c1c] pointer-events-none' : 'text-[#d04c1c] hover:text-white hover:bg-[#d04c1c] cursor-pointer'} page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 focus:shadow-none`}
                            aria-disabled={currentPage === totalPage ? 'true' : 'false'}
                            onClick={() => handlePaginate(Number(currentPage) + 1)}
                        >
                            Next
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination