import { apiSlice } from "../api/apiSlice";

export const testimonialsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTestimonials: builder.query({
            query: (page = 1) => `/api/v1/testimonials?page=${page}`,
        }),
    })
});

export const { 
    useGetTestimonialsQuery, 
} = testimonialsAPI;