import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSubscriberVerifyMutation } from '../../../features/subscribers/subscribersAPI';
import SEO from '../../../components/ui/SEO';

const SubscriberVerify = () => {
    
    const {verifyToken, hashEmail} = useParams();
    const {subscriberVerifying, subscriberVerifyStatus} = useSelector(state => state.subscriber);
    const navigate = useNavigate();





    const [verifySubscriber] = useSubscriberVerifyMutation();





    useEffect(() => {

        if (!subscriberVerifying) {
            verifySubscriber({verifyToken, hashEmail})
        }
        
    }, [subscriberVerifying, verifySubscriber, verifyToken, hashEmail]);





    useEffect(() => {

        if (subscriberVerifying && subscriberVerifyStatus === 1) {
            navigate(`/subscriber/verify/success`);
        } else if (subscriberVerifying && subscriberVerifyStatus === 0) {
            navigate(`/subscriber/verify/failed`);
        }

    }, [subscriberVerifying, subscriberVerifyStatus, navigate]);
    




    return (
        <>
            <SEO 
                title="Verify Subscriber"
            />
            <div style={{ backgroundColor: '#6DB3F2' }}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <div className='flex flex-col items-start gap-3'>
                            <div className='flex items-center justify-center gap-2 text-3xl'>
                                <svg className="h-7 w-7 animate-spin text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span className="text-blue-600 font-semibold"> Verifying... </span>
                            </div>
                            <p className='text-cyan-700'>Please don't refresh this page. After verified it will be redirect another page!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriberVerify