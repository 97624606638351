import React from 'react'

const AccountCourseSkeleton = () => {
    return (
        <div className="flex flex-col justify-between bg-white h-[290px] rounded-md mt-8 shadow-lg animate-pulse">
            <div className="bg-gray-300 h-40 w-[89%] mx-auto rounded-md mt-6 transform -translate-y-10 hover:-translate-y-2 transition duration-700 mb-2"></div>
            <div className="p-3 -mb-2">
                <p className="bg-gray-300 h-5 w-full rounded-xl mb-2"></p>
                <p className="bg-gray-300 h-5 w-full rounded-xl mb-2"></p>
                <button className='bg-gray-300 h-7 w-full rounded-xl'></button>
            </div>
        </div>
    )
}

export default AccountCourseSkeleton