import { apiSlice } from "../api/apiSlice";


export const faqsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getFaqs: builder.query({
            query: () => '/api/v1/faqs',
        }),
    })
});

export const { 
    useGetFaqsQuery
} = faqsAPI;