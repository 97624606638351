import React from 'react';
import { FaArrowRight } from 'react-icons/fa';


const PaymentFailed = () => {
    return (
        <>
          
        <div>
            <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
                <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                    <div className="max-w-md text-center">
                        <h2 className="mb-8 font-extrabold text-9xl text-gray-300">
                            <span className="sr-only">Opps!</span>
                        </h2>
                        <p className="text-2xl font-semibold md:text-3xl mb-10">Payment Failed.</p>
                        <a href='/' className=" relative border-2 rounded-full border-[#292d60] bg-transparent py-2 px-3 font-medium uppercase  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100">


                            <span className="">
                                Back To Homepage
                                <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                            </span>


                        </a>
                    </div>
                </div>
            </section>
        </div>
        </>
    );
};

export default PaymentFailed;