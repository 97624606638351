import React, { useEffect, useState } from 'react'
import PopularCourseItem from './PopularCourseItem';
import { FaArrowRight } from 'react-icons/fa';
import { useGetPopularCoursesQuery } from '../../../../features/courses/coursesAPI';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';
import { useCookies } from 'react-cookie';
import Pagination from '../../../ui/Pagination';


const PopularCourseAll = () => {

    const [courseData, setCourseData] = useState([]);
    const [page, setPage] = useState(1);

    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        // Fetch data from an API endpoint with token authentication
        fetch(`${baseUrl}/api/v1/account/courses`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then(data => {
                setCourseData(data?.data?.data)
                localStorage.setItem("accountCourseData", JSON.stringify(data?.data?.data));
            })
            // .catch((error) => console.log(error));
    }, [token]);



    const { data, isLoading, isError } = useGetPopularCoursesQuery(page);
    const { data: responseData } = data || {};
    const { data: popularCourses, meta } = responseData || {};

    const { current_page, per_page, total } = meta || {};

    
    const perPageItems = per_page;
    const totalItem = total;
    const currentPage = current_page;
   

    const handlePaginate = (page) => {
        setPage(page)
    }


    let popularCoursesItems = null;

    if (isLoading) {

        popularCoursesItems =
         <div className='grid grid-cols-4'>
            <CourseSkeleton/>
            <CourseSkeleton/>
            <CourseSkeleton/>
            <CourseSkeleton/>
         </div>

    } else if (!isLoading && isError) {
        popularCoursesItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && popularCourses?.length === 0) {
        popularCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && popularCourses?.length > 0) {
        popularCoursesItems = popularCourses.map(popularCourse => <PopularCourseItem key={popularCourse.id} popularCourse={popularCourse} popularCourses={popularCourses} courseData={courseData} />);

    }



    return (
        <div className='pb-7 mt-10 max-w-[1265px]  mx-auto'>
            <div className='mb-10'>
            <h1 className="text-2xl font-semibold">Popular Courses</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                
                    {
                        popularCoursesItems
                    }
                
            </div>


            {
                popularCourses?.length > 0 &&
                (
                    perPageItems < totalItem &&
                    <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate} />
                )
            }
        </div>
    );
}

export default PopularCourseAll