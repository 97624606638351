import { apiSlice } from "../api/apiSlice";

export const siteInfoAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSiteInfo: builder.query({
            query: () => '/api/v1/site-info',
        }),
    })
});

export const { 
    useGetSiteInfoQuery, 
} = siteInfoAPI;