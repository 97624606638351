import React from 'react';
import StudentFeadbackCoursePage from './StudentFeadbackCoursePage';
import SuggestCourse from './SuggestCourse';
import { Link, useParams } from 'react-router-dom';
import Instructors from '../../components/Course/Details/Instructor/Instructors';
import Outcomes from '../../components/Course/Details/Outcome/Outcomes';
import FAQs from '../../components/Course/Details/FAQ/FAQs';
import Topics from '../../components/Course/Details/Topic/Topics';
import Introductions from '../../components/Course/Details/Introduction/Introductions';
import DefaultIntroVideo from '../../assets/videos/introVideo.mp4';
import noVideo from '../../assets/images/no-video.gif';
import { useGetAccountCourseQuery, useGetCourseDetailsQuery } from '../../features/courses/coursesAPI';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../features/cart/cartSlice';
import CourseDetailsSkeleton from '../../components/ui/Skeleton/Course/CourseDetailsSkeleton';
import ReactPlayer from 'react-player';



const CourseDetails = () => {

    const { cartItems } = useSelector(state => state.cart);

    const { slug } = useParams();
    const dispatch = useDispatch();

    const { data, isLoading, isError } = useGetCourseDetailsQuery(slug);
    const { data: course } = data || {};

    const { data: courseData } = useGetAccountCourseQuery([]);


    const playerConfig = {
        file: {
          attributes: {
            controlsList: 'nodownload'
          }
        },
        autoplay: false
      };

    let courseDetailcontent = null;

    if (isLoading) {
        courseDetailcontent = <><CourseDetailsSkeleton /></>
    } else if (!isLoading && isError) {
        courseDetailcontent = <p>There was an error!</p>
    } else if (!isLoading && !isError && data?.status === 1) {

        const {
            id,
            category,
            language,
            level,
            title,
            slug: courseSlug,
            regular_fee: regularFee,
            discount_amount: discountAmount,
            course_fee: courseFee,
            course_type: courseType,
            intro_video: introVideo,
            thumbnail_image: thumbnailImage,
            short_description: shortDescription,
            average_rating: averageRating,
            total_lecture: totalLecture,
            total_duration: totalDuration
        } = course;

        // console.log(course);

        // let existCourse = null;
        const existCourse = courseData?.data?.data?.find((exist) => exist?.id === id);
        // console.log(courseData?.data?.data);


        const itemIndex = cartItems?.findIndex(item => item.id === id);



        const intro = process.env.REACT_APP_BASE_URL + '/' + introVideo;





        const addToCartHandler = () => {
            dispatch(addToCart({
                id,
                title,
                slug,
                categoryName: category?.name,
                regularFee,
                discountAmount,
                courseFee,
                thumbnailImage,
                averageRating,
                totalLecture,
                totalDuration,
                quantity: 1,
            }));
        };






        courseDetailcontent = <div className='pt-10 px-5 lg:px-20'>
            <div className='grid md:grid-cols-3 gap-10'>
                <div className='md:gap-12 md:pt-10 md:col-span-2'>
                    <h1 className='mb-4 text-[21px] font-semibold md:block md:text-4xl'>{title}</h1>
                    <div className="mb-10 md:block">{shortDescription}</div>
                    <Instructors />
                    <Outcomes />
                    <FAQs />
                    <Topics />
                    <StudentFeadbackCoursePage course={course} />
                    {/* <SuggestCourse /> */}
                </div>
                <div className='pt-5 lg:h-[600px] lg:sticky lg:top-14 lg:bottom-4 lg:overflow-auto'>
                    {/* <video className='w-full h-[200px]' controls>
                        <source src={introVideo !== null ? intro : DefaultIntroVideo} type="video/mp4" />
                    </video> */}
                    {

                        introVideo !== null || undefined ?

                            <ReactPlayer
                            url={`${introVideo}`}
                            playing={false}
                            controls={true}
                            config={playerConfig}
                            height="300px" width="100%"
                            />
                            // <iframe height="250" width="100%" src={`${introVideo}`} title={title} frameBorder="0" allowFullScreen allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe>
                            :
                            <>
                                <img src={noVideo} alt="" className='w-full h-[200px]' />
                            </>
                    }
                    <p className='pt-4 mb-4 text-lg font-semibold'>{courseFee > 0 ? (
                        discountAmount > 0 ? <><span className='line-through text-[rgba(0,0,0,.6)]'>৳ {regularFee} </span> ৳ {courseFee} </> : courseFee
                    ) : 'Free'}</p>
                    {
                        isLoading ? <> <p>loading..</p></>
                            :
                            (existCourse ? <>
                                <Link to={`/user/account/courses/${slug}`} className='w-full bg-[#292d60] pt-3 pb-3 px-10 m-auto  text-white rounded-lg mt-2 hover:bg-black hover:text-white'>
                                    Go to Course
                                </Link>
                            </>
                                :
                                itemIndex !== -1 ?
                                    (
                                        <Link to={`/cart`} className='w-full bg-[#292d60] pt-3 pb-3 px-10 m-auto  text-white rounded-lg mt-2 hover:bg-black hover:text-white'>
                                            View In Cart
                                        </Link>
                                    )
                                    :
                                    (
                                        <button onClick={addToCartHandler} className='w-full bg-[#292d60] py-3 px-4 text-white rounded-lg mt-2 hover:bg-black hover:text-white'>
                                            Add to cart
                                        </button>
                                    )
                            )
                    }


                    <Introductions />
                </div>
            </div>
        </div>
    } else {
        courseDetailcontent = <p>404</p>
    }



    return courseDetailcontent;
};

export default CourseDetails;