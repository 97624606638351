import React from 'react';
import Slider from 'react-slick';
import {  FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useGetSlidersQuery } from '../../../features/sliders/slidersAPI';
import SliderItem from './SliderItem';
import SliderSkeleton from '../../ui/Skeleton/SliderSkeleton';
// import './Slider.module.css'

const Sliders = () => {

    const sliderSettings = {
        arrows:false,
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <FaAngleRight />,
        prevArrow: <FaAngleLeft />,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        )
    };





    const { data, isLoading, isError } = useGetSlidersQuery();
    const { data: responseData } = data || {};
    const { data: sliders } = responseData || {};


    let sliderItems = null;

    if (isLoading) {
        sliderItems = <SliderSkeleton />;
    } else if (!isLoading && isError) {
        sliderItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && sliders?.length === 0) {
        sliderItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && sliders?.length > 0) {
        sliderItems = sliders.map(slider => <SliderItem key={slider.id} slider={slider} />);
    }





    return (
        <div className="banner hidden md:block">
            <Slider {...sliderSettings}>
                {
                    sliderItems
                }
            </Slider>
        </div>
    )
}

export default Sliders