import React, { useState } from 'react';
import { useGetPopularCoursesQuery } from '../../../features/courses/coursesAPI';
import CourseSkeleton from '../../../components/ui/Skeleton/CourseSkeleton';
import DashboardItem from './DashboardItem';
import Pagination from 'react-js-pagination';

const DashBoardSuggestCourse = () => {
    const [page, setPage] = useState(1);

    const { data, isLoading, isError } = useGetPopularCoursesQuery(page);
    const { data: responseData } = data || {};
    const { data: suggestedCourse, meta } = responseData || {};

    const { current_page, per_page, total } = meta || {};


    const perPageItems = per_page;
    const totalItem = total;
    const currentPage = current_page;

    const handlePaginate = (page) => {
        setPage(page)
    }

    let suggestedCourseItem = null;

    if (isLoading) {

        suggestedCourseItem =
            <div className='grid grid-cols-4 gap-4'>
                <div><CourseSkeleton /></div>
                <div><CourseSkeleton /></div>
                <div><CourseSkeleton /></div>
                <div><CourseSkeleton /></div>
                
            </div>

    } else if (!isLoading && isError) {
        suggestedCourseItem = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && suggestedCourse?.length === 0) {
        suggestedCourseItem = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && suggestedCourse?.length > 0) {
        suggestedCourseItem = suggestedCourse.map(suggestCourse => <DashboardItem key={suggestCourse.id} suggestCourse={suggestCourse} />);

    }

    return (
        <>
            {
                suggestedCourseItem
            }

            <div>
            {
                suggestedCourse?.length > 0 &&
                (
                    perPageItems < totalItem &&
                    <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate} />
                )
            }
            </div>
        </>
    );
};

export default DashBoardSuggestCourse;