import React from 'react';
import { Link } from 'react-router-dom';
import defaultBlog from '../../assets/images/defaultblog.png';

const BlogItem = ({ blog }) => {




    const {
        title,
        slug,
        thumbnail_image,
        short_description,
        category,
        created,
        createdBlog,
        cover_image


    }
        = blog || {};

    const { categoryName, categorySlug } = category || {};
    const { username } = created || {};

    const thumbnailImage = process.env.REACT_APP_BASE_URL + '/' + thumbnail_image;
    const coverImage = process.env.REACT_APP_BASE_URL + '/' + cover_image;

    return (
        <>

            <div className="rounded overflow-hidden shadow-lg bg-white mb-5">
                <img className="w-fit h-fit" src={cover_image !== null ? coverImage : defaultBlog} alt="Mountain" />
                <div className="px-3 pt-3 pb-1">
                    <Link to={categorySlug}>
                        <span className="inline-block bg-orange-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{categoryName}</span>
                    </Link>

                </div>
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">
                        <Link to={slug}>{title}</Link>
                    </div>
                    <p className="text-gray-700 text-base">
                        {short_description}
                    </p>
                </div>
                <div className="px-6 pt-4 pb-2">
                    <div className=" items-center rounded-full py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 flex justify-between">
                        <div className='flex items-center space-x-2'>
                            <img src={thumbnail_image !== null ? thumbnailImage : defaultBlog} className="w-8 h-8 rounded-full" alt="" />
                            <span> {username}</span>
                        </div>
                        <div>
                            <span>{createdBlog}</span>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default BlogItem;