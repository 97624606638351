import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useLogoutMutation } from '../../../features/auth/authAPI';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BiHomeAlt } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';
import { SlGraduation } from 'react-icons/sl';
import { TiTags } from 'react-icons/ti';
import { TbLogout } from 'react-icons/tb';
import { TfiArrowCircleLeft } from 'react-icons/tfi';
import demoAvater from '../../../assets/images/avatar.png';

const AccountSidebar = ({ toggleMenu, setToggleMenu }) => {
    
	const { user } = useSelector(state => state.auth);
    const [cookies, setCookie, removeCookie] = useCookies(['EduTvAuth']);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    // if(user?.image === 'assets/img/avatar.png'){
    // }
    



    // const [logout, { data, isLoading }] = useLogoutMutation();

    const handleLogOut = () => {
        setIsLoading(true);
        localStorage?.removeItem('prevPathname');
        removeCookie('EduTvAuth', {path: '/'});
        setTimeout(() => {
            setIsLoading(false); // Set isLoading back to false after the logout actions are completed
            navigate(0); // Redirect to the home page or any desired page
          }, 2000);
    }


    



    // useEffect(() => {
    //     if (data?.status === 1) {
    //         localStorage?.removeItem('prevPathname');
    //         removeCookie('EduTvAuth', {path: '/'});
    //         navigate(0);
            
    //     }
    // }, [data, removeCookie, navigate]);





    const avatar = process.env.REACT_APP_BASE_URL + '/' + user?.image;
	


	

	return (
		<div className={`${toggleMenu ? "w-64" : "w-0 "} h-full lg:static z-[1000] lg:z-0 bg-white shadow-md ease-in-out duration-500 overflow-hidden`}>
            <aside className="w-64 " aria-label="Sidebar">
                <div className='flex items-center justify-between h-16'>
                    <Link className="flex items-center text-base font-normal">
                        <div className="ml-3 flex items-center">
                            <img src={(user?.image === 'assets/img/avatar.png' ||user?.image === null ) ? demoAvater : avatar} className="w-[50px] h-[50px] rounded-full" alt="" />
                            <div className='flex flex-col text-xs font-semibold'>
                                <span>{user?.first_name} {user?.last_name}</span>
                                <span className='text-indigo-600'>{user?.username}</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="overflow-y-auto w-full">
                    <ul>
                        <li>
                            <NavLink to="/" className='flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black'>
                                <FiExternalLink /> <span>Visit Website</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/dashboard" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <BiHomeAlt /> <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/profile" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <BiHomeAlt /> <span>Profile</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/change-password" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <BiHomeAlt /> <span>Change Password</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/courses" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <SlGraduation /> <span>Course</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/enrollments" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <TiTags /> <span>Enrollment History</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/user/account/certification" className={({ isActive }) => (isActive ? 'flex items-center gap-2 py-2 px-5 text-base font-normal bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'flex items-center gap-2 py-2 px-5 text-base font-normal hover:bg-blue-100 hover:text-black')}>
                                <TiTags /> <span>Certificate Historty</span>
                            </NavLink>
                        </li>
                        <li>
                            {
                                isLoading
                                ?
                                    <button 
                                        type="button" 
                                        className="flex items-center gap-2 py-2 px-5 text-rose-600"
                                        disabled
                                    >
                                        <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span> Sign Out ... </span>
                                    </button>
                                :
                                    <button 
                                        disabled={isLoading}
                                        onClick={handleLogOut} 
                                        className="flex items-center gap-2 py-2 px-5 hover:text-rose-600" 
                                    > 
                                        <TbLogout /> <span>Sign Out</span>
                                    </button>
                            }
                        </li>
                        <li className='flex items-center justify-center'>
                            <TfiArrowCircleLeft 
                                onClick={() => setToggleMenu(!toggleMenu)} 
                                className='text-gray-400 text-xl cursor-pointer hover:text-gray-500 ease-in -mr-5' 
                            />
                        </li>
                    </ul>
                </div>
            </aside>
		</div>
	);
};

export default AccountSidebar;