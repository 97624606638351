import React from 'react'
import Lessons from '../Lesson/Lessons';

const Topic = ({topic}) => {

    const { id, title, total_lecture, total_duration } = topic || {};

    return (
        <details className="flex flex-col gap-4 p-4 border-b border-gray-300">
            <summary className="font-semibold cursor-pointer ease-in-out duration-300">
                {title} 
                <span className='float-right text-xs font-normal'>{total_lecture} {total_lecture > 1 ? 'lectures' : 'lecture'} &mdash; {total_duration} minutes</span>
            </summary>

            <Lessons topicId={id} />
        </details>
    )
}

export default Topic