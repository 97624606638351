import React, { useEffect, useState } from 'react';
import QuizResult from './QuizResult';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

const QuizModal = ({ showModal, handleShowModal, lesson_quiz , quizResultId}) => {


    const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [quizResult, setQuizResult] = useState([]);
    const [showResult, setShowResult] = useState([]);
    const [resultModal, setResultModal] = useState(false);
    const [totalCorrect, setTotalCorrect] = useState(0);
    const [isLastSubmit, setIsLastSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const { user } = auth || {};
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const { lessonId } = useParams();

    const [quizResults, setQuizResults] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        axios.get(`${baseUrl}/api/v1/account/lesson/quiz-result-show/${lessonId}/${user?.id}`,
        {
            headers:{
                Authorization: `Bearer ${token}`
        }
        })
            .then(response => {
                setQuizResults(response.data.data);

                // console.log("aksjcdaskf", response.data.data);
                setIsLoading(false);


            })
            .catch(error => {
                console.error(error);
            });

    }, []);

    useEffect(() => {
        localStorage.setItem('quizOptions', JSON.stringify(quizResult));
    }, [quizResult]);

    useEffect(() => {
        if(isLastSubmit){
            handaleResultSubmit();
            
        }
    }, [isLastSubmit]);

    const handleSubmitResult = () => {
        setIsLastSubmit(true);    
        submitNextQuiz();
        setResultModal(true);
        
    }
    const handaleResultSubmit =() =>{
        fetch(`${baseUrl}/api/v1/account/lesson/quiz-result-update/${quizResultId.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                totalMarks: totalCorrect,
                is_completed: 1,
            })
            })
            .then(response => response.json())
            .then(response =>  setQuizResults(response.data))
            .catch(error => {
                console.error(error);
            });
    }

    const handleNextQuiz = () => {
        if (currentQuizIndex < lesson_quiz.length - 1) {
            setCurrentQuizIndex(currentQuizIndex + 1);
            setSelectedOption(null);
            submitNextQuiz();
        } else {
            submitNextQuiz();
        }
    };



    const handlePreviousQuiz = () => {
        if (currentQuizIndex > 0) {
            setCurrentQuizIndex(currentQuizIndex - 1);
            setSelectedOption(null);
        }
    };

    const handleOptionClick = (optionIndex) => {
        setSelectedOption(optionIndex);
    };

    const submitNextQuiz = () => {
        const selectedQuiz = lesson_quiz[currentQuizIndex];
        const selectedOptionValue = selectedOption !== null ? selectedQuiz.lesson_quiz_option[selectedOption] : 'No option selected';
        const currectAnswer = selectedOptionValue?.answer == 1 ? 1 : 0;

        const isCorrectAnswer = currectAnswer === 1;



        const result = {
            name: selectedQuiz?.name,
            selectedOption: selectedOptionValue,
            currectAnswer: currectAnswer,
            isCorrect: isCorrectAnswer,
        };
        // console.log(currectAnswer);

        const isDuplicate = quizResult.some((res) => res.name === result.name);
        
        if (!isDuplicate) {
            const newResults = [...quizResult, result];
            setQuizResult(newResults);

            setShowResult(newResults);

            // console.log(newResults);

            if (isCorrectAnswer) {
                setTotalCorrect(totalCorrect + 1);

            }

        }
    };

    useEffect(() => {
        const getResult = JSON.parse(localStorage.getItem('quizOptions')) || [];
        setShowResult(getResult)

    }, []);


    // console.log(quizResults);

    

    const currentQuiz = lesson_quiz[currentQuizIndex];

    return isLoading? <h1>Loading..</h1>:(
        <>
            <div class={`${showModal ? 'block' : 'hidden'} relative z-10`} aria-labelledby="modal-title" role="dialog" aria-modal="true">

                {/* <div class=" inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div> */}

                {/* <div class="fixed inset-0 z-10 overflow-y-auto"> */}
                    {/* <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"> */}

                        {/* <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"> */}
                            {/* <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"> */}

                                {     

                                    quizResults ?

                                        <>
                                            <div>
                                                <h2 className="text-lg font-bold mt-4">Quiz Result</h2>
                                                <h2 className="text-lg font-bold mt-4">Total Quiz : {lesson_quiz?.length}</h2>
                                                <h2 className="text-lg font-bold mt-4">Total Correct Answers: {quizResults?.total_marks}</h2>
                                                {

                                                    showResult?.map(result => <QuizResult key={result?.id} result={result} />
                                                    )
                                                }
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div>
                                                <h1 className='text-orange-300'>{currentQuizIndex + 1}/{lesson_quiz?.length} Quiz</h1>
                                                <h1 className="text-3xl font-bold mb-4">{currentQuiz?.name}</h1>

                                                <div className="mb-4">
                                                    <ul>
                                                        {
                                                            currentQuiz?.lesson_quiz_option?.map((option, index) => (
                                                                // <li
                                                                
                                                                //     key={index}
                                                                //     className={`mb-2 cursor-pointer py-3 text-xl px-2 ${selectedOption === index ? 'bg-orange-500 rounded-md text-white' : ''
                                                                //         }`}
                                                                //     onClick={() => handleOptionClick(index)}
                                                                // >
                                                                //     {option?.name}
                                                                // </li>
                                                                <label key={index} className={`inline-flex items-center w-full py-3 hover:bg-[#1476a5] hover:text-white rounded-md px-4 duration-300 ${selectedOption === index ? 'bg-[#1476a5] text-white': '' } `}>
                                                                <input
                                                                
                                                                    type="radio"
                                                                    value={option.id || ''}
                                                                    checked={selectedOption === index}
                                                                    onChange={() => handleOptionClick(index)} 
                                                                    className="form-radio h-5 w-5"
                                                                />
                                                                <span className="ml-2 ">{option.name}</span>
                                                            </label>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="space-x-4 flex justify-between">
                                                    <button
                                                        className="bg-orange-500 hover:bg-blue-700 text-white font-bold py-3 px-4 text-normal rounded"
                                                        onClick={handlePreviousQuiz}
                                                        disabled={currentQuizIndex === 0}
                                                    >
                                                        Previous
                                                    </button>


                                                    {
                                                        currentQuizIndex < lesson_quiz.length - 1 ?
                                                            <>

                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                                                                    onClick={handleNextQuiz}
                                                                >
                                                                    Next
                                                                </button>

                                                            </>
                                                            :
                                                            <>
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                                                                    onClick={handleSubmitResult}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </>
                                                    }

                                                </div>



                                            </div>
                                        </>
                                }

                            {/* </div> */}
                            {/* <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button onClick={handleShowModal} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                            </div> */}
                        {/* </div> */}


                    {/* </div> */}
                {/* </div> */}
            </div>
        </>
    );
};

export default QuizModal;