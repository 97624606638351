import React, { useState } from 'react'
import Topic from './Topic';
import { useGetCourseTopicsQuery } from '../../../../features/courses/coursesAPI';
import TopicSkeleton from '../../../ui/Skeleton/TopicSkeleton';





const Topics = ({slug}) => {

    const { data, isLoading, isError } = useGetCourseTopicsQuery(slug);
    const { data: courseTopicsData } = data || {};
    const { data: courseTopics } = courseTopicsData || {};




    let topicContent = null;

    if (isLoading) {
        topicContent =  <div className='flex flex-col gap-2'>
                            <TopicSkeleton />
                            <TopicSkeleton />
                            <TopicSkeleton />
                            <TopicSkeleton />
                            <TopicSkeleton />
                        </div>
    } else if (!isLoading && isError) {
        topicContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseTopics?.length === 0) {
        topicContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No topics found!</div>;
    } else if (!isLoading && !isError && courseTopics?.length > 0) {
        topicContent = courseTopics.map((topic) => <Topic key={topic.id} topic={topic} />)
    }


    return (
        <div className="flex flex-col border-b border-gray-200">
            {
                topicContent
            }
        </div>
    )
}

export default Topics