import React, { useEffect, useState } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu/MobileMenu';
import MobileSideNav from './MobileMenu/MobileSideNav';
import MobileSearch from './MobileMenu/MobileSearch';
import MobileMenuCategories from './MobileMenu/MobileMenuCategories';
import MobileMenuTakeExam from './MobileMenu/MobileMenuTakeExam';
import Loader from '../Preloader/Loader';
import { useLocation } from 'react-router-dom';
import ReactWhatsapp from '../React-whatsapp/ReactWhatsapp';



const Master = ({children}) => {
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsLoading(true);
	
		// Simulate a delay to show the loader for demonstration purposes
		setTimeout(() => {
		  setIsLoading(false);
		}, 2000);
	  }, [location]);

    return (
        <>
		    {
				isLoading ?
				<Loader/> : 
				<>
				{/* <Loader/> */}
				<ReactWhatsapp/>
			<MobileSideNav />
			<MobileSearch/>
			<MobileMenuCategories/>
			<MobileMenuTakeExam/>
			<Header />
			<MobileMenu />
			<LoadingBar
				color='#292d60'
				progress={100}
				height={4}
			/>

			<main>
			{
				children
			}
			</main>
			<Footer />
				</>
			}
		</>
    )
}

export default Master