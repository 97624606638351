import React from 'react';
import { AiOutlineFileText, AiOutlineHome } from 'react-icons/ai';
import { BsPeople } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleMobileCategory } from '../../../features/mobileCategory/mobileCategorySlice';
import { handleMobileTakeExam } from '../../../features/mobileTakeExam/mobileTakeExamSlice';


const MobileMenu = () => {


const dispatch = useDispatch()
    const showMobileMenuCategory = useSelector((state) => state.mobileCategory.mobileCategory);
    const showMobileTakeExamMenu = useSelector((state) => state.mobileTakeExam.mobileTakeExam);
    return (
        <>
            {/* <MobileMenuCategories /> */}
            <div 
                className={`
                    ${
                        showMobileMenuCategory || showMobileTakeExamMenu 
                        ? 'hidden' 
                        : 'block'
                    } 
                    w-[100vw] h-12 bg-white text-[#090b24] pb-2 fixed bottom-0 lg:hidden sm:block z-40 shadow-t-lg`
                }
               
            >
                <ul className='font-semibold flex justify-between px-5'>
                    <li className='hover:text-gray-500 pl-auto py-2'>
                        <Link to="/">
                            <AiOutlineHome className='mx-auto' />
                            Home
                        </Link>
                    </li>
                    <li className='hover:text-gray-500 pl-auto py-2'>
                        <button 
                           onClick={() => dispatch(handleMobileCategory())}
                        >
                            <AiOutlineFileText className='mx-auto' />
                            Categories
                        </button>
                    </li>
                    <li className='hover:text-gray-500 pl-auto py-2'>
                        <button 
                            onClick={() => dispatch(handleMobileTakeExam())}
                        >
                            <BsPeople className='mx-auto' />
                            Take Exam
                        </button>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default MobileMenu;