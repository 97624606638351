import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	// <React.StrictMode>
		<Provider store={store}>
			<Toaster position='top-center' reverseOrder={false} />
			<HelmetProvider>
				<Router>
					<App />
				</Router>
			</HelmetProvider>
		</Provider>
	// </React.StrictMode>
);

reportWebVitals();
