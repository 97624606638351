import React from 'react';

const ExamFilterSkeleton = () => {
    return (
        <div className='flex items-center gap-2 mb-4 animate-pulse w-full'>
            <p className='h-5 w-5 bg-gray-200 rounded-md'></p>
            <p className='h-5 w-48 bg-gray-200 rounded-md'></p>
        </div>
    );
};

export default ExamFilterSkeleton;