import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showPrimarySearch: false,
  };



const showPrimarySearchSlice = createSlice({
    name: 'showPrimarySearch',
    initialState,
    reducers: {
      handlePrimarySearch: (state) => {
        state.showPrimarySearch = !state.showPrimarySearch;
      },
    },
  });

  export const { handlePrimarySearch } = showPrimarySearchSlice.actions;

export default showPrimarySearchSlice.reducer;