import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProgressBar from '../../components/Checkout/ProgressBar';
import PaymentMethods from '../../components/Checkout/PaymentMethod/PaymentMethods';
import { useGetVatPercentageQuery } from '../../features/cart/cartAPI';
import { useStoreEnrollmentMutation } from '../../features/checkout/checkoutAPI';
import SEO from '../../components/ui/SEO';
import AlertMessage from '../../components/ui/AlertMessage';
import { useDispatch } from 'react-redux';





const CheckoutAamarPay = () => {

    const { user } = useSelector(state => state.auth);
    const { invoiceNo } = useSelector(state => state.checkout);
    const {cartItems, cartSubtotal, cartDiscountAmount, cartCouponDiscountAmount, cartCouponAmount,cartCouponId, cartVatAmount, cartGrandTotal} = useSelector(state => state.cart);
    const [access, setAccess] = useState(false);
    const [paymentMethodId, setPaymentMethodId] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useGetVatPercentageQuery();





    useEffect(() => {
        if (invoiceNo !== '') {
            window.location.replace('/enrollment-success')
        } else if (cartItems?.length === 0) {
            window.location.replace('/')
        } else if (cartItems?.length > 0) {
            setAccess(true);
        }
    }, [invoiceNo, cartItems]);


    const [storeEnrollment, {data, isLoading, isError, error}] = useStoreEnrollmentMutation();

    const handleCheckout = () => {

        // const baseURL       = process.env.REACT_APP_AMARPAY_SANDBOX_CURL;
        // const baseURL       = process.env.REACT_APP_AMARPAY_LIVE_CURL;
        // const storeID       = process.env.REACT_APP_AMARPAY_SANDBOX_STORE_ID;
        // const signatureKey  = process.env.REACT_APP_AMARPAY_SANDBOX_SIGNATURE_KEY;

        const baseURL       = "https://sandbox.aamarpay.com/index.php";
        const storeID       = "aamarpaytest";
        const signatureKey  = "dbb74894e82415a2f7ff0ec3a97e4183";


        const formData = new FormData();
        formData.append('store_id', storeID);
        formData.append('signature_key', signatureKey);
        formData.append('cus_name', 'John Doe');
        formData.append('cus_email', 'johndoe@gmail.com');
        formData.append('cus_phone', '01234567890');
        formData.append('amount', 20);
        formData.append('currency', 'BDT');
        formData.append('tran_id', '4kdDDKDFK232dkdfkww3333'); 
        formData.append('success_url', 'http://localhost:3000/enrollment-success'); 
        formData.append('fail_url', 'http://localhost:3000/enrollment-failed'); 
        formData.append('cancel_url', 'http://localhost:3000/enrollment-cancelled'); 
        formData.append('desc', 'Lorem Ipsum Testing AmarPay Sandbox!'); 

        // const data = {
        //     type: 'json',
        //     store_id: storeID,
        //     signature_key: signatureKey,
        //     cus_name: "John Doe",
        //     cus_email: "johndoe@gmail.com",
        //     cus_phone: "01234567890",
        //     amount: 20,
        //     currency: 'BDT',
        //     tran_id: '4kdDDKDFK232dkdfk',
        //     success_url: "http://localhost:3000/enrollment-success",
        //     fail_url: "http://localhost:3000/enrollment-failed",
        //     cancel_url: "http://localhost:3000/enrollment-cancelled",
        //     desc: 'Lorem Ipsum Testing AmarPay Sandbox!...'
        // };


        const options = {
            url: baseURL,
            method: 'POST',
            headers: { 
                'Content-Type': 'multipart/form-data',
                // "Content-type": "application/json",
                // 'Access-Control-Allow-Headers': 'Origin, X-Api-key, X-Requested-With, Content-Type, Authorization', 
                // 'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS', 
                // 'Access-Control-Allow-Origin': '*', 
            },
            body: formData,
        };


        axios(options)
        .then((response) => {
            // console.log(response);
        })
        .catch((error) => {
            // console.log(error)
        });
    }





    useEffect(() => {
        if (isError) {
            setAlertMessageType('error');
            setAlertMessage(error?.data?.errors?.payment_method_id[0]);
        }

        setTimeout(() => {
            setAlertMessageType('');
            setAlertMessage('');
        }, 5000);
    }, [isError, error]);





    return access && (
        <>
            <SEO 
                title='Checkout'
                description='Checkout'
                url='/checkout'
            />
            <div className="py-10 flex justify-center items-center rounded-md">
                <div className="card w-[90%] rounded-md shadow-md bg-white p-5">
                    {
                        alertMessage !== '' && <AlertMessage type={alertMessageType} message={alertMessage} />
                    }
                    <ProgressBar />
                    <div className='mt-4 lg:grid lg:grid-cols-2 divide-x'>
                        <PaymentMethods setPaymentMethodId={setPaymentMethodId} />
                        <div className='pl-5'>
                            <h2 className='text-gray-500 text-xl font-medium mb-2'>Summary</h2>
                            <hr />
                            <div className='mb-5 mt-3'>
                                <div className='flex justify-between mb-1'>
                                    <p>Subtotal</p>
                                    <p>৳<span>{cartSubtotal}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>VAT</p>
                                    <p>৳<span>{cartVatAmount}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>Discount</p>
                                    <p>৳<span>{cartDiscountAmount}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>Coupon</p>
                                    <p>৳<span>{cartCouponDiscountAmount<=0?cartCouponAmount:cartCouponDiscountAmount}</span></p>
                                </div>
                                <hr />
                                <div className='flex justify-between mt-2'>
                                    <p className='font-semibold'>Grand Total</p>
                                    <p className='font-semibold'>৳<span>{cartGrandTotal}</span></p>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <Link to='/cart' className='w-full py-2 text-center hover:bg-indigo-50 text-indigo-900 font-semibold border border-slate-500 rounded-sm'>
                                    Previous
                                </Link>
                                <button disabled={isLoading} onClick={handleCheckout} className='w-full py-2 bg-indigo-900 hover:bg-indigo-800 text-indigo-50 font-medium rounded-sm'>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CheckoutAamarPay;