import React, { useState } from 'react';

const CustomPagination = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPagination = () => {
    const pagination = [];

    // Calculate the range of pages to display
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);
    const nextPage = Math.min(totalPages, currentPage + 1);
    const previousPage = Math.min(totalPages, currentPage - 1);

    // Render the "First" page button
    // if (currentPage > 1) {
    //   pagination.push(
    //     <button key="first" className='text-[#D04C1C]' onClick={() => handlePageChange(1)}>
    //       Frist Page
    //     </button>
    //   );
    // }


      // Render the "Previous" page button
  if (currentPage > 1) {
    pagination.push(
      <button key="previous" className='text-[#D04C1C]' onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      >
        Previous
      </button>
    );
  }

    // Render the page numbers
    for (let page = startPage; page <= endPage; page++) {
      pagination.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={currentPage === page ? 'active bg-[#D04C1C] px-3 text-normal font-semobold py-1 rounded-md mx-1 border-0 text-white' : 'mx-2 text-[#D04C1C]'}
        >
          {page}
        </button>
      );
    }

     // Render the "Next" page button
  if (currentPage < totalPages) {
    pagination.push(
      <button key="next" className='text-[#D04C1C]' onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      >
        Next
      </button>
    );
  }

    // Render the "Last" page button
    // if (currentPage < totalPages) {
    //   pagination.push(
    //     <button key="last" className='text-[#D04C1C]' onClick={() => handlePageChange(totalPages)}>
    //       Last Page
    //     </button>
    //   );
    // }

    return pagination;
  };

  return <div className="pagination">{renderPagination()}</div>;
};

export default CustomPagination;
