import React from 'react';

const QuizSkeleton = () => {
    return (
        <div>
             <div className="flex w-full flex-1 flex-col">
        <div className="w-1/2 animate-pulse flex-row space-x-1 rounded-xl p-6 ">
          <div className="flex flex-col space-y-2">
            <div className="h-6 w-11/12 rounded-md bg-gray-300 "></div>
            <div className="h-6 w-10/12 rounded-md bg-gray-300 "></div>
            <div className="h-6 w-9/12 rounded-md bg-gray-300 "></div>
            <div className="h-6 w-9/12 rounded-md bg-gray-300 "></div>
          </div>
        </div>
      </div>
        </div>
    );
};

export default QuizSkeleton;