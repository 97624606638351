import React, { useEffect } from 'react'
import { BiSearch } from 'react-icons/bi';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { handleSearch } from '../../../features/mobileSearch/mobileSearchSlice';
import MobileSearchForm from '../../Search/MobileSearchForm';



const MobileSearch = () => {

    const dispatch = useDispatch();
    const { logo } = useSelector(state => state.siteInfo);
    const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;

    const showSearch = useSelector((state) => state.showSearch.showSearch);



    useEffect(() => {
        if (
            showSearch === true 
        ) {
            document.body.style.overflow = "hidden";
            return () => (document.body.style.overflow = "scroll");
        }
    });



    return (

        <>
            <div className={`${showSearch ? 'scale-x-100' : 'scale-x-0'} duration-300 z-50 fixed`} >
                <div className=" absolute bg-white top-0 w-screen h-screen md:hidden rounded-b-lg">
                    <div>
                        <div className='flex justify-between px-10 mt-5'>
                            <img src={siteLogo} alt="logo" className='h-10 w-15 mr-2' />
                            {

                                // showSearch &&
                                <button className=" " onClick={() => dispatch(handleSearch())}>
                                    <MdOutlineClose className='w-8 h-8 duration-300' />
                                </button>



                            }
                        </div>


                        <div className='px-6 mt-8'>
                            <MobileSearchForm/>
                        </div>
                    </div>



                </div>
            </div>


        </>
    )
}

export default MobileSearch