import React from 'react'
import { BsChevronDown } from 'react-icons/bs'

const TopicSkeleton = ({height = '75px'}) => {
    return (
        <div className='flex items-center justify-between  px-4 bg-gray-300 animate-pulse w-full rounded' style={{ height }}>
            <div className='flex flex-col gap-2'>
                <div className='h-5 w-36 bg-gray-100 rounded'></div>
                <div className='h-3 w-20 bg-gray-100 rounded'></div>
            </div>
            <BsChevronDown className='text-gray-100' />
        </div>
    )
}

export default TopicSkeleton