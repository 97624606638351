import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({result}) => {

  const TotalQuestion = (parseFloat(result?.total_quiz));
  const TotalMark = (parseFloat(result?.total_marks));
  const CorrectAnswer = result?.quiz_test_quizzes?.length;
  const WrongAnswer = parseFloat(result?.total_quiz) - result?.quiz_test_quizzes?.length;
  const array = [TotalQuestion,TotalMark,CorrectAnswer, WrongAnswer ]

  const data = {
    labels: ['TotalQuestion', 'TotalMark', 'CurrectAnswer', 'WrongAnswer'],
    datasets: [
      {
        
        data:array,
        backgroundColor: [
          '#095499',
          '#00366C',
          '#9AC4DF',
          '#000000',
         
        ],
       
        borderWidth: 1,
      },
    ],
  };
//  console.log(result);
    return (
        <div className=''>
          <Pie data={data}/>  
        </div>
    );
};

export default PieChart;