import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import ParentCategories from './Categories/ParentCategories';
import { useDispatch, useSelector } from 'react-redux';
import { handleMobileCategory } from '../../../features/mobileCategory/mobileCategorySlice';

const MobileMenuCategories = () => {
    const dispatch = useDispatch();

        const { logo } = useSelector(state => state.siteInfo);
        const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;

        const showMobileMenuCategory = useSelector((state) => state.mobileCategory.mobileCategory);


        return (
            <>
                <div 
                    className={`
                        ${
                            showMobileMenuCategory 
                            ? 'scale-x-100' 
                            : 'scale-x-0'
                        } 
                        duration-300 z-50 fixed`
                    } 
                    
                >
                    <div className="absolute bg-white top-0 w-screen h-screen md:hidden rounded-b-lg">
                        <div className='flex justify-between px-10 mt-7'>
                            <img src={siteLogo} alt="logo" className='h-10 w-15 mr-2' />
                            {
                                <button 
                                onClick={() => dispatch(handleMobileCategory())}
                                >
                                    <MdOutlineClose className='w-8 h-8 duration-300' />
                                </button>
                            }
                        </div>
                        <div className='mt-10'>
                            <ParentCategories />
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default MobileMenuCategories;