import React, { useEffect, useState } from 'react';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import RegisterBackground from "../../assets/images/coursebanner.jpg";
import { useRegisterMutation } from '../../features/auth/authAPI';
import AlertMessage from '../../components/ui/AlertMessage';
import SEO from '../../components/ui/SEO';

import * as sha256 from 'crypto-js/sha256';



const Register = () => {

    const [firstName, setFirstName]                         = useState('');
    const [lastName, setLastName]                           = useState('');
    const [username, setUsername]                           = useState('');
    const [email, setEmail]                                 = useState('');
    const [password, setPassword]                           = useState('');
    const [confirmPassword, setConfirmPassword]             = useState('');
    const [phone, setPhone]                                 = useState('');
    const [agreed, setAgreed]                               = useState(true);
    const [alertMessage, setAlertMessage]                   = useState('');
    const [alertMessageType, setAlertMessageType]           = useState('');
    const [passwordShown, setPasswordShown]                 = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown]   = useState(false);
    const { btoa } = window;




    const togglePassword = (type) => {
        if (type === 'password') {
            setPasswordShown(!passwordShown);
        } else {
            setConfirmPasswordShown(!confirmPasswordShown);
        }
    };





    const [register, { data, isLoading, error }] = useRegisterMutation();


    useEffect(() => {

        if(error?.data?.errors?.password) {
            setAlertMessage(error?.data?.errors?.password);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.first_name) {
            setAlertMessage(error?.data?.errors?.first_name);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.last_name) {
            setAlertMessage(error?.data?.errors?.last_name);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.username) {
            setAlertMessage(error?.data?.errors?.username);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.email) {
            setAlertMessage(error?.data?.errors?.email);
            setAlertMessageType('error');
        }
        else if (error?.data?.errors?.phone) {
            setAlertMessage(error?.data?.errors?.phone);
            setAlertMessageType('error');
        } 
        else if (data?.status === 0) {
            setAlertMessage(data?.message);
            setAlertMessageType('error');
        } else if (data?.status === 1) {
            setAlertMessage(data?.message);
            setAlertMessageType('success');
            setFirstName('');
            setLastName('');
            setUsername('');
            setEmail('');
            setPhone('');
            setPassword('');
            setConfirmPassword('');
            const phone = btoa(data?.user?.phone);
            // window.location.href = `/verify-otp/${data?.user?.phone}`;
            window.location.href = `account/verify-otp/${phone}`;
        }

		// setTimeout(() => setAlertMessage(''), 5000);

    }, [data, error]);




    const handleSubmit = (e) => {
        e.preventDefault();
        
        setAlertMessage('');
        setAlertMessageType('');

        if (password !== confirmPassword) {
            setAlertMessage('Passwords do not matched!');
            setAlertMessageType('error');
        } else {
            
            register({
                first_name: firstName,
                last_name: lastName,
                username,
                email,
                phone,
                password,
                password_confirmation: confirmPassword,
                accept_terms_and_conditions: agreed
            })
           
        }
    }




    return (
        <>
            <SEO 
                title="Register"
                url="/register"
            />
            <div style={{ backgroundImage:`url(${RegisterBackground})`, backgroundSize: 'cover' }}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <h1 className="text-3xl font-semibold text-center text-[#192b3d]">
                            Sign up
                        </h1>
                        {
                            alertMessage !== '' && <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage} /></div>
                        }
                        <form onSubmit={handleSubmit} className="mt-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label
                                        htmlFor="firstName"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        First Name <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        className={`block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40 ${error?.data?.errors?.first_name ? 'border-red-500' : ''}`}
                                        id='firstName'
                                        name='firstName'
                                        value={firstName || ''}
                                        onChange={e => setFirstName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="lastName"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Last Name <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        className={`block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40 ${error?.data?.errors?.last_name ? 'border-red-500' : ''}`}
                                        id='lastName'
                                        name='lastName'
                                        value={lastName || ''}
                                        onChange={e => setLastName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div>
                                    <label
                                        htmlFor="username"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Username <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type="text"
                                        className={`${error?.data?.errors?.username ? 'border-red-500' : ''} block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40`}
                                        id='username'
                                        name='username'
                                        value={username || ''}
                                        onChange={e => setUsername(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Email <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type="email"
                                        className={`block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40 ${error?.data?.errors?.email ? 'border-red-500' : ''}`}
                                        id='email'
                                        name='email'
                                        value={email|| ''}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Phone <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type="number"
                                        className={`${error?.data?.errors?.phone ? 'border-red-500' : ''} block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40`}
                                        id='phone'
                                        name='phone'
                                        value={phone|| ''}
                                        onChange={e => setPhone(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                <div className='relative'>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Password <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type={passwordShown ? "text" : "password"}
                                        className={`relative block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40 ${password !== confirmPassword || error?.data?.errors?.password ? 'border-red-500': ''}`}
                                        id='password'
                                        name='password'
                                        value={password|| ''}
                                        onChange={e => setPassword(e.target.value)}
                                        required
                                    />
                                    {
                                        passwordShown 
                                        ? <VscEyeClosed onClick={() => togglePassword('password')} className="absolute right-3 top-10" />
                                        : <VscEye onClick={() => togglePassword('password')} className="absolute right-3 top-10" />
                                    }
                                </div>
                                <div className='relative'>
                                    <label
                                        htmlFor="confirmPassword"
                                        className="block text-sm font-semibold text-gray-800"
                                    >
                                        Confirm Password <sup className='text-red-500'>*</sup>
                                    </label>
                                    <input
                                        type={confirmPasswordShown ? "text" : "password"}
                                        className={`relative block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40 $ ${password !== confirmPassword ? 'border-red-500': ''}`}
                                        id='confirmPassword'
                                        name='confirmPassword'
                                        value={confirmPassword || ''}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    {
                                        confirmPasswordShown 
                                        ? <VscEyeClosed onClick={() => togglePassword('confirmPassword')} className="absolute right-3 top-10" />
                                        : <VscEye onClick={() => togglePassword('confirmPassword')} className="absolute right-3 top-10" />
                                    }
                                </div>
                            </div>
                            <div className="flex items-start mt-2">
                                <div className="flex items-center h-5">
                                    <input 
                                        id="remember" 
                                        type="checkbox" 
                                        checked={agreed}
                                        onChange={e => setAgreed(e.target.checked)}
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" 
                                        required 
                                    />
                                </div>
                                <label htmlFor="remember" className="ml-2 text-sm font-medium text-slate-500">Accept terms & conditions</label>
                            </div>
                            <div className="mt-6">
                                {
                                    isLoading
                                    ?
                                        <button 
                                            type="button" 
                                            className="w-full flex items-center justify-center gap-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                            disabled
                                        >
                                            <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span> Register ... </span>
                                        </button>
                                    :
                                        <button 
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                        >
                                            Register
                                        </button>
                                }
                            </div>
                        </form>

                        <p className="mt-8 text-xs font-light text-center text-gray-700">
                            {" "}
                            Already have an account?{" "}
                            <Link
                                to="/login"
                                className="font-medium text-[#192b3d] hover:underline"
                            >
                                Sign In
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;