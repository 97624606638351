import React from 'react';
import ExamCategories from './Category/ExamCategories';
import ExamYears from './Year/ExamYears';
import ExamFeeTypes from './FeeType/ExamFeeTypes';

const Filter = () => {
    return (
        <div>
            <div className='border border-gray-300 border-dashed p-4'>
                <h3 className='text-xl font-semibold mb-3'>Category</h3>
                <hr />
                <ExamCategories />
            </div>
            <div className='border border-gray-300 border-dashed p-4 mt-10'>
                <h3 className='text-xl font-semibold mb-3'>Year</h3>
                <hr />
                <ExamYears />
            </div>
            <div className='border border-gray-300 border-dashed p-4 mt-10'>
                <h3 className='text-xl font-semibold mb-3'>TYPE</h3>
                <hr />
                <ExamFeeTypes />
            </div>
        </div>
    );
};

export default Filter;