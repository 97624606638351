import React from 'react'
import { Link } from 'react-router-dom';
import DefaultThumbnailImage from '../../assets/images/course5.jpeg';
import { TfiClose } from 'react-icons/tfi';
import { HiOutlineHeart } from 'react-icons/hi2';
import { useDispatch } from 'react-redux';
import { calculateCartAmounts, removeToCart } from '../../features/cart/cartSlice';

const Course = ({item}) => {

    const dispatch = useDispatch();

    const { 
        id,
        categoryName,
        title,
        slug,
        regularFee,
        discountAmount,
        courseFee,
        thumbnailImage,
        averageRating,
        totalLecture,
        totalDuration
    } = item || {};


    const thumbnail = process.env.REACT_APP_BASE_URL + '/' + thumbnailImage;





    const removeHandler = () => {
        dispatch(removeToCart({id, title}));
        dispatch(calculateCartAmounts());
    }





    return (
        <div className='flex py-4 h-32'>
            <img className='w-1/5' src={thumbnailImage !== null ? thumbnail : DefaultThumbnailImage} alt="thumbnail" />
            <div className='w-4/5 flex justify-between'>
                <div className='flex flex-col justify-between px-4'>
                    <div className=''>
                        <h1 className='text-lg font-semibold'><Link to={`/courses/${id ? slug : (slug ? slug : '')}`}>{title}</Link></h1>
                        <div className='space-x-2'>
                            <Link className='bg-orange-200 text-orange-900 text-xs font-semibold py-0.5 px-1.5'>
                                {categoryName}
                            </Link>
                        </div>
                    </div>
                    <small className='text-gray-500'>
                        {totalDuration} total hours &#x2022; {totalLecture} lectures
                    </small>
                </div>
                <div className='flex flex-col justify-between items-end'>
                    {
                        courseFee > 0
                        ?   <div className='flex flex-col items-end'>
                                <h4 className='font-semibold text-indigo-700'>৳ {courseFee}</h4>
                                {
                                    discountAmount > 0 ? <del className='text-xs font-medium text-slate-600'>৳ {regularFee}</del> : ''
                                }
                            </div>
                        : <span className='bg-indigo-700 text-indigo-50 text-xs font-medium py-0.5 px-1.5 rounded-md'>Free</span>
                    }
                    <HiOutlineHeart className='w-5 h-5 mt-2 ml-2' />
                    <TfiClose onClick={removeHandler} className='w-5 h-5 mt-2 ml-2 cursor-pointer hover:text-red-500' />
                </div>
            </div>
        </div>
    )
}

export default Course