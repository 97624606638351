import React from 'react';
import "react-whatsapp-chat-widget/index.css";
import { useSelector } from 'react-redux';


import ReactWhatsappButton from "react-whatsapp-button";

const ReactWhatsapp = () => {
    const {  phone } = useSelector(state => state.siteInfo);

    return (
        <>
       
    <ReactWhatsappButton
        countryCode="880"
        phoneNumber={phone}
        style={{
            // backgroundColor: "#00a4f5",
            right: "unset",
            left: "10px",
        }}
      />
        </>
    );
};

export default ReactWhatsapp;