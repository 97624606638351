import React from 'react'

const AboutItem = ({aboutCount}) => {

    const { title, count } = aboutCount;

    return (
        <div className='bg-[#E3FBF2] rounded-lg p-6 text-[#292d60] hover:text-gray-800'>
            <div className='text-center'>
                <h1 className='text-3xl font-bold'>{count}</h1>
                <p>{title}</p>
            </div>
        </div>
    )
}

export default AboutItem