import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mobileTakeExam: false,
  };



const showMobileTakeExamSlice = createSlice({
    name: 'mobileTakeExam',
    initialState,
    reducers: {
      handleMobileTakeExam: (state) => {
        state.mobileTakeExam = !state.mobileTakeExam;
      },
    },
  });

  export const { handleMobileTakeExam } = showMobileTakeExamSlice.actions;

export default showMobileTakeExamSlice.reducer;