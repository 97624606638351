import React from 'react';

const ExamSkeleton = () => {
    return (
        <div className='relative h-[380px] animate-pulse mb-10'>
            <div className='absolute w-full z-10 flex justify-between h-full bg-slate-50 border-2 border-gray-500'>
                <div className='w-3 h-full bg-slate-400'></div>
                <div className='flex flex-col items-center justify-between w-full relative'>
                    <small className='absolute top-2 h-5 w-10 bg-gray-200 right-2 rounded-xl'></small>
                    <div className='h-full w-full flex items-center justify-center'>
                        <h1 className='w-[70%] h-10 rounded-xl bg-gray-200'></h1>
                    </div>
                    <div className='w-full h-[200px] bg-gray-200 mb-20'>

                    </div>
                </div>
            </div>
            <div className='absolute -skew-y-6 bg-slate-50 border-2 border-gray-500 -top-3.5 h-20 w-[90%] z-0'>

            </div>
        </div>
    );
};

export default ExamSkeleton;