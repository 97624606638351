import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

const SEO = ({title = '', description = '', name = '', type = 'article', url = '/'}) => {
    
	let { siteName, siteTitle, shortDescription } = useSelector(state => state.siteInfo);

    siteTitle   = siteTitle !== '' ? siteTitle : 'Edu Tv'
    title       = title !== '' && title !== undefined ? title + ' | ' + siteTitle : siteTitle;

    description = description !== '' ? description : shortDescription;
    name = name !== '' ? name : siteName;
    
    return (
        <Helmet>
            <title>{siteName} | {title}</title>
            <meta name='description' content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <link rel="canonical" href={url} />
        </Helmet>
    )
}

export default SEO