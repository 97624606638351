import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleMobileTakeExam } from '../../../features/mobileTakeExam/mobileTakeExamSlice';

const MobileMenuTakeExam = () => {

const dispatch = useDispatch()
    const { logo } = useSelector(state => state.siteInfo);
    const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;

    const showMobileTakeExamMenu = useSelector((state) => state.mobileTakeExam.mobileTakeExam);

   

    // exam data 
    // const { data, isLoading, isError } = useGetExamsCategoriesQuery();
    const data = {};
    const { data: examsData } = data || {};
    const { data: examData } = examsData || {};


    let examMenu = null;


    // if (!isLoading && !isError && examData?.length > 0) {
    //     examMenu = examData.map(menu => (
    //         <li key={menu.id} className="group py-3 font-base ">
    //             <a href={menu.slug} className={`hover:bg-[#292d60] px-5 py-2 hover:text-white rounded-lg`} >
    //                 {menu.name}
    //                 {
    //                     menu.totalexams.length > 0 ? <IoIosArrowForward className='inline group-hover:rotate-90' /> : ''
    //                 }
    //             </a>

    //             <ul className='hidden group-hover:block'>
    //                 {menu.totalexams.map(child => (
    //                     <li key={child.id} className='p-2 '>
    //                         <Link className='hover:bg-[#292d60] px-5 py-2 hover:text-white rounded-lg' to={`take-exam/${child.slug}`}>{child.title}</Link>
    //                     </li>
    //                 ))}
    //             </ul>
    //         </li>
    //     ))
    // }



    return (

        <div>
            <div className={`${showMobileTakeExamMenu ? 'scale-x-100 ' : ' scale-x-0 '} duration-300 z-50 fixed`} >

                <div className=" absolute bg-white top-0 w-screen h-screen md:hidden rounded-b-lg">


                    <div className='flex justify-between px-10 mt-7'>
                        <img src={siteLogo} alt="" className='h-10 w-15 mr-2' />
                        {

                            showMobileTakeExamMenu &&
                            <button className=" "  onClick={() => dispatch(handleMobileTakeExam())}>
                                <MdOutlineClose className='w-8 h-8 duration-300' />
                            </button>
                        }

                    </div>

                    <div className='mt-10'>
                        <ul className='text-center font-semibold'>

                            {
                                examMenu
                            }

                        </ul>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default MobileMenuTakeExam;