import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function AuthRouteProvider({ children, redirectTo = '/' }) {

    const location = useLocation();

 
  
    const isLoggedIn = useAuth();
    if (!isLoggedIn) {
      localStorage.setItem('prevPathname', JSON.stringify(location?.pathname));
        return (
          <Navigate
            to={{
              pathname: '/login',
              state: { pathName: location?.pathname }
            }}
            replace
            />
            );
            
      }
    
      return children;
   
}