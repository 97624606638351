import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeFeeType, setFeeTypes } from '../../../../features/exams/examsSlice';


const ExamFeeType = ({feeType}) => {


    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();





    useEffect(() => {
        if (isChecked) {
            dispatch(setFeeTypes(feeType));
        } else {
            dispatch(removeFeeType(feeType));
        }
    }, [isChecked, feeType, dispatch]);





    return (
        <li className="flex items-center mb-4">
            <input 
                type="checkbox" 
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer" 
                onChange={() => setIsChecked(!isChecked)}
                value={feeType || ''}
                id={feeType} 
                checked={isChecked}
            />
            <label 
                htmlFor={feeType} 
                className="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >
                {feeType}
            </label>
        </li>
    );
};

export default ExamFeeType;