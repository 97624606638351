import React from 'react'
import { useGetShowInMenuParentCourseCategoriesQuery } from '../../../../features/courseCategories/courseCategoriesAPI';
import ParentCategory from './ParentCategory';

const ParentCategories = () => {

    const { data, isLoading, isError } = useGetShowInMenuParentCourseCategoriesQuery();
    const { data: responseData } = data || {};
    const { data: showInMenuParentCourseCategories } = responseData || {};

    let content = null;

    if (!isLoading && !isError && showInMenuParentCourseCategories?.length > 0) {
        content = showInMenuParentCourseCategories.map(category => <ParentCategory key={category?.id} category={category} />);
    }

    return (
        <>     
        
            
            {content}
            
      
        </>

    );
}

export default ParentCategories