import React, { useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';

const FaqsItem = ({ key, faq }) => {

    const { title, description } = faq || {};

    const [faqList, setFaqList] = useState(false);

    const toggleFAQ = () => {
        setFaqList(!faqList)
    };

    return (
        <>


            <div className="single-faq w-full rounded-lg border border-[#F3F4FE] bg-white  ">
                <button
                    className="faq-btn flex w-full text-left"
                onClick={toggleFAQ}

                >

                    <div className="w-full flex items-center justify-between px-5 py-3">
                        <h4 className="text-lg font-semibold text-black">
                            {title}
                        </h4>
                        <AiOutlineLeft className={`${faqList ? '-rotate-90' : '' } duration-300`}/>
                    </div>
                </button>
                <div className={`faq-content pl-[62px]`}>
                    <p className="pb-3 text-base leading-relaxed text-body-color">
                        {faqList && <p className="mt-2 duration-300 ease-in-out">{description}</p>}
                    </p>
                </div>
            </div>



        </>
    );
};

export default FaqsItem;