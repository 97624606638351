import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth"

export default function PublicRouteProvider({children}) {

    const prevPathname = localStorage.getItem('prevPathname');

    // console.log('prevPathParse');
    const isLoggedIn = useAuth();

  

    if (isLoggedIn) {
        const redirectPath = '/' || prevPathname;
        return <Navigate to={redirectPath} replace />;
      }
      
      return children;
   
}