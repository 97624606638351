import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
    cartItems: localStorage.getItem('edutvCartItems') ? JSON.parse(localStorage.getItem('edutvCartItems')) : [],
    cartTotalItems: localStorage.getItem('edutvCartItems') ? JSON.parse(localStorage.getItem('edutvCartItems'))?.length : 0,
    cartSubtotal: 0,
    cartVatPercentage: 0,
    cartVatAmount: 0,
    cartDiscountAmount: 0,
    cartDiscountPercentage: 0,
    cartCouponId: null,
    cartCouponCode: null,
    cartCouponDiscountAmount: 0,
    cartCouponDiscountPercentage: 0,
    cartCouponAmount :0,
    cartGrandTotal: 0,
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);

            if (itemIndex !== -1) {
                toast.success(`${action.payload.title} already in cart.`);
            } else {
                state.cartItems = [...state.cartItems, action.payload];
                toast.success(`${action.payload.title} added to cart successfully.`);

                localStorage.setItem('edutvCartItems', JSON.stringify(state.cartItems));
            }
        },
        removeToCart: (state, action) => {
            const removeItem = state.cartItems.filter(item => item.id !== action.payload.id);

            state.cartItems = removeItem;
            localStorage.setItem('edutvCartItems', JSON.stringify(state.cartItems));
            
            toast.success(`${action.payload.title} remove from cart.`);
        },
        clearCartItems: (state, action) => {
            state.cartItems = [];
            toast.success('Cart has been clear successfully!');

            localStorage.setItem('edutvCartItems', JSON.stringify(state.cartItems));
        },
        clearCartItemsAfterCheckoutSuccess: (state, action) => {
            state.cartItems = [];
            localStorage.setItem('edutvCartItems', JSON.stringify(state.cartItems));
        },
        setCouponInfo: (state, action) => {
            state.cartCouponId = action.payload.id;
            state.cartCouponCode = action.payload.code;
            state.cartCouponDiscountAmount = action.payload.amount;
            state.cartCouponDiscountPercentage = action.payload.percentage;
        },
        setVatPercentage: (state, action) => {
            state.cartVatPercentage = action.payload.percentage;
        },
        calculateCartAmounts: (state, action) => {
            let { subtotal, discountAmount } = state.cartItems.reduce((cartTotal, cartItem) => {
                
                const { discountAmount, regularFee, quantity } = cartItem;
                const subtotal              = Number(regularFee * quantity);
                const totalDiscountAmount   = Number(discountAmount * quantity);

                cartTotal.subtotal          += subtotal;
                cartTotal.discountAmount    += totalDiscountAmount;

                return cartTotal;
            }, { 
                subtotal: 0,
                discountAmount: 0,
            });

            const discountPercent           = (discountAmount / subtotal) * 100;
            const vatPercentage             = Number(state.cartVatPercentage);
            const vatAmount                 = (subtotal * vatPercentage) / 100;

            const couponPercentage             = Number(state.cartCouponDiscountPercentage);
            const couponAmount                 = (subtotal * couponPercentage) / 100;

            let grandTotal                = Math.ceil(subtotal + vatAmount - discountAmount - Number(state.cartCouponDiscountAmount <=0 ? couponAmount :state.cartCouponDiscountAmount));
            grandTotal = grandTotal < 0 ? 0: grandTotal;


            state.cartTotalItems            = state.cartItems?.length;
            state.cartSubtotal              = subtotal.toFixed(2);
            state.cartDiscountAmount        = discountAmount.toFixed(2);
            state.cartDiscountPercentage    = discountPercent.toFixed(2);
            state.cartVatAmount             = vatAmount.toFixed(2);
            state.cartCouponAmount          = couponAmount.toFixed(2);
            state.cartGrandTotal            = grandTotal.toFixed(2);
        },
    }
});

export const {
    addToCart,
    removeToCart,
    clearCartItems,
    clearCartItemsAfterCheckoutSuccess,
    setCouponInfo,
    setVatPercentage,
    calculateCartAmounts,
} = cartSlice.actions;

export default cartSlice.reducer;