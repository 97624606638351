import { apiSlice } from "../api/apiSlice";

export const socialLinksAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSocialLinks: builder.query({
            query: () => '/api/v1/social-links',
        }),
    })
});

export const { 
    useGetSocialLinksQuery, 
} = socialLinksAPI;