import React from 'react';
import demoAvater from '../../../assets/images/defaultblog.png';
import { useGetAccountCourseQuery } from '../../../features/courses/coursesAPI';
import { useGetEnrollDataQuery } from '../../../features/enrollments/enrollmentsAPI';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, calculateCartAmounts } from '../../../features/cart/cartSlice';
import { FaArrowRight } from 'react-icons/fa';
import { CgEye } from 'react-icons/cg';
import { AiOutlineShoppingCart } from 'react-icons/ai';
const DashboardItem = ({ suggestCourse }) => {


    const { data: courseData } = useGetAccountCourseQuery([]);
    const { data: enrollData } = useGetEnrollDataQuery();
    const { data: enorllItems } = enrollData || {};
    const { data: enrollitem } = enorllItems || {};
    const navigate = useNavigate();

    const { id,
        title,
        courseFee,
        thumbnailImage,
        category,
        slug,
        regularFee,
        discountAmount,
        averageRating,
        totalLecture,
        totalDuration } = suggestCourse || {};
    const thumbnailBg = process.env.REACT_APP_BASE_URL + '/' + thumbnailImage;

    const existCourse = courseData?.data?.data?.find((exist) => exist?.id === id);
    const { cartItems } = useSelector(state => state.cart);


    const dispatch = useDispatch();
    const itemIndex = cartItems?.findIndex(item => item.id === id);
    const existEnroll = enrollitem?.find((singleItem) => singleItem?.enrollment_items?.find((item) => item?.course_id === id))

    const addToCartHandler = () => {
        dispatch(addToCart({
            id,
            title,
            slug,
            categoryName: category?.name,
            regularFee,
            discountAmount,
            courseFee,
            thumbnailImage,
            averageRating,
            totalLecture,
            totalDuration,
            quantity: 1,
        }));

        dispatch(calculateCartAmounts());
    };


    const handleEnrollNow = () => {
        addToCartHandler();
        navigate('/cart');
    }
    return (
        <>
            <div class="my-1 px-1 w-full ">
                <article class="overflow-hidden rounded-lg shadow-lg h-[400px] bg-white relative">
                    <a href="#">
                        <img className="relative h-fit w-full object-cover transition-transform duration-500"
                            src={(thumbnailImage === 'assets/img/avatar.png' || thumbnailImage === null) ? demoAvater : thumbnailBg}
                            alt="" />
                    </a>

                    <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                        <h1 class="text-lg">
                            <a class="no-underline hover:underline text-black" href="#">
                                {title}
                            </a>
                        </h1>
                        <p class="text-grey-darker text-sm">
                            <span>{courseFee > 0 ?
                                (
                                    discountAmount > 0 ? <><span className='line-through text-[#caca]'>৳ {regularFee} </span> ৳ {courseFee} </> : courseFee
                                )
                                :
                                'Free'}</span>
                        </p>
                    </header>

                    <footer class=" leading-none p-2 w-full md:p-4 bottom-0 absolute">
                        <div className="flex justify-between items-center">
                           <div>
                           {
                                existCourse ? <>
                                    <Link to={`/user/account/courses/${slug}`} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-black transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-orange-500 before:hover:scale-x-100">
                                        <span className=" text-xs">
                                            Go To Course
                                            <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                                        </span>
                                    </Link>
                                </>
                                    :
                                    (
                                        existEnroll ?
                                            <Link to={`/user/account/enrollments`} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-black transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-orange-500 before:hover:scale-x-100">
                                                <span className=" text-xs">
                                                    View EnrollPage
                                                    <FaArrowRight className='ml-2 inline animate-animation-bounch-left' />
                                                </span>
                                            </Link>
                                            :
                                            itemIndex !== -1 ?
                                                (
                                                    <Link to={`/cart`} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-black transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-orange-500 before:hover:scale-x-100">
                                                        <span className=" text-xs">
                                                            View In Cart
                                                            <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                                                        </span>
                                                    </Link>
                                                )
                                                :
                                                (
                                                    <button onClick={handleEnrollNow} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-black transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-orange-500 before:hover:scale-x-100">
                                                        <span className=" text-xs">
                                                            Enroll Now
                                                            <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                                                        </span>
                                                    </button>
                                                )
                                    )

                            }
                           </div>

                            <div className='text-black mt-4 space-x-3 '>
                                <Link to={`/courses/${id ? slug : (slug ? slug : '')}`} className='inline-block'>
                                    <CgEye className='w-6 h-6 ' />
                                </Link>
                                {
                                    existCourse || existEnroll ?
                                        <>
                                        </>
                                        :
                                        itemIndex === -1
                                        &&
                                        <button onClick={addToCartHandler} className=' '>
                                            <AiOutlineShoppingCart title='Add To Cart' className='hover:text-indigo-800 w-6 h-6' />
                                        </button>
                                }
                                {/* <button className=''>
                                <AiOutlineHeart title='Add To Wishlist' className='hover:text-indigo-800 w-6 h-6' />
                            </button> */}
                            </div>


                        </div>

                    </footer>

                </article>


            </div>
        </>
    );
};

export default DashboardItem;