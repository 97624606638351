import React from 'react';

const ExamdetailsSkeleton = () => {
    return (
        <div>
            <div className="bg-gray-100 py-6">
                <div className="container mx-auto max-w-[1260px]">
                    <div className="">
                        <div className="w-full px-4">
                            <div className="h-6 bg-gray-300 mb-4"></div>
                            <div className="h-4 bg-gray-300 mb-4"></div>
                            <div className="h-4 bg-gray-300 mb-4"></div>
                            <div className="h-4 bg-gray-300 mb-4"></div>
                            <div className="h-4 bg-gray-300 mb-4"></div>
                         
                        </div>
                    </div>

                    <div className='grid grid-cols-8 gap-10'>
                        <div className='col-span-5'>

                            <div className="h-24 bg-gray-300 mb-4"></div>
                            <div className="h-6 bg-gray-300 mb-4"></div>
                            <div className="h-6 bg-gray-300 mb-4"></div>
                            <div className="h-6 bg-gray-300 mb-4"></div>
                            <div className="h-6 bg-gray-300 mb-4"></div>
                            <div className="h-6 bg-gray-300 mb-4"></div>

                        </div>
                        <div className='col-span-3'>
                            <div className="w-full rounded-lg shadow-md">
                                <div className="h-40 bg-gray-300 mb-4"></div>
                                <div className="h-4 bg-gray-300 mb-2"></div>
                                <div className="h-4 bg-gray-300 mb-2"></div>
                                <div className="h-4 bg-gray-300"></div>
                                <div className="h-4 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ExamdetailsSkeleton;