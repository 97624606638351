import { apiSlice } from "../api/apiSlice";

export const aboutAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAboutInfo: builder.query({
            query: () => '/api/v1/about',
        }),
    })
});

export const { 
    useGetAboutInfoQuery, 
} = aboutAPI;