import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   
    description: '',
    
};

const supportSlice = createSlice({
    name: "support",
    initialState,
    reducers: {
        setSupport: (state, action) => {
       
            state.description       = action.payload?.description;
        
        }
    }
});

export const { 
    setSupport, 
} = supportSlice.actions;

export default supportSlice.reducer;