import React from 'react';
import ExamFeeType from './ExamFeeType';


const ExamFeeTypes = () => {

    const feeTypes = ['Free', 'Paid'];

    return (
        <ul className='mt-3 -mb-4 max-h-[300px] overflow-y-scroll'>
            {
                feeTypes.map((feeType, index) => <ExamFeeType key={index} feeType={feeType} />)
            }
        </ul>
    );
};

export default ExamFeeTypes;