import React from 'react'
import { useGetCourseTopicLessonsQuery } from '../../../../../features/courses/coursesAPI';
import LessonSkeleton from '../../../../ui/Skeleton/LessonSkeleton';
import Lesson from './Lesson';

const Lessons = ({topicId}) => {

    const { data, isLoading, isError } = useGetCourseTopicLessonsQuery(topicId);
    const { data: courseTopicLessonsData } = data || {};
    const { data: courseTopicLessons } = courseTopicLessonsData || {};

    let courseTopicLessonContent = null;

    if (isLoading) {
        courseTopicLessonContent  = <div className='flex flex-col gap-1'>
                                        <LessonSkeleton />
                                        <LessonSkeleton />
                                        <LessonSkeleton />
                                        <LessonSkeleton />
                                        <LessonSkeleton />
                                        <LessonSkeleton />
                                    </div>;
    } else if (!isLoading && isError) {
        courseTopicLessonContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseTopicLessons?.length === 0) {
        courseTopicLessonContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No topics found!</div>;
    } else if (!isLoading && !isError && courseTopicLessons?.length > 0) {
        courseTopicLessonContent = courseTopicLessons.map(lesson => <Lesson key={lesson.id} lesson={lesson} />)
    }


    return (
        <ul className='divide-y divide-gray-300'>
            {
                courseTopicLessonContent
            }
        </ul>
    )
}

export default Lessons