import { apiSlice } from "../api/apiSlice";

export const certificatesAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCertificates: builder.query({
            query: ({ search, page }) => {

                let query = '';

                if (search !== '' || search !== null || search !== undefined) {
                    query +=`search=${search}`;
                }

                if (page !== '' || page !== null || page !== undefined) {
                    query +=`&page=${page}`;
                }

                return `/api/v1/account/lesson/certificates?${query}`;
            },
            providesTags: ["Certificates"],
            transformResponse(apiResponse) {
                const { data, status } = apiResponse || {};
                const { data: certificates } = data || {};

                if (status) {
                    return {
                        certificates: certificates,
                        currentPage: data?.current_page,
                        perPage: data?.per_page,
                        totalCertificates: data?.total,
                    };
                }
            },
        }),
        getCertificatesData: builder.query({
            query: () => '/api/v1/account/lesson/certificates',
        }),
        showCertificatesDetails: builder.query({
            query: (invoiceNo) => `/api/v1/account/lesson/certificates/${invoiceNo}`,
        }),
    })
});

export const { 
    useGetCertificatesQuery,
    useGetCertificatesDataQuery,
    useShowCertificatesDetailsQuery,
} = certificatesAPI;