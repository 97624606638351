import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetExamCategoriesByParentQuery } from '../../../../features/exams/examsAPI';
import { removeCategorySlug, setCategorySlugs } from '../../../../features/exams/examsSlice';

const ExamCategory = ({ parentExamCategory }) => {

    const [isChecked, setIsChecked] = useState(false);
    const { id, name, slug, totalExam } = parentExamCategory || {};
    const dispatch = useDispatch();

    const { data, isLoading, isError } = useGetExamCategoriesByParentQuery(id);
    const { data: responseData } = data || {};
    const { data: examCategories } = responseData || {};

    let content = null;

    if (!isLoading && !isError && examCategories?.length > 0) {
        content = examCategories.map(examCategory => <ExamCategory key={examCategory.id} parentExamCategory={examCategory} />)
    }
    




    useEffect(() => {
        if (isChecked) {
            dispatch(setCategorySlugs(slug));
        } else {
            dispatch(removeCategorySlug(slug));
        }
    }, [isChecked, slug, dispatch]);





    return (
        <li className="flex flex-col mb-4">
            <div className='flex items-center u'>
                <input 
                    type="checkbox" 
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer" 
                    onChange={() => setIsChecked(!isChecked)}
                    value={name || ''}
                    id={id} 
                    checked={isChecked}
                />
                <label 
                    htmlFor={id} 
                    className="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
                >
                    {name} ({totalExam})
                </label>
            </div>
            <ul className='mt-3 -mb-4 ml-2'>
                {
                    content 
                }
            </ul>
        </li>
    );
};

export default ExamCategory;