import React from 'react';
import { useSelector } from 'react-redux';
import SEO from '../../components/ui/SEO';
import style from './support.module.css';
import DOMPurify from 'dompurify';

const Support = () => {

    const { description } = useSelector(state => state.support);



    return (
        <>
            <SEO
                title="Support"
                url="/support"
            />
            <div className={`mt-10 container m-auto max-w-[1260px] px-5`}>
            <div className={` ${style.size}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
                />
            </div>
        </>
    );
};

export default Support;