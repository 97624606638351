import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useStoreEnrollmentMutation } from '../../features/checkout/checkoutAPI';
import { toast } from "react-hot-toast";

// const EnrollNow = ({ courseId, regularFee, discountAmount, courseFee }) => {
const EnrollNow = ({handleEnrollNow}) => {

    // const [alertMessage, setAlertMessage] = useState('');
    // const [alertMessageType, setAlertMessageType] = useState('');

    // const [storeEnrollment, {data, isLoading, isError, error}] = useStoreEnrollmentMutation();

    // const handleEnrollNow = () => {

    //     setAlertMessage('');  
    //     setAlertMessageType('');

    //     storeEnrollment({
    //         source                      : 'Website',
    //         subtotal                    : regularFee,
    //         total_vat_amount            : 0,
    //         item_total_discount_amount  : discountAmount,
    //         coupon_discount_amount      : 0,
    //         course_id                   : [courseId],
    //         regular_fee                 : [regularFee],
    //         discount_amount             : [discountAmount],
    //         course_fee                  : [courseFee],
    //         quantity                    : [1],
    //     });
    // }





    // useEffect(() => {
    //     if (data?.status) {
    //         toast.success('Course Enrollment Successfully!');
    //     }
    // }, [data]);


    return (
        <button onClick={handleEnrollNow} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-white transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100">
            <span className=" text-xs">
                Enroll Now
                <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
            </span>
        </button>

        // <button 
        //     type="button" 
        //     className="mt-3 flex items-center justify-center gap-2 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium text-white transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100"
        //     disabled
        // >
        //     <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        //         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        //         <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        //     </svg>
        //     <span>Enrolling...</span>
        // </button>
    )
}

export default EnrollNow