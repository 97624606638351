import React from 'react';
import Course from '../../components/Cart/Course';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { calculateCartAmounts, setCouponInfo } from '../../features/cart/cartSlice';
import { useCouponAmountMutation } from '../../features/cart/cartAPI';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProgressBar from '../../components/Checkout/ProgressBar';
import SEO from '../../components/ui/SEO';

const Cart = () => {

    const navigate = useNavigate();
    const [couponCode, setCouponCode] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [access, setAccess] = useState(false);
    
    const {cartItems, cartSubtotal, cartDiscountAmount, cartDiscountPercentage, cartCouponCode, cartCouponDiscountAmount ,cartCouponDiscountPercentage,cartCouponAmount, cartTotalItems} = useSelector(state => state.cart);
    const dispatch = useDispatch();

    // console.log(cartCouponAmount);
    
    // console.log(cartCouponDiscountPercentage);

    useEffect(() => {
        dispatch(calculateCartAmounts())
    }, [dispatch]);


    const [couponAmount, { data, isLoading }] = useCouponAmountMutation();





    const handleCouponAmount = (e) => {
        e.preventDefault();

        setMessage('');
        setMessageType('');

        couponAmount({code: couponCode ,                     
            course_id: cartItems?.map(item => item.id),
        });
    }





    useEffect(() => {

        if (data?.errors?.code[0]) {
            setMessage(data?.errors?.code[0]);
            setMessageType('error');
        } else if (data?.status === 0) {
            setMessage(data?.message);
            setMessageType('error');
        } else if (data?.status === 1) {
            setMessage(data?.message);
            setMessageType('success');
            setCouponCode('');
        }

        setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 3000);
    }, [data]);





    const handleCouponRemove = () => {
        dispatch(setCouponInfo({id: null, code: null, amount: 0, percentage:0 }));
        dispatch(calculateCartAmounts());
        setMessage('Coupon removed successfully!');
        setMessageType('error');

        setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 3000);
    }





    let subtotalWithDiscount = cartSubtotal - cartDiscountAmount - (cartCouponDiscountAmount??0) -(cartCouponAmount??0);
     subtotalWithDiscount = subtotalWithDiscount < 0 ? 0: subtotalWithDiscount;

// console.log(cartCouponDiscountAmount);
// console.log(cartCouponAmount);


    useEffect(() => {
        if (cartItems?.length === 0) {
            navigate('/');
        } else if (cartItems?.length > 0) {
            setAccess(true);
        }
    }, [cartItems])





    return access && (
        <>
            <SEO 
                title='Cart'
                description='Cart'
                url='/cart'
            />
            <div className="py-10 flex justify-center items-center rounded-md">
                <div className="card w-[90%] rounded-md shadow-md bg-white p-5">
                    <ProgressBar />
                    <div className='mt-4 lg:grid lg:grid-cols-3 gap-16'>
                        <div className='lg:col-span-2'>
                            <h1 className='text-xl font-medium mb-2'>{cartTotalItems} { cartTotalItems > 1 ? 'Courses' : 'Course' } in Cart</h1>
                            <hr />
                            <div className='divide-y'>
                                {
                                cartItems?.length > 0 && cartItems.map(item => <Course key={item.id} item={item} />)
                                }
                            </div>
                        </div>
                        <div className='flex flex-col gap-5 divide-y'>
                            <div className='flex flex-col gap-2'>
                                <h3 className='text-gray-500 font-bold'>Total:</h3>
                                <div className='flex flex-col gap-0'>
                                    <h2 className='text-slate-900 text-3xl font-bold'>৳<span>{subtotalWithDiscount}</span></h2>
                                    {
                                        cartDiscountAmount > 0 || cartCouponDiscountAmount > 0 || cartCouponAmount>0
                                        ? <>
                                            <del className='text-slate-700 font-medium'>৳{cartSubtotal}</del>
                                            {
                                                cartDiscountAmount > 0 && <p>{cartDiscountPercentage}% off</p>
                                            }
                                          </> 
                                        : ''
                                    }
                                    {
                                        cartCouponDiscountAmount > 0 || cartCouponAmount>0 ? <small className='font-semibold text-indigo-900'>You got extra ৳{cartCouponDiscountAmount<=0?cartCouponAmount:cartCouponDiscountAmount} discount from coupon</small> : ''
                                    }
                                </div>
                                <Link to='/checkout' className='py-2.5 w-full bg-indigo-900 hover:bg-indigo-800 text-indigo-50 font-medium text-center'>Checkout</Link>
                            </div>
                        
                            <div className='py-3'>
                                {
                                    cartCouponDiscountAmount > 0 || cartCouponAmount > 0
                                    ? (
                                        <div className='flex'>
                                            <input 
                                                type="text" 
                                                value={cartCouponCode || ''}
                                                className="border border-slate-900 text-black bg-slate-200 font-medium text-sm focus:outline-none block w-full px-2.5 py-1.5" 
                                                disabled
                                            />
                                            <button 
                                                onClick={handleCouponRemove}
                                                className='text-center bg-red-900 hover:bg-red-800 text-red-50 px-5 text-sm font-medium'
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    ) : (
                                        <form onSubmit={handleCouponAmount} className='flex'>
                                            <input 
                                                type="text" 
                                                name="couponCode"
                                                value={couponCode || ''}
                                                onChange={e => setCouponCode(e.target.value)}
                                                className="border border-slate-900 text-gray-900 text-sm focus:outline-none block w-full px-2.5 py-1.5" 
                                                required 
                                                placeholder='Enter Coupon' 
                                            />
                                            <button 
                                                disabled={isLoading}
                                                className='text-center bg-indigo-900 hover:bg-indigo-800 text-indigo-50 px-5 text-sm font-medium'
                                            >
                                                Apply
                                            </button>
                                        </form>
                                    )
                                }
                                {
                                    message !== ''
                                    ? <small className={`${messageType === 'success' ? 'text-teal-800' : 'text-red-800'} font-semibold`}>{message}</small>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cart