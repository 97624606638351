import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

//   const { logo } = useSelector(state => state.siteInfo);


  useEffect(() => {
    // Simulate a delay in loading content
    setTimeout(() => setIsLoading(false), 2000);
  }, []);

  return (
    <div className={`fixed top-0 left-0 w-full h-full bg-white z-50 ${isLoading ? '' : 'hidden'}`}>
      <div className="flex flex-col justify-center items-center h-full">
        <svg className="animate-spin h-10 w-10 text-gray-900 mb-2" viewBox="0 0 24 24"></svg>
        <div>
            <img src={logo} className="h-14 w-22" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Loader;