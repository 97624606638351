import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

const MobileSearchForm = () => {

    const [searchQuery, setSearchQuery] = useSearchParams("");
    const [value, setValue] = useState(searchQuery.get('search'));


    const navigate = useNavigate();


    const handleSearch = (event) => {
        event.preventDefault();

        if (value) {
            const options = {
                pathname: '/course-page',
                search: `?${createSearchParams({ search: value })}`,
            };

            navigate(options, { state: value, replace: true });
            

        } else if (!value) {
            // navigate('/')
        }
    };



    return (
        <div>
            <form onSubmit={handleSearch}>
                <div className=' border bg-gray-200 border-gray-700 hover:border-[#292d60] rounded-full flex items-center'>
                    <input
                    type="search"
                    name='search'
                    placeholder='search'
                    value={value || ''}
                    onChange={(e) => setValue(e.target.value)}
                    className='w-full outline-none px-3 rounded-full bg-gray-200' />
                    <button className=' bg-[#292d60] text-white hover:bg-[#1f235a] p-2 rounded-full'>
                        <BiSearch className='w-7 h-7' />
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MobileSearchForm;