import React from 'react';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useGetCourseLessonsQuery } from '../../../../features/courses/coursesAPI';
import VideoModalDataLIst from './VideoModalDataLIst';
import ReactPlayer from 'react-player';



const ViewVideoModal = ({ lesson, handleModal }) => {

  const { title, video } = lesson || {};
  const [currentVideo, setCurrentVideo] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const { slug } = useParams();

  const { data, isLoading, isError } = useGetCourseLessonsQuery(slug);
  const { data: courseLessonsData } = data || {};
  const { data: courseLessons } = courseLessonsData || {};
  let courseLessonContent = null;
  
    // aws 

    const playerConfig = {
      file: {
        attributes: {
          controlsList: 'nodownload'
        }
      },
      autoplay: false
    };

  if (isLoading) {
    courseLessonContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
  } else if (!isLoading && isError) {
    courseLessonContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
  } else if (!isLoading && !isError && courseLessons?.length === 0) {
    courseLessonContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No free videos found!</div>;
  } else if (!isLoading && !isError && courseLessons?.length > 0) {


    courseLessonContent = courseLessons.map(videos =>
      <VideoModalDataLIst key={videos.id} videos={videos} setCurrentVideo={setCurrentVideo} setCurrentTitle={setCurrentTitle} currentTitle={currentTitle} currentVideo={currentVideo}/>
    )
  }



  return (
    <>
      <div
        className={`justify-center items-center flex fixed inset-0 z-50 outline-none focus:outline-none duration-300`}
      >
        <div className='mt-auto mb-auto  bg-[#12143d] lg:w-[40%] rounded-lg shadow-lg '>
          <div className='flex justify-between px-5 lg:py-4 text-white items-center'>
          <div className="ml-auto">
            <MdClose onClick={handleModal} />
          </div>
          </div>
            {
              currentTitle === '' ?
                <h1 className='text-xl px-5 text-white'>{`${title}`}</h1>
                :
                <h1 className='text-xl px-5 text-white'>{`${currentTitle}`}</h1>
            }
            

          <div className='px-5 py-4'>
            {
              currentVideo === '' ?
              <ReactPlayer
              url={`${video}`}
              playing={true}
              controls={true}
              config={playerConfig}
              width=""
              height="300px"
            />
                // <iframe className='w-full h-[300px]' src={`${video}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                :
                <ReactPlayer
                url={`${currentVideo}`}
                playing={true}
                controls={true}
                config={playerConfig}
                width=""
                height="300px"     
           
              />
                // <iframe className='w-full h-[300px]' src={`${currentVideo}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            }
          </div>
          <p className='text-white px-3 text-md font-semibold'>Free More videos</p>
          <div className='px-2 mb-4 max-h-[250px] overflow-y-auto'>
            <ul>
              {
                courseLessonContent
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ViewVideoModal;