import React, { useState } from 'react'
import Slider from 'react-slick';
import TagCourseItem from './TagCourseItem';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useGetCourseTagsQuery, useGetCoursesByTagQuery } from '../../../../features/courses/coursesAPI';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';
import { useParams } from 'react-router-dom';
import Pagination from '../../../ui/Pagination';
import CustomPagination from '../../../../pages/InstructorPage/CustomPagination';

const TagCourseAll = () => {

const {tagId} = useParams();


    const { data, isLoading, isError } = useGetCoursesByTagQuery(tagId);
    const { data: responseData } = data || {};
    const { data: tagCourses, meta } = responseData || {};

    const { data:Tags } = useGetCourseTagsQuery();
    const { data: courseTags } = Tags || {};

    const { current_page, per_page, total } = meta || {};
 
    // const perPageItems = per_page;
    // const totalItem = total;
    // const currentPage = current_page;


    // const handlePaginate = (page) => {
    //     setPage(page)
    // }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12; // Set the number of items per page
    const totalItems = tagCourses?.length || 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    
    // Calculate the start and end index of items for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let tagCoursesItems = null;
    let tag = null;
    if (isLoading) {
        tagCoursesItems = <div className='text-center text-blue-700 text-lg p-4 font-medium'><CourseSkeleton /></div>;

    } else if (!isLoading && isError) {
        tagCoursesItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && tagCourses?.length === 0) {
        tagCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && tagCourses?.length > 0) {
        const itemsForCurrentPage = tagCourses.slice(startIndex, endIndex);
        tagCoursesItems = itemsForCurrentPage.map(tagCourse => <TagCourseItem key={tagCourse.id} tagCourse={tagCourse} />);
       tag = courseTags?.find(tags =>tagId == tags?.tag?.id);
       
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };


    return (
        <div className='pb-7 mt-10 max-w-[1265px]  mx-auto'>
            <div className='mb-10'>
               
            <h1 className="text-2xl font-semibold">{tag?.tag?.name} Courses</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                
                    {
                        tagCoursesItems
                    }
                
            </div>

            <div className="mt-4 mb-3 ">
            {
                itemsPerPage < totalItems &&
                // perPageItems < totalItem &&
                // <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate} />
                <CustomPagination totalPages={totalPages} onPageChange={handlePageChange} />
            }
            </div>
        </div>
    );
}

export default TagCourseAll