import React from 'react'

const FAQ = ({faq}) => {

    const { title, description } = faq || {};

    return (
        <details className="p-4 border-b border-gray-300">
            <summary className="font-semibold cursor-pointer">{title}</summary>

            <div className="mt-3">
                <p className="text-sm leading-6 text-gray-600">
                    <p>{description}</p>
                </p>
            </div>
        </details>
    )
}

export default FAQ