import React, { useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io';
import ChildCategories from './ChildCategories';
import style from './MobileCategory.module.css';

const ChildCategory = ({ category }) => {
    const [isSubOpen, setIsSubOpen] = useState(false)



    const { id, name } = category || {};




    return (
        <>
            <li onClick={() => setIsSubOpen(true)} className={`${style.subParent} py-3 px-5 hover:bg-blue-600 hover:text-white`}>
                {name}
                {
                    !isSubOpen &&
                    <ul className={`${style.subParentChild}`}>
                        <ChildCategories parentId={id} isSubOpen={isSubOpen} />
                    </ul>


                }




            </li>
            <hr />
        </>
    )
}

export default ChildCategory