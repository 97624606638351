import React, { useEffect } from 'react'
import CourseCategory from '../../components/Home/CourseCategory/CourseCategories';
import LatestCourse from '../../components/Home/Course/Latest/LatestCourses';
import BecomeInstructor from '../../components/Home/BecomeInstructor';
import About from '../../components/about/About';
import Subscriber from '../../components/Subscriber/Subscriber';
import Sliders from '../../components/Home/Slider/Sliders';
import PopularCourses from '../../components/Home/Course/Popular/PopularCourses';
import SEO from '../../components/ui/SEO';
import Navbar from '../../components/layout/Header/Navbar';
import Tags from '../../components/Home/Course/Tag/Tags';
import Header from '../../components/layout/Header';
// import TestNavbar from '../../components/layout/TestNavbar/TestNavbar';
  


const Home = () => {
    localStorage.removeItem('prevPathname');
    return (
        <>
            <SEO />
            <Sliders />
            <PopularCourses />
            <CourseCategory />
            <LatestCourse />
            <BecomeInstructor />
            <Tags />
            <About /> 
            <Subscriber />
        </>
    )
}

export default Home