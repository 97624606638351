import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriberVerifying: false,
    subscriberVerifyStatus: 0,
};

const subscribersSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setSubscriberVerifyInfo: (state, action) => {
            state.subscriberVerifying = action.payload?.subscriberVerifying;
            state.subscriberVerifyStatus = action.payload?.subscriberVerifyStatus;
        }
    }
});

export const { 
    setSubscriberVerifyInfo,
} = subscribersSlice.actions;

export default subscribersSlice.reducer;