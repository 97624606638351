import React from 'react'
import { useParams } from 'react-router-dom';
import { useGetCourseFAQsQuery } from '../../../../features/courses/coursesAPI';
import FAQ from './FAQ';

const FAQs = () => {

    const { slug } = useParams();

    const { data, isLoading, isError } = useGetCourseFAQsQuery(slug);
    const { data: courseFAQsData } = data || {};
    const { data: courseFAQs } = courseFAQsData || {};

    let faqContent = null;

    if (isLoading) {
        faqContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
    } else if (!isLoading && isError) {
        faqContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseFAQs?.length === 0) {
        faqContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No faqs found!</div>;
    } else if (!isLoading && !isError && courseFAQs?.length > 0) {
        faqContent = courseFAQs.map(faq => <FAQ key={faq.id} faq={faq} />)
    }


    return (
        <div className='mt-10'>
            <h1 className='text-2xl text-semibold'>FAQ</h1>

            <div className='border border-gray-300 mt-4'>
                <div className="w-full space-y-2">
                    {
                        faqContent
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQs