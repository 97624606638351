import { apiSlice } from "../api/apiSlice";
import { calculateCartAmounts, setCouponInfo, setVatPercentage } from "./cartSlice";

export const cartAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        couponAmount: builder.mutation({
            query: (data) => ({
                url: '/api/v1/coupon-amount',
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const { data } = await queryFulfilled || {};
                    const { status, data: couponData } = data || {};

                    if (status) {
                        dispatch(setCouponInfo({id: couponData?.id, code: couponData?.code, amount: couponData?.amount, percentage: couponData?.percentage}));
                        dispatch(calculateCartAmounts());
                    }
                } catch (error) {}
            }
        }),
        getVatPercentage: builder.query({
            query: () => '/api/v1/vat-percentage',
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const { data } = await queryFulfilled || {};
                    const { status, percentage } = data || {};

                    if (status) {
                        dispatch(setVatPercentage({percentage}));
                        dispatch(calculateCartAmounts());
                    }
                } catch (error) {
                    // CATCH FROM UI
                }
            }
        }),
    })
});

export const { 
    useCouponAmountMutation,
    useGetVatPercentageQuery
} = cartAPI;