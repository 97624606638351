import React from 'react';
import { useState } from 'react';
import { useGetInstructorsQuery } from '../../features/Instructor/InstructorAPI';
import InstructorListItems from './InstructorListItems';
import CourseSkeleton from '../../components/ui/Skeleton/CourseSkeleton';
import Pagination from '../../components/ui/Pagination';

const InstructorList = () => {

    const [page, setPage] = useState(1);

    // Instructor Data
    const { data, isLoading, isError } = useGetInstructorsQuery(page);
    const { data: instructorsList } = data || {};
    const { data: instructors, meta: pages } = instructorsList || {};

    // console.log(instructorsList);
    const perPageItems = instructorsList?.per_page;
    const totalItem = instructorsList?.total;
    const currentPage = instructorsList?.current_page;
    const lastPage = instructorsList?.last_page;
    const totalPage = totalItem / perPageItems;

console.log(instructors)

    const handlePageChange = () => {
        setPage((prev) => prev + 1);
    }

    function previousClick() {
        setPage(page - 1)
    }

    const handlePaginate = (page) => {
        setPage(page)
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }
    // console.log(pageNumbers);


    let instructorItems = null;

    if (isLoading) {
        instructorItems = <>

            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>



        </>;
    } else if (!isLoading && isError) {
        instructorItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && instructors?.length === 0) {
        instructorItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && instructors?.length > 0) {
        instructorItems = instructors.map(instructor => <InstructorListItems key={instructor?.id} instructor={instructor} />);
    }

    //  console.log(instructors)


    return (
        <div className='mt-10'>
            <h1 className='text-2xl text-center font-semibold mb-3'>Instructors</h1>
            <div className='grid grid-cols-4 gap-4 max-w-[1260px] mx-auto px-5'>
                {
                    instructorItems
                }

            </div>

            {
                instructors?.length > 0 &&
                (
                    perPageItems < totalItem &&
                    <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate}/>
                )
            }
        </div>
    );
};

export default InstructorList;