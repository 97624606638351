import React from 'react'

const SliderSkeleton = () => {
    return (
        <div className='w-full bg-slate-200' style={{ height: '600px' }}>
            <div className="animate-pulse flex items-center justify-between pl-32 pt-40">
                <div className='flex flex-col gap-10'>
                    <h2 className='h-12 w-[600px] bg-slate-300 rounded-md'></h2>
                    <p className='h-6 w-[300px] bg-slate-300 rounded-md'></p>
                    <button className='w-44 h-14 bg-slate-300 rounded-full mt-8'></button>
                </div>
            </div>
        </div>
    )
}

export default SliderSkeleton