import React, { useEffect } from 'react'
import { FaArrowRight } from 'react-icons/fa';
import { FiShare } from 'react-icons/fi';
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs';
import { MdContentCopy, MdMail } from 'react-icons/md';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';


import { Link } from 'react-router-dom';
import { AiFillTwitterCircle } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';

const SliderItem = ({ slider }) => {

    const [copied, setCopied] = useState(false);
    const { courseId, title, shortDescription, link, image, course } = slider || {};
    const sliderImage = process.env.REACT_APP_BASE_URL + '/' + image;

    const ShareUrl = window.location.origin + '/courses/' + link;



    useEffect(() => {
        const id = setInterval(() => {
            setCopied(copied)
        }, 3000)
        return () => {
            clearInterval(id)
        }
    }, [setCopied])


    return (
        <div className='relative'>
            <div className='w-full' style={{ background: `url(${image && sliderImage}) center center no-repeat`, height: '600px', backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}></div>
            {/* <section className='absolute top-0 pt-16 pb-full align-center py-32 w-full'>
                <div className="mx-auto flex py-24 md:flex-row flex-col items-center max-w-[1265px]">
                    <div className="relative lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className=" p-3 mb-4 leading-10 font-bold text-white align-center text-5xl">
                            {title}
                        </h1>
                        <p className="text-white text-base capitalize font-normal p-3">
                            {shortDescription}
                        </p>
                        
                    </div>
                </div>
            </section> */}
            <section className=''>
                <div className='flex space-x-4 absolute bottom-5 left-5'>
                    <Link
                        to={`/courses/${courseId ? course?.courseSlug : (link ? link : '')}`}
                        className='text-white font-bold bg-[#292d60] hover:bg-black  rounded-3xl mt-14 px-4 py-2'
                    >
                        See Course <FaArrowRight className='inline' />
                    </Link>



                    <button className='text-white font-bold bg-transparent border hover:border-0 hover:bg-[#292d60] rounded-3xl mt-14 relative group px-4'>
  {copied ? (
    <>
      <p className=''>copied</p>
    </>
  ) : (
    <>
      Share
      <FiShare className="inline" />
    </>
  )}

  <ul className='absolute bg-white text-[#292d60] -translate-y-full p-3 invisible translate-x-0 transform  group-hover:-mt-7 duration-300 rounded-md group-hover:-translate-y-full group-hover:visible'>
    <li>
      <FacebookShareButton url={ShareUrl}>
        <BsFacebook className='p-2 w-10 h-10 hover:text-black  ' />
       
      </FacebookShareButton>
    </li>
    <li>
      <TwitterShareButton url={ShareUrl} >
        <AiFillTwitterCircle className='p-2 w-10 h-10 hover:text-black' />
      </TwitterShareButton>
    </li>
    <li>
      <EmailShareButton url={ShareUrl}>
        <MdMail className='p-2 w-10 h-10 hover:text-black' />
      </EmailShareButton>
    </li>
    <li>
      <CopyToClipboard text={`${ShareUrl}`} onCopy={(() => setCopied(!copied))}>
        <MdContentCopy className='p-2 w-10 h-10 hover:text-black' />
      </CopyToClipboard>
    </li>
  </ul>
</button>




                </div>
            </section>
        </div>
    )
}

export default SliderItem