import React from 'react'
import AccountCourseSkeleton from '../../ui/Skeleton/AccountCourseSkeleton';
import CourseItem from './CourseItem';


const Courses = ({ courses }) => {


    let courseItems = <>
        <AccountCourseSkeleton />
        <AccountCourseSkeleton />
        <AccountCourseSkeleton />
        <AccountCourseSkeleton />
    </>;



    if (courses !== undefined && courses?.length > 0) {
        courseItems = courses?.map((course, index) => (
            <CourseItem course={course} />
        ))

    } else if (courses !== undefined && courses?.length === 0) {
        courseItems = <h2 className='text-center text-xl text-red-500 font-medium'>No Courses Found!</h2>
    }





    return (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
            {
                courseItems

            }
        </div>
    )
}

export default Courses