import React, { useState } from 'react'
import { BsChevronDown } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Lessons from './Lesson/Lessons';

const Topic = ({topic}) => {

    const { topicId } = useParams();
    const { id, title, totalLecture, totalDuration } = topic || {};
    const [toggleAccordion, setToggleAccordion] = useState(true);


    return (
        <div className="rounded overflow-hidden p-1 h-400">
    <div className="outline-none">
        <div onClick={() => setToggleAccordion(!toggleAccordion)} className={`${topicId == id ? 'bg-gradient-to-tr from-indigo-900 to-cyan-600 text-white' : 'bg-gray-300 '} flex px-4 py-3 items-center justify-between cursor-pointer hover:bg-gradient-to-tr hover:from-indigo-900 hover:to-cyan-600 hover:text-white`}>
            <div>
                <h5 className='font-medium'>{title}</h5>
                <small>{totalLecture} { totalLecture > 1 ? 'Lectures' : 'Lecture'} | {totalDuration} Minutes</small>
            </div>
            <BsChevronDown className={`text-xl transform transition ease duration-700 ${toggleAccordion ? 'rotate-180' : ''}`} />
        </div>
        <div className={`${toggleAccordion ? 'max-h-[400px]' : 'max-h-0'} bg-gray-100 overflow-y-scroll ease duration-500 mt-1`}>
            <Lessons topicId={id} />
        </div>
    </div>
</div>

    )
}

export default Topic