import React from 'react'

const Outcome = ({outcome}) => {

    const { text } = outcome || {};

    return (
        <p className='flex space-x-2 mt-3'><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" className="mr-1 mt-[2px]" height="20" width="20" xmlns="http://www.w3.org/2000/svg" ><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
            <span>{text}</span>
        </p>
    )
}

export default Outcome