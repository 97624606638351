import React from 'react';

const CourseDetailsSkeleton = () => {
    return (
      <>
       <div className="pt-10 px-5 lg:px-20">
       <div className='grid grid-cols-6 gap-4 mt-7 animate-pulse  max-w-[1265px]'>
            <div className=" rounded-md p-4 col-span-5">
                <div className=" ">
                    <div className=' w-64 h-10 bg-slate-200 mb-5'></div>
                    <div className=' w-52 mt-5 h-10 bg-slate-200 mb-5'></div>
                    <div className='mt-10 w-full h-20 bg-slate-200'></div>
                </div>
            </div>
            <div className="rounded-md">
                <div className=" ">
                    <div className=' w-96 h-52 bg-slate-200 mb-5'></div>
                    <div className=' w-48 h-10 bg-slate-200 mb-5'></div>
                    <div className='w-20 h-10 bg-slate-200'></div>
                    
                </div>
            </div>
            
        </div>
       </div>
      </>
    );
};

export default CourseDetailsSkeleton;