import React from 'react';

const LogoSkeleton = () => {
    return (
        <div className='m-auto animate-pulse'>
            <p className='h-[34px] w-[94px] bg-slate-100 rounded-md'></p>
        </div>
    );
};

export default LogoSkeleton;