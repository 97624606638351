import React from 'react';
import { SlGraduation } from 'react-icons/sl';
import { TfiWrite } from 'react-icons/tfi';
import { TiTags } from 'react-icons/ti';
import SEO from '../../../components/ui/SEO';
import { useGetDashboardCountingsQuery } from '../../../features/account/accountAPI';
import { Link } from 'react-router-dom';
import DashBoardSuggestCourse from './DashBoardSuggestCourse';

const Dashboard = () => {

    const { isLoading, data: respData } = useGetDashboardCountingsQuery();
    const { data: counting } = respData || {};

    return (
        <>
            <SEO
                title="Dashboard"
                description="Dashboard"
                url="/user/account/dashboard"
            />
            <div className="lg:flex lg:flex-col flex-1">
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'>
                    <div className='bg-white shadow h-20 flex items-center justify-between px-3 py-2'>
                        <TfiWrite className='text-[42px] text-indigo-900' />
                        <div className='flex flex-col items-end'>
                            <span className='text-indigo-900 font-medium'>Test Attend</span>
                            <span className='bg-indigo-100 text-indigo-900 font-semibold rounded-full flex items-center justify-center w-7 h-7'>0</span>
                        </div>
                    </div>
                    <Link to="/user/account/courses">
                        <div className='bg-white shadow h-20 flex items-center justify-between px-3 py-2'>
                            <SlGraduation className='text-[50px] text-sky-900' />
                            <div className='flex flex-col items-end'>
                                <span className='text-sky-900 font-medium'>My Course</span>
                                <span className='bg-sky-100 text-sky-900 font-semibold rounded-full flex items-center justify-center w-7 h-7'>{isLoading ? '...' : counting?.myCourseCount}</span>
                            </div>
                        </div>
                    </Link>
                    <Link to="/user/account/enrollments">
                        <div className='bg-white shadow h-20 flex items-center justify-between px-3 py-2'>
                            <TiTags className='text-[48px] text-orange-700' />
                            <div className='flex flex-col items-end'>
                                <span className='text-orange-700 font-medium'>Course Enrollment</span>
                                <span className='bg-orange-100 text-orange-900 font-semibold rounded-full flex items-center justify-center w-7 h-7'>{isLoading ? '...' : counting?.courseEnrollmentCount}</span>
                            </div>
                        </div>
                    </Link>
                </div>



                <div className="mt-12">
                    <h1 className='text-xl font-semibold mb-10 '>Suggested Course</h1>
                    <div class="grid grid-cols-4 gap-10">
                        <DashBoardSuggestCourse/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;