import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

const SearchForm = () => {

    const [searchQuery, setSearchQuery] = useSearchParams();
    const [value, setValue] = useState(searchQuery.get('search'));


    const navigate = useNavigate();
    

    const handleSearch = (event) => {
        event.preventDefault();

        if (value) {
            const options = {
                pathname: '/course-page',
                search: `?${createSearchParams({search: value})}`,
            };

            navigate(options, { state:value, replace: true });
          
        } else if (!value){
            // navigate('/')
        }
    };


    return (
        <>
            <form onSubmit={handleSearch}>
                <div
                    className='primary-search text-black bg-white flex'
                >

                    <input
                        type="search"
                        name='search'
                        placeholder='search'
                        value={value || ''}
                        onChange={(e) => setValue(e.target.value)}
                        className='w-full outline-none px-3 bg-gray-200'
                    />
                    <button
                        type='submit'
                        className='bg-[#292d60] text-white hover:bg-[#1f235a] p-2'
                    >
                        <BiSearch className='w-7 h-7' />
                    </button>

                </div>
            </form>

        </>
    );
};

export default SearchForm;