import { apiSlice } from "../api/apiSlice";

export const supportAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSupport: builder.query({
            query: () => '/api/v1/support',
        }),
    })
});

export const { 
    useGetSupportQuery
} = supportAPI;