import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'; 
import { BsCheckCircleFill, BsFiletypePdf } from 'react-icons/bs';
import { FiPlayCircle } from 'react-icons/fi';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { setLessonId, setVideoFinished } from '../../playing/VideoStatusSlice';

const Lesson = ({ lesson }) => {
    console.log("Lessons: ", lesson);

    const { slug, lessonId } = useParams();
    const { id, topicId, courseId, title, courseLessonTracking, duration, attachment, isVideo, isAttachment } = lesson || {};
    const [isActive, setIsActive] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [lessonTracking, setLessonTracking] = useState([]);
    const [cookies] = useCookies(['EduTvAuth']);
    const videoStatus = useSelector((state)=>state.videoStatus.isVideoFinished);
    const videoLessonId = useSelector((state)=>state.videoStatus.lessonId);
    
    const auth = cookies?.EduTvAuth;
    const { user } = auth || {};
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const dispatch = useDispatch();

    // useEffect to set isActive
    useEffect(() => {
        if (lessonId == id) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [id, lessonId]);

    // Function to handle link click
    const handleLinkClick = () => {
        if (isAttachment) {
            sendLessonTracking();
            dispatch(setVideoFinished(true))
            dispatch(setLessonId(id))
        }
    };

    // Function to send lesson tracking
    const sendLessonTracking = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/lesson-tracking`, {
                lesson_id: id,
                student_id: user?.id,
                course_id: courseId,
                is_completed: 1,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Lesson tracking POST request successful:', response.data);
        } catch (error) {
            console.error('Error sending lesson tracking POST request:', error);
        }
    };

    return (
        <li>
            {/* Add onClick handler to the Link */}
            <Link
                to={`/user/account/courses/${slug}/${topicId}/playlists/${id}`}
                onClick={handleLinkClick} // Call handleLinkClick on link click
                className={`${isActive ? 'bg-blue-100 text-blue-900' : 'text-gray-600'} flex items-start gap-2 py-3 px-5 text-sm hover:bg-indigo-100 hover:text-blue-900 cursor-pointer`}
            >
                <div className='w-5'>
                    {isVideo ? (
                        <FiPlayCircle className={`text-xl ${isActive ? 'text-rose-600 animate-pulse' : ''}`} />
                    ) : (
                        isAttachment ? (
                            <BsFiletypePdf className={`text-xl ${isActive ? 'text-rose-600 animate-pulse' : ''}`} />
                        ) : (
                            <></>
                        )
                    )}
                </div>
                <div className='flex flex-col gap-1.5'>
                    <h5 className='font-medium'>{title}</h5>
                    <small>{duration} Minutes</small>
                </div>
                <div className='w-5'>
                    {(courseLessonTracking || videoStatus && videoLessonId==id) && (
                        <BsCheckCircleFill className={`text-xl text-green-600`} />
                    )}
                </div>
            </Link>
        </li>
    )
}

export default Lesson;
