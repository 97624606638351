import React from 'react';
import Courses from '../../components/Course/Courses';
import SEO from '../../components/ui/SEO';
import { useParams } from 'react-router-dom';
import { useGetCourseCategoryDetailsQuery } from '../../features/courseCategories/courseCategoriesAPI';
import { coursesAPI } from '../../features/courses/coursesAPI';
import { useDispatch } from 'react-redux';





const Course = () => {

    const {categorySlug} = useParams();
    const dispatch = useDispatch();

    dispatch(coursesAPI.util.invalidateTags(["CoursesByCategory"]));
    dispatch(coursesAPI.util.invalidateTags(["MoreCoursesByCategory"]));

    const { data } = useGetCourseCategoryDetailsQuery(categorySlug);
    const { data: category } = data || {};



    return (
        <>
            <SEO 
                title={`${category?.name} — Courses`}
                description={`${category?.name} all courses list.`}
                url={`/${categorySlug}/courses`}
            />
            <Courses categorySlug={categorySlug} />
        </>
    );
};

export default Course;