import React from 'react'
import { useGetCourseOutcomesQuery } from '../../../../features/courses/coursesAPI';
import { useParams } from 'react-router-dom';
import sliceIntoChunks from '../../../../utils/sliceIntoChunks';
import Outcome from './Outcome';

const Outcomes = () => {

    const { slug } = useParams();

    const { data, isLoading, isError } = useGetCourseOutcomesQuery(slug);
    const { data: courseOutcomesData } = data || {};
    const { data: courseOutcomes } = courseOutcomesData || {};

    let outcomeContent = null;

    if (isLoading) {
        outcomeContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
    } else if (!isLoading && isError) {
        outcomeContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseOutcomes?.length === 0) {
        outcomeContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No outcomes found!</div>;
    } else if (!isLoading && !isError && courseOutcomes?.length > 0) {

        const chunkSize = Math.ceil(courseOutcomes?.length / 2);
        const chunkArr = sliceIntoChunks(courseOutcomes, chunkSize);

        outcomeContent = chunkArr.map(chunk => {
            return chunk.map(outcome => <Outcome key={outcome.id} outcome={outcome} />)
        });
    }


    return (
        <div className='mt-10'>
            <h1 className='text-2xl text-semibold'>What you'll learn</h1>
            <div className='grid grid-cols-2 mt-3 border border-gray-300 p-4'>
                {
                    outcomeContent
                }
            </div>
        </div>
    )
}

export default Outcomes