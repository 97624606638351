import React, { useState } from 'react';
import CourseSkeleton from '../../components/ui/Skeleton/CourseSkeleton';
import InstructorsCoursesItem from './InstructorsCoursesItem';
import Pagination from '../../components/ui/Pagination';
import CustomPagination from './CustomPagination';
import customavater from '../../assets/images/avatar.png';

const InstructorsCourses = ({ instructorsCourses, isLoading, isError }) => {
    // const [page, setPage] = useState(1)
    // console.log(instructorsCourses);

    // const perPageItems = 1
    // const totalItem = instructorsCourses?.length;
    // const currentPage = page;
    // const totalPage = totalItem / perPageItems;

    // const handlePaginate = (page) => {
    //     setPage(page)
    // }



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Set the number of items per page
    const totalItems = instructorsCourses?.length || 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    
    // Calculate the start and end index of items for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let InstructorCourseItems = null;


    if (isLoading) {

        InstructorCourseItems = <div className='text-center text-blue-700 text-lg p-4 font-medium'>
            <CourseSkeleton />
        </div>;
    } else if (!isLoading && isError) {
        InstructorCourseItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && instructorsCourses?.length === 0) {
        InstructorCourseItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && instructorsCourses?.length > 0) {
        const itemsForCurrentPage = instructorsCourses.slice(startIndex, endIndex);

    InstructorCourseItems = itemsForCurrentPage.map((instructorsCourse) => (
      <InstructorsCoursesItem
        key={instructorsCourse.id}
        instructorsCourses={instructorsCourse}
      />
    ));




    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

    return (
        <>
            <div className='grid md:gr lg:grid-cols-3 gap-4'>
                {
                    InstructorCourseItems
                }


            </div>
            {
                itemsPerPage < totalItems &&
                // perPageItems < totalItem &&
                // <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate} />
                <CustomPagination totalPages={totalPages} onPageChange={handlePageChange} />
            }

        </>
    );
};

export default InstructorsCourses;