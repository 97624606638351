import React from 'react'
import EnrollmentItem from './EnrollmentItem'

const Enrollments = ({enrollments}) => {

    let tableRows = null;

    if (enrollments === undefined) {
        tableRows = <tr><td colSpan={8} className='text-center text-blue-200 text-lg text-amaranth p-4 font-medium'>Loading...</td></tr>;
    } else if (enrollments?.length === 0) {
        tableRows = <tr><td colSpan={8} className='text-center text-red-200 text-lg text-amaranth p-4 font-medium'>No Enrollments Found!</td></tr>;
    } else if(enrollments?.length > 0) {
        tableRows = enrollments.map((enrollment, index) => <EnrollmentItem key={enrollment.id} snNo={index + 1} enrollment={enrollment} />);
    }





    return (
        <table className="min-w-full">
            <thead className="border-b bg-slate-200">
                <tr>
                    <th width="4%" scope="col" className="text-sm font-semibold text-gray-900 py-4 text-center">
                        SN
                    </th>
                    <th width="15%" scope="col" className="text-sm font-semibold text-gray-900 py-4 px-1 text-left">
                        Invoice No
                    </th>
                    <th width="10%" scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-left">
                        Date
                    </th>
                    <th width="30%" scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-left">
                        Student
                    </th>
                    <th width="15%" scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-left">
                        Payment Method
                    </th>
                    <th width="10%" scope="col" className="text-sm font-semibold text-gray-900 px-1 py-4 text-right">
                        Grand Total
                    </th>
                    <th width="10%" scope="col" className="text-sm font-semibold text-gray-900 py-4 px-1 text-center">
                        Payment Status
                    </th>
                    <th width="6%" scope="col" className="text-sm font-semibold text-gray-900 py-4 px-1 text-center">
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    tableRows
                }
            </tbody>
        </table>
    )
}

export default Enrollments