import React, { useEffect } from 'react';
import './App.css';
import Master from './components/layout/Master';
import { useLocation } from 'react-router-dom';
import AuthRoutes from './routers/AuthRoutes';
import PublicRoutes from './routers/PublicRoutes';
import { useGetSiteInfoQuery } from './features/siteInfo/siteInfoAPI';
import { useDispatch } from 'react-redux';
import { setSiteInfo } from './features/siteInfo/siteInfoSlice';
import useAuthCheck from './hooks/useAuthCheck';
import AccountMaster from './components/layout/AccountMaster';
import { useGetSupportQuery } from './features/support/supportAPI';
import { setSupport } from './features/support/supportSlice';

import { useGetAccountCourseQuery } from './features/courses/coursesAPI';
import ReactGA from 'react-ga';




function getFaviconEl() {
	return document.getElementById("favicon");
}





function App() {

	const { data } = useGetSiteInfoQuery();
	const { data: support } = useGetSupportQuery();
	const { data: accountCourse } = useGetAccountCourseQuery();
	const authCheck = useAuthCheck();
	const dispatch = useDispatch();
	const { pathname } = useLocation();


	// useEffect(() => {
	// 	// define a custom handler function
	// 	// for the contextmenu event
	// 	const handleContextMenu = (e) => {
	// 	  // prevent the right-click menu from appearing
	// 	  e.preventDefault()
	// 	}

	// 	// attach the event listener to 
	// 	// the document object
	// 	document.addEventListener("contextmenu", handleContextMenu)

	// 	// clean up the event listener when 
	// 	// the component unmounts
	// 	return () => {
	// 	  document.removeEventListener("contextmenu", handleContextMenu)
	// 	}
	//   }, [])

	ReactGA.initialize('G-8RR25766VN');

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, [pathname]);


	useEffect(() => {
		if (data?.status === 1) {

			const {
				site_name: siteName,
				site_title: siteTitle,
				fav_icon: favIcon,
				logo,
				transparent_logo: transparentLogo,
				address,
				phone,
				email,
				short_description: shortDescription,
				description,
				map_url: mapUrl,

			} = data?.data || {};


			const fav = process.env.REACT_APP_BASE_URL + '/' + favIcon;
			const favicon = getFaviconEl();
			favicon.href = fav;


			dispatch(setSiteInfo({
				siteName,
				siteTitle,
				favIcon,
				logo,
				transparentLogo,
				address,
				phone,
				email,
				shortDescription,
				description,
				mapUrl
			}));
		}
	}, [data]);


	useEffect(() => {
		if (support?.status === 1) {

			const {
				description,

			} = support?.data || {};


			dispatch(setSupport({

				description,

			}));
		}
	}, [support]);


	// useEffect(() => {
	// 	if (accountCourse?.status === 1) {
	// 		const courseData = accountCourse?.data?.data || [];
	// 		console.log(courseData);

	// 		dispatch(setAccountCourse(

	// 			courseData,

	// 		));
	// 	}
	// }, [accountCourse]);





	let content = null;

	if (
		pathname.includes("/exam-page")
		|| pathname.includes("/user-courses")
		|| pathname.includes("/user-playlist")
		|| pathname.includes("/checkout")
		|| pathname.includes("/cart")
		|| pathname.includes("/enrollment-success")
	) {
		content = <Master><AuthRoutes /></Master>;
		
	} else if (
		pathname.includes("/user/account/")
	) {
		content = <AccountMaster><AuthRoutes /></AccountMaster>
		
	} else {
		content = <Master><PublicRoutes /></Master>
	}

	return authCheck && content;
}

export default App;
