import React from 'react'
import { Link } from 'react-router-dom';
import DefaultAvatar from '../../../../assets/images/instructor.jpg';

const Instructor = ({courseInstructor}) => {


    const { instructor } = courseInstructor || {};
    const { user_primary_education: instructorPrimaryEducation, first_name: firstName = '', last_name: lastName = '', image, username } = instructor || {};
    


    const instructorAvatar = process.env.REACT_APP_BASE_URL + '/' + image;

    return (
        <div className=''>
           <Link to={`/instructors/${username}`} className='flex space-x-3 border w-full p-4 border-gray-800'>
           <img src={image !== null ? instructorAvatar : DefaultAvatar} className="h-[50px] w-[50px] rounded-3xl" alt="avatar" />
            <div>
                <p>{firstName} {lastName}</p>
                <p>{instructorPrimaryEducation !== null ? instructorPrimaryEducation?.title : ''} in {instructorPrimaryEducation !== null ? instructorPrimaryEducation?.institute : ''}</p>
            </div>
           </Link>
        </div>
    )
}

export default Instructor