import React from 'react';

const LetestCourseSkeleton = () => {
    return (
        <div className='grid grid-cols-4 gap-4 animate-pulse max-w-[1260px]'>
            <div className="p-4 max-w-sm w-full mx-auto">
                <div className="">
                    <div className='rounded-md w-56 h-72 bg-slate-200 mb-5 '></div>
                    <div className=' w-56 h-5 bg-slate-200 mb-5'></div>
                    <div className='w-20 h-3 bg-slate-200'></div>

                </div>
            </div>
            <div className="  p-4 max-w-sm w-full mx-auto">
                <div className=" ">
                    <div className='rounded-md w-56 h-72 bg-slate-200 mb-5 '></div>
                    <div className=' w-56 h-5 bg-slate-200 mb-5'></div>
                    <div className='w-20 h-3 bg-slate-200'></div>

                </div>
            </div>
            <div className="  p-4 max-w-sm w-full mx-auto">
                <div className=" ">
                    <div className='rounded-md w-56 h-72 bg-slate-200 mb-5 '></div>
                    <div className=' w-56 h-5 bg-slate-200 mb-5'></div>
                    <div className='w-20 h-3 bg-slate-200'></div>

                </div>
            </div>
            <div className=" p-4 max-w-sm w-full mx-auto">
                <div className=" ">
                    <div className='rounded-md w-56 h-72 bg-slate-200 mb-5 '></div>
                    <div className=' w-56 h-5 bg-slate-200 mb-5'></div>
                    <div className='w-20 h-3 bg-slate-200'></div>

                </div>
            </div>
        </div>
    );
};

export default LetestCourseSkeleton;