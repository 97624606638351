import React, { useState } from 'react';
import { FaRegHandPointRight, FaShareAlt } from 'react-icons/fa';
import instructor from '../../assets/images/instructor.jpg';
import course1 from '../../assets/images/course1.png';
import { useParams } from 'react-router-dom';
import { useGetSingleInstructorsQuery } from '../../features/Instructor/InstructorAPI';
import InstructorsCourses from './InstructorsCourses';
import SEO from '../../components/ui/SEO';

const Instructors = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    }



    const { userName } = useParams();

    const { data, isLoading, isError } = useGetSingleInstructorsQuery(userName);
    const { data: Instructors } = data || {};


    let instructorContent = null;

    if (isLoading) {
        instructorContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
    } else if (!isLoading && isError) {
        instructorContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && Instructors?.id === null) {
        instructorContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No instructors found!</div>;
    } else if (!isLoading && !isError && Instructors?.id > 0) {

        let instructorImage = process.env.REACT_APP_BASE_URL + '/' + Instructors.image;
        let instructorsCourses = Instructors?.course_instructors;

        instructorContent = <>
          <SEO
                title={`Instructor - ${Instructors.first_name}`}
                url={`/instructors/${Instructors.userName}`}
            />
         <div className=' pt-24'>
            <div className=' lg:px-40'>
                <div className='grid lg:grid-cols-4 gap-4'>

                    <div className='ml-auto mr-auto'>
                        <img src={instructorImage === null || undefined ? instructor : instructorImage}
                            className="w-[250px] h-[250px]" alt={Instructors.first_name} />

                        <h3 className='mb-2 mt-3 text-gray-500'>Instructor</h3>
                        <h1 className='text-2xl font-bold'>{Instructors?.first_name} {Instructors?.last_name}</h1>
                        <p>{Instructors.user_primary_education?.title} <br />{Instructors.user_primary_education?.institute}</p>

                        {/* <h3 className='mt-10 text-xl font-semibold'>Achivement</h3>
                <p>
                    <FaRegHandPointRight className='inline mx-1' /> Founder at Learn with Salman</p>
                <p>
                    <FaRegHandPointRight className='inline mx-1' /> IT Instructor of ITM</p> */}

                    </div>
                    <div className='px-4 lg:col-span-3'>
                        <h3 className='text-2xl mb-3 font-bold'>About Instructor</h3>
                        <p className=' text-gray-500'>{Instructors.bio}</p>

                        <hr className='mt-10 border-gray-300 border' />

                        <h1 className='text-2xl mb-3 mt-4'>Cousrses</h1>

                        <InstructorsCourses isLoading={isLoading} isError={isError} instructorsCourses={instructorsCourses} />

                        {/* <h1 className='text-2xl font-semibold mt-16 mb-5'>Salman's Free Live Classes</h1>
                <button onClick={()=> toggleTab(1)} className={toggleState === 1?'ml-3 bg-black text-white pb-2 pl-4
                    pr-4 pt-2 rounded-lg ':'ml-3 bg-blue-500 pl-4 pr-4 pt-2 rounded-lg text-white hover:bg-black
                    hover:text-white pb-2'}>live</button>
                <button onClick={()=> toggleTab(2)} className={toggleState===2?'ml-3 bg-black text-white pl-4 pr-4 pt-2
                    rounded-lg pb-2':'ml-3 bg-blue-500 pl-4 pr-4 pt-2 rounded-lg text-white hover:bg-black
                    hover:text-white pb-2'}>upcomming</button>
                <button onClick={()=> toggleTab(3)} className={toggleState===3?'ml-3 bg-black text-white pl-4 pr-4 pt-2
                    rounded-lg pb-2':'ml-3 bg-blue-500 pl-4 pr-4 pt-2 rounded-lg text-white hover:bg-black
                    hover:text-white pb-2'}>Recorded Live</button>

                <div className='mt-4 w-full border border-gray-300 h-[300px] p-4 overflow-scroll'>
                    <div className={toggleState===1 ?'visible' :'invisible hidden'}>
                        <h1>no Live Content</h1>
                    </div>
                    <div className={toggleState===2 ? 'visible' : ' hidden' }>
                        <h1>No Upcomming Content</h1>
                    </div>
                    <div className={toggleState===3 ? 'visible' : ' hidden' }>
                        <div className='grid lg:grid-cols-3 gap-4'>
                            <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl'
                                style={{ backgroundImage: `url(${course1})` }}>

                                <a href="/" className="text-white group">
                                    <div className='pt-5'>
                                        <p
                                            className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>
                                            premiem</p>
                                    </div>
                                    <div className=''>
                                        <div
                                            className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                            <div
                                                className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                                <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]'
                                                    href="/">Watch Now</a>
                                                <a className='py-2' href="/">
                                                    <FaShareAlt /></a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl'
                                style={{ backgroundImage: `url(${course1})` }}>

                                <a href="/" className="text-white group">
                                    <div className='pt-5'>
                                        <p
                                            className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>
                                            premiem</p>
                                    </div>
                                    <div className=''>
                                        <div
                                            className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                            <div
                                                className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                                <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]'
                                                    href="/">Watch Now</a>
                                                <a className='py-2' href="/">
                                                    <FaShareAlt /></a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl'
                                style={{ backgroundImage: `url(${course1})` }}>

                                <a href="/" className="text-white group">
                                    <div className='pt-5'>
                                        <p
                                            className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>
                                            premiem</p>
                                    </div>
                                    <div className=''>
                                        <div
                                            className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                            <div
                                                className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                                <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]'
                                                    href="/">Watch Now</a>
                                                <a className='py-2' href="/">
                                                    <FaShareAlt /></a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}
                    </div>

                </div>
            </div>
        </div>
        </>
    }




    return (
        instructorContent
    );
};

export default Instructors;