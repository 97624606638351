import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProgressBar from '../../components/Checkout/ProgressBar';
import { useGetVatPercentageQuery } from '../../features/cart/cartAPI';
import { useStoreEnrollmentMutation } from '../../features/checkout/checkoutAPI';
import SEO from '../../components/ui/SEO';
import AlertMessage from '../../components/ui/AlertMessage';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useGetUserInfoQuery } from '../../features/userProfile/userProfileAPI';
import CourseDetailsSkeleton from '../../components/ui/Skeleton/Course/CourseDetailsSkeleton';
import Swal from 'sweetalert2';





const Checkout = () => {
  
    const { data: getUser,isLoading:userLoading, refetch } = useGetUserInfoQuery();
    // console.log(getUser?.data)
    const {username, phone, address_1} = getUser?.data || {};

  
    // const { invoiceNo } = useSelector(state => state.checkout);
    const { cartItems, cartSubtotal, cartDiscountAmount, cartCouponDiscountAmount, cartCouponDiscountPercentage,cartCouponAmount,cartCouponId, cartVatAmount, cartGrandTotal } = useSelector(state => state.cart);
    const [access, setAccess] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useGetVatPercentageQuery();



    const [storeEnrollment, { data, isLoading, isError, error }] = useStoreEnrollmentMutation();

    const grandTotalAmount = parseInt(cartGrandTotal);
    // console.log(data)


    useEffect(() => {
        // if (invoiceNo !== '') {
        //     navigate('/enrollment-success');
        // } else 
        if (!data?.status && cartItems?.length === 0) {
            navigate('/');
        } else if (cartItems?.length > 0) {
            setAccess(true);
        }
    }, [data, cartItems]);


    const handleAlert = () => {
        Swal.fire({

            html: '<p>Please update profile to purchase continue.</p>',
            showCloseButton: true,
          })
      };
    

    const handleCheckout = () => {
       if((phone === null || undefined)){
        handleAlert()
        navigate('/user/account/profile')
       }else{
            setAlertMessage('');
            setAlertMessageType('');
    
            if (grandTotalAmount > 0) {
                storeEnrollment({
                    coupon_id: cartCouponId,
                    source: 'Website',
                    subtotal: cartSubtotal,
                    total_vat_amount: cartVatAmount,
                    item_total_discount_amount: cartDiscountAmount,
                    coupon_discount_amount: cartCouponDiscountAmount<=0?cartCouponAmount:cartCouponDiscountAmount,
                    course_id: cartItems?.map(item => item.id),
                    regular_fee: cartItems?.map(item => item.regularFee),
                    discount_amount: cartItems?.map(item => item.discountAmount),
                    course_fee: cartItems?.map(item => item.courseFee),
                    quantity: cartItems?.map(item => item.quantity),
                }
                
                );
            } else {
                storeEnrollment({
                    coupon_id: cartCouponId,
                    source: 'Website',
                    subtotal: cartSubtotal,
                    total_vat_amount: cartVatAmount,
                    item_total_discount_amount: cartDiscountAmount,
                    coupon_discount_amount: cartCouponDiscountAmount,
                    coupon_discount_percentage: cartCouponAmount,
                    course_id: cartItems?.map(item => item.id),
                    regular_fee: cartItems?.map(item => item.regularFee),
                    discount_amount: cartItems?.map(item => item.discountAmount),
                    course_fee: cartItems?.map(item => item.courseFee),
                    quantity: cartItems?.map(item => item.quantity),
                    payment_status: 'Paid'
                }
               
                );
            }
       }
       
    }





    useEffect(() => {
        if (data?.status) {
            if (grandTotalAmount > 0) {
                window.location.replace(`${process.env.REACT_APP_BASE_URL}/payment?invoice_no=${data?.data}`);
            } else {
                navigate(`/enrollment-success`);
            }
        }

        if (isError) {
            setAlertMessageType('error');
            setAlertMessage(error?.data?.errors?.payment_method_id[0]);
        }

        setTimeout(() => {
            setAlertMessageType('');
            setAlertMessage('');
        }, 5000);
    }, [data, isError, error]);





    return access && (
        <>
            <SEO
                title='Checkout'
                description='Checkout'
                url='/checkout'
            />
            {
                userLoading ? <><p><CourseDetailsSkeleton/></p></> : 
                <div className="py-10 flex justify-center items-center rounded-md">
                <div className="card w-[90%] rounded-md shadow-md bg-white p-5">
                    {
                        alertMessage !== '' && <AlertMessage type={alertMessageType} message={alertMessage} />
                    }
                    <ProgressBar />
                    <div className='mt-4 divide-x'>
                        {/* <PaymentMethods setPaymentMethodId={setPaymentMethodId} /> */}
                        <div className='pl-5'>
                            <h2 className='text-gray-500 text-xl font-medium mb-2'>Summary</h2>
                            <hr />
                            <div className='mb-5 mt-3'>
                                <div className='flex justify-between mb-1'>
                                    <p>Subtotal</p>
                                    <p>৳<span>{cartSubtotal}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>VAT</p>
                                    <p>৳<span>{cartVatAmount}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>Discount</p>
                                    <p>৳<span>{cartDiscountAmount}</span></p>
                                </div>
                                <div className='flex justify-between mb-1'>
                                    <p>Coupon</p>
                                    <p>৳<span>{cartCouponDiscountAmount<=0?cartCouponAmount:cartCouponDiscountAmount}</span></p>
                                </div>
                                <hr />
                                <div className='flex justify-between mt-2'>
                                    <p className='font-semibold'>Grand Total</p>
                                    <p className='font-semibold'>৳<span>{cartGrandTotal}</span></p>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                                <Link to='/cart' className='w-full py-2 text-center hover:bg-indigo-50 text-indigo-900 font-semibold border border-slate-500 rounded-sm'>
                                    Previous
                                </Link>
                                {
                                    isLoading ?
                                        <>
                                            <button
                                                type="button"
                                                className="flex items-center gap-2 py-2 px-5 text-rose-600"
                                                disabled
                                            >
                                                <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <span> Submitting ... </span>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button disabled={isLoading} onClick={handleCheckout} className='w-full py-2 bg-indigo-900 hover:bg-indigo-800 text-indigo-50 font-medium rounded-sm'>
                                                Submit
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            
        </>
    );
}

export default Checkout;