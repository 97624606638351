import { apiSlice } from "../api/apiSlice";

export const courseCategoriesAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getHighlightedCourseCategories: builder.query({
            query: () => '/api/v1/course-categories/highlighted',
        }),
        getShowInMenuParentCourseCategories: builder.query({
            query: () => `/api/v1/course-categories/parent/show-in-menu`,
        }),
        getShowInMenuCourseCategoriesByParent: builder.query({
            query: (parent_id) => `/api/v1/course-categories/show-in-menu-by-parent/${parent_id}`,
        }),
        getCourseCategoryDetails: builder.query({
            query: (categorySlug) => `/api/v1/course-categories/${categorySlug}`,
        }),
    })
});

export const { 
    useGetHighlightedCourseCategoriesQuery, 
    useGetCourseCategoryDetailsQuery,
    useGetShowInMenuParentCourseCategoriesQuery,
    useGetShowInMenuCourseCategoriesByParentQuery,
} = courseCategoriesAPI;