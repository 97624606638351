import React from 'react'
import { useLocation } from 'react-router-dom';

const ProgressBar = () => {

    const { pathname } = useLocation();
    const progressCount = [1, 2];
    
    return (
        <div className='flex justify-center'>
            {
                progressCount.map(count => 
                    <>
                        <div className='flex flex-col gap-2 w-[400px] items-center'>
                            <div 
                                className={
                                    `w-[35px] px-4 text-white rounded-full h-[35px] flex justify-center items-center
                                    ${ 
                                        (pathname.includes("/cart") && count === 1) || (pathname.includes("/checkout") && count === 2)
                                        ? 'bg-indigo-900' 
                                        : (
                                            pathname.includes("/checkout")
                                            ? 'bg-indigo-900'
                                            : 'bg-slate-400'
                                        )
                                    }
                                    `
                                }>
                                {count}
                            </div>
                            <p>
                                {
                                    count === 1
                                    ? 'Cart'
                                    : (count === 2
                                    ? 'Checkout'
                                    : '')
                                }
                            </p>
                        </div>
                        {
                            count !== progressCount.length && <div 
                                className={
                                    `w-full -mx-[43px] md:-mx-[53px] lg:-mx-[128px] h-[2px] mt-[17.5px]
                                    ${ 
                                        pathname.includes("/checkout")
                                        ? 'bg-indigo-900'
                                        : 'bg-slate-400'
                                    }
                                    `
                                }
                            >
                            </div>
                        }
                    </>
                )
            }
        </div>
    )
}

export default ProgressBar