import React from 'react'
import InvoiceItem from './InvoiceItem';





const InvoiceItems = ({enrollmentItems}) => {

    let tr = null;

    if (enrollmentItems?.length > 0) {
        tr = enrollmentItems.map((enrollmentItem, index) => <InvoiceItem key={enrollmentItem.id} snNo={index + 1} enrollmentItem={enrollmentItem} />)
    }

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 px-4">
                <div className="py-2 inline-block m-auto w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full">
                            <thead className="border-b">
                                <tr>
                                    <th width="4%" scope="col" className="text-sm font-medium text-gray-900 py-4 text-center">
                                        SN
                                    </th>
                                    <th width="40%" scope="col" className="text-sm font-medium text-gray-900 py-4 px-1 text-left">
                                        Course Description
                                    </th>
                                    <th width="13%" scope="col" className="text-sm font-medium text-gray-900 px-1 py-4 text-right">
                                        Regular Fee
                                    </th>
                                    <th width="10%" scope="col" className="text-sm font-medium text-gray-900 px-1 py-4 text-right">
                                        Discount
                                    </th>
                                    <th width="13%" scope="col" className="text-sm font-medium text-gray-900 px-1 py-4 text-right">
                                        Course Fee
                                    </th>
                                    <th width="10%" scope="col" className="text-sm font-medium text-gray-900 py-4 px-1 text-center">
                                        Quantity
                                    </th>
                                    <th width="10%" scope="col" className="text-sm font-medium text-gray-900 py-4 px-1 text-right">
                                        Total Fee
                                    </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tr
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceItems