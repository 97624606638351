import React, { useState } from 'react';
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './Testimonial.css';

import { FaArrowRight } from 'react-icons/fa';
import { useGetTestimonialsQuery } from '../../features/testimonials/testimonialsAPI';
import TestimonialItem from './TestimonialItem';
import CourseSkeleton from '../ui/Skeleton/CourseSkeleton';
import Pagination from '../ui/Pagination';





const Testimonials = () => {

    const [page, setPage] = useState(1);



    const { data, isLoading, isError } = useGetTestimonialsQuery(page);
    const { data: responseData } = data || {};
    const { data: testimonials, meta: pages } = responseData || {};

    // console.log(pages);
    const perPageItems = pages?.per_page;
    const totalItem = pages?.total;
    const currentPage = pages?.current_page;
    const lastPage = pages?.last_page;
    // const totalPage = totalItem / perPageItems;
    const totalPage = lastPage;
    // console.log(currentPage);

    const handlePageChange = () => {
        setPage((prev) => prev + 1);
    }

    function previousClick() {
        setPage(page - 1)
    }

    const handlePaginate = (page) => {
        setPage(page)
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }
    // console.log(pageNumbers);


    let testimonialItems = null;

    if (isLoading) {
        testimonialItems = <>
        
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div>
        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton/></div> 
         </>;
    } else if (!isLoading && isError) {
        testimonialItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && testimonials?.length === 0) {
        testimonialItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && testimonials?.length > 0) {
        testimonialItems = testimonials.map(testimonial => <TestimonialItem key={testimonial.id} testimonial={testimonial} />);
    }





    return (
        <div className='mt-20 h-full max-w-[1265px] mx-auto'>
            <div className='mb-3'>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    What Our have to say
                    
                </h1>
            </div>
            <div className='px-8 lg:px-20'>
                <div className="grid grid-cols-3 gap-10">
                    {
                        testimonialItems
                    }
                </div>
            </div>

           {
            testimonials?.length > 0 &&
            (
                perPageItems < totalItem &&
                <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate}/>
            )
           }
        </div>
    )
}

export default Testimonials