import React, { useState, useEffect } from 'react';
import newsletter from '../../assets/images/newsletter1.jpg';
import { useDispatch } from 'react-redux';
import { useStoreSubscriberMutation } from '../../features/subscribers/subscribersAPI';

const Subscriber = () => {


    const [email, setEmail]             = useState('');
    const [messageType, setMessageType] = useState('');
    const [message, setMessage]         = useState('');


    const dispatch = useDispatch();


    const [storeSubscriber, { data, isLoading, error }] = useStoreSubscriberMutation();





    useEffect(() => {

        if(error?.data?.errors?.email) {
            setMessageType('error')
            setMessage(error?.data?.errors?.email[0])
        } else if (data?.status === 0) {
            setMessageType('error')
            setMessage(data?.message)
        } else if (data?.status === 1) {
            setMessageType('success')
            setMessage(data?.message)
            setEmail('')
        }


        setTimeout(() => {
            setMessage('')
        }, 5000);

    }, [data, error, dispatch]);





    const handleSubmit = (e) => {
        e.preventDefault();
        
        setMessageType('');
        setMessage('')

        storeSubscriber({email})
    }



    return (
        <div className='max-w-[1265px] mx-auto mt-10'>
            <div className='bg-[#02315B] p-10 rounded-xl' style={{ backgroundImage: `url(${newsletter})`, height: '', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
                <div className='grid lg:grid-cols-5 pt-8 pb-8'>
                    <div className='lg:col-span-2'>
                        <h1 className='text-white text-4xl font-bold'>Want Us to Email you About Special Offers & Updates?</h1>
                    </div>
                    <div className='lg:col-span-3'>
                        <form onSubmit={handleSubmit} className='w-full pt-2'>
                            <input 
                                type="text" 
                                className='p-4 w-[80%] focus:outline-none' 
                                placeholder='example@email.com' 
                                name="email"
                                value={email || ''}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <button className='bg-[#292d60] hover:bg-blue-800 p-4 lg:w-[20%] text-white'>Subscribe</button>
                        </form>
                        {
                            message !== '' && <p className={`font-medium ${messageType === 'success' ? 'text-teal-500' : 'text-red-500'}`}>{message}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscriber;