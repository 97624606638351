import { apiSlice } from "../api/apiSlice";

export const userProfileAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateProfile: builder.mutation({
            query: (data) => ({
                url: "/api/v1/account/upload-image",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
        }) ,
        getProfileImage: builder.query({
            query: () => `/api/v1/account/profile-image`,
        }), 

        updateProfileInfo: builder.mutation({
            query: (data) => ({
                url: "/api/v1/account/profile-update",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
        }) ,
        getProfileInfo: builder.query({
            query: () => `/api/v1/account/profile-info`,
        }),
        getUserInfo: builder.query({
            query: () => `/api/v1/account/user-info`,
        }),
    })
});

export const { 
    useUpdateProfileMutation, 
    useGetProfileImageQuery,
    useUpdateProfileInfoMutation,
    useGetProfileInfoQuery,
    useGetUserInfoQuery


} = userProfileAPI;