import React from 'react';
import './Navbar.css';
import { FaHeart, FaSearch, FaShoppingCart } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import LogoSkeleton from '../../ui/Skeleton/LogoSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import ParentCategories from './Category/ParentCategories';
import { handleSidebar } from '../../../features/sideNav/sideNavSlice';
import { handleSearch } from '../../../features/mobileSearch/mobileSearchSlice';
import { handlePrimarySearch } from '../../../features/search/showPrimarySearchSlice';
import SearchForm from '../../Search/SearchForm';
import { AiFillThunderbolt } from 'react-icons/ai';





const Navbar = ({ nav }) => {

    const { pathname } = useLocation();
    const isHomePage = pathname === "/";


    const { cartTotalItems } = useSelector(state => state.cart);
    const { user } = useSelector(state => state.auth);
    const { logo, transparentLogo } = useSelector(state => state.siteInfo);
    const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;
    const transparentMenuLogo = process.env.REACT_APP_BASE_URL + '/' + transparentLogo;
    const showSearchPrimary = useSelector((state) => state.showPrimarySearch.showPrimarySearch);

    const cartdata  = JSON.parse(localStorage.getItem('edutvCartItems'));
    const totalCartData = cartdata?.length;
  

    const dispatch = useDispatch();





    return (
        <>
            <nav className={`${!isHomePage ? "bg-white shadow-lg sticky -top-1" : (nav ? 'bg-white shadow-lg sticky -top-1' : ' md:bg-transparent md:shadow-none md:bg-gradient-to-b md:from-[#192b3d] md:to-transparent md:fixed')} bg-white shadow-lg w-full z-40`}>
                <div className=" mx-auto max-w-[1265px]">
                    <div className="flex justify-between">
                        <div className="flex space-x-5">
                            <div>
                                <Link to="/" className="flex items-center py-3 px-2 md:hidden">
                                    {
                                        logo !== null ?
                                            <img src={siteLogo} alt="Logo" className="h-10 w-15 mr-2" />
                                            :
                                            <LogoSkeleton />
                                    }
                                </Link>
                                <Link to="/" className=" items-center py-3 px-2 hidden md:block">
                                    {
                                        logo !== null ?

                                            (isHomePage ? (!nav ? <img src={transparentMenuLogo} alt="Logo" className="h-10 w-15 mr-2" /> : <img src={siteLogo} alt="Logo" className="h-10 w-15 mr-2" />) :

                                                <img src={siteLogo} alt="Logo" className="h-10 w-15 mr-2" />)
                                            :
                                            <LogoSkeleton />
                                    }
                                </Link>
                            </div>
                            <ul
                                className={`
                                    ${!isHomePage
                                        ? "text-gray-700"
                                        : (
                                            nav
                                                ? 'text-gray-700'
                                                : 'text-white'
                                        )
                                    } 
                                    hidden md:flex items-center space-x-1`
                                }>
                                <ParentCategories />
                            </ul>
                        </div>
                        <div
                            className={`
                                ${!isHomePage
                                    ? "text-[#292d60]"
                                    : (nav ? '' : 'text-white')
                                } 
                                hidden md:flex items-center space-x-3
                            `}
                        >
                            <button
                                className="py-2 px-2 font-medium rounded hover:text-[#292d60] transition duration-300 relative"
                                onClick={() => dispatch(handlePrimarySearch())}
                            >
                                <FaSearch />
                            </button>
                            <div className={`
                                    ${showSearchPrimary
                                        ? "scale-y-100"
                                        : "scale-y-0"
                                    } 
                                    absolute translate-y-14 -translate-x-5 duration-300 w-[18%]
                                `}>
                                <SearchForm/>
                            </div>
                     
                            <Link to="/cart"
                                className="py-2 px-2 font-medium rounded  hover:text-[#292d60] transition duration-300 relative">
                                <FaShoppingCart />
                                
                                  {
                                    totalCartData > 0 &&
                                    <span className={`absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center`}>{totalCartData}</span>
                                  }
                                
                            </Link>
                            <Link to="/exams" className='bg-gradient-to-tr from-indigo-900 to-cyan-600 py-2 px-3 font-semibold flex gap-1 items-center rounded-lg cursor-pointer'>
                                <AiFillThunderbolt className='text-yellow-300 animate-pulse' />
                                <span className='text-white'>Exams</span>
                            </Link>
                            {/* <ExamCategories /> */}
                            {
                                user !== undefined
                                    ?
                                    <>
                                        {/* <p className='font-medium'>Hey, {user?.username}</p> */}
                                        <Link to="/user/account/dashboard" className="py-2 px-3 font-medium text-white bg-gradient-to-tr from-indigo-900 to-cyan-600 rounded-lg">Dashboard</Link>
                                    </>
                                    :
                                    <>
                                        <Link to="/login" className="py-2 px-3 font-medium text-white bg-gradient-to-tr from-indigo-900 to-cyan-600 rounded-lg">Login/SignUp</Link>
                                        {/* <Link className="py-2 px-2 font-medium text-white bg-[#292d60] rounded-md hover:bg-[#1e2158] transition duration-300" to="/register">Sign Up</Link> */}
                                    </>
                            }
                        </div>
                        <div className="md:hidden flex items-center">
                            {/* <Link
                                to="/user/account/wishlist"
                                className="py-2 px-2 font-medium rounded hover:text-[#292d60] transition duration-300 relative">
                                <FaHeart />
                            </Link> */}
                            <Link
                                to="/cart"
                                className="py-2 px-2 font-medium rounded hover:text-[#292d60] transition duration-300 relative"
                            >
                                <FaShoppingCart />
                                <span
                                    className="absolute right-0 top-0 rounded-full bg-red-600 w-4 h-4 top right p-0 m-0 text-white font-mono text-sm  leading-tight text-center">{totalCartData}
                                </span>
                            </Link>
                            <button
                                onClick={() => dispatch(handleSearch())}
                                className="py-2 px-2 font-medium rounded hover:text-[#292d60] transition duration-300 relative"
                            >
                                <FaSearch />
                            </button>
                            <button
                                className=""
                                onClick={() => dispatch(handleSidebar())}
                            >
                                <RxHamburgerMenu className='w-8 h-8 duration-300' />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;