import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import backgroundTiming from '../../assets/images/backgroundtiming.jpg';
import SEO from '../../components/ui/SEO';
import { useCreateExamQuizTestMutation, useGetChapterDetailsQuery } from '../../features/exams/examsAPI';
import ChaptarQuiz from './ChaptarQuiz';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import ChapterQuizValidation from './ChapterQuizValidation';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";


const ChapterDetails = () => {

    const { slug, chapterSlug } = useParams();
    const [page, setPage] = useState(1);

    const getExamTime = localStorage.getItem("examDuration");
    const examTime = JSON.parse(getExamTime);
  
    const [timeRemaining, setTimeRemaining] = useState(examTime);
    const [isQuizFinished, setIsQuizFinished] = useState(false);

    const navigate = useNavigate();

    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    // console.log(auth);

 
    // quizes data
    const { data, isLoading, isError } = useGetChapterDetailsQuery({ chapterSlug, page });
    const { data: chapters } = data || {};
    const { data: quizez, meta: pages } = chapters || {};

    // console.log(quizez)

    const totalPage = pages?.total;
    const currentPage = pages?.current_page;
    const lastPage = pages?.last_page;

    const handlePageChange = () => {
        setPage((prev) => prev + 1);
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }

    const percentage = (currentPage / totalPage) * 100; 
    
    function handleBeforeUnload(event) {
        event.preventDefault();
        event.returnValue = "Are you sure you want to leave?";
      }

    // handle time
    useEffect(() => {
        const timer = setInterval(() => {
            if (!isQuizFinished) {
                // setTimeRemaining(prevTime => prevTime - 1);
                setTimeRemaining((prevTime) => {
                    const [hours, minutes, seconds] = prevTime.split(":").map(Number);
                    if (hours === 0 && minutes === 0 && seconds === 0) {
                        clearInterval(timer);
                        return prevTime;
                    }

                    let newSeconds = seconds - 1;
                    let newMinutes = minutes;
                    let newHours = hours;
                    if (newSeconds < 0) {
                      newSeconds = 59;
                      newMinutes -= 1;
                    }
                    if (newMinutes < 0) {
                      newMinutes = 59;
                      newHours -= 1;
                    }
                    return `${newHours.toString().padStart(2, "0")}:${newMinutes
                        .toString()
                        .padStart(2, "0")}:${newSeconds.toString().padStart(2, "0")}`;
                }
               
                );
            }
        }, 1000);
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
        clearInterval(timer);
        window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    }, [isQuizFinished]);

  

    useEffect(() => {
        if (timeRemaining === 0 || timeRemaining === ('00:00:00')) {
            setIsQuizFinished(true);
            navigate('/quiz-result');
            
        }
    }, [timeRemaining, navigate]);

    // if(isQuizFinished === true){
    //     console.log("wowo sesss");
    // }
//   console.log(timeRemaining);
    
    // create quiz data
    const [createExamQuizTest, { data: createQuizData, error }] = useCreateExamQuizTestMutation();
    const { user } = useSelector(state => state.auth);


    let quiz = null;

    if (isLoading) {
        quiz = <p>Loading...</p>
    } else if (!isLoading && isError) {
        quiz = <p>There was an error!</p>
    } else if (!isLoading && !isError && data?.status === 1) {
        quiz = quizez.map((quiz, index) => (
            <>
                <SEO
                    title={`${slug} - ${quiz?.title}`}
                    url={`/exams/${slug}/chapters/${chapterSlug}`}
                />
                <div>
                    {/* breadcam Timing */}
                    <div className='p-10' style={{ backgroundImage: `url(${backgroundTiming})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
                        <h1 className='text-center text-3xl font-semibold text-white'><span className='font-bold text-orange-400'><ion-icon name="alarm-outline"></ion-icon> Time :</span> {timeRemaining}</h1>
                    </div>
                    {/* quiz */}
         
                    <div className='grid lg:grid-cols-4 gap-4 mt-8 p-10'>

                        <div className=' lg:col-span-3'>
                            <div className=' rounded-lg bg-white shadow p-3'>
                            <Progress percent={`${percentage}`} status="success" />
                            
                                {
                                   
                                        <ChaptarQuiz
                                            quiz={quiz}
                                            index={index}
                                            page={page}
                                            createQuizData={createQuizData}
                                            error={error}
                                            createExamQuizTest={createExamQuizTest}
                                            user={user}
                                            handlePageChange={handlePageChange}
                                            currentPage={currentPage}
                                            setPage={setPage}
                                            lastPage={lastPage}
                                            isLoading={isLoading}
                                         
                                        />
                                }


                            </div>
                         
                        </div>

                        <div>
                            <div className='bg-white shadow rounded-md lg:p-10'>
                                <div className='grid grid-cols-5 gap-3' >

                                    {pageNumbers.map((number) => (
                                        <p key={number} className={`${currentPage === number ? 'bg-blue-700' : 'bg-gray-400 '} p-3 text-white rounded-lg`}>
                                            <button onClick={() => setPage(number)}>Q:{number}</button>
                                        </p>
                                    ))}
                                </div>

                                <div className=' mt-10'>
                                    <ul>
                                        <li className='text-gray-400 font-bold'>Not Answered</li>
                                        <li className='text-blue-700 font-bold'>Answered</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ))
    }


    return quiz;
};

export default ChapterDetails;