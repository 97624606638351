import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import PopularCourseItem from './PopularCourseItem';
import { FaArrowRight, FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { useGetPopularCoursesQuery } from '../../../../features/courses/coursesAPI';
import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';
import { useCookies } from 'react-cookie';


const PopularCourses = () => {

    const [courseData, setCourseData] = useState([]);
    const [enrollitem, setEnrollItem] = useState([]);
    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;
  

    useEffect(() => {

        fetch(`${baseUrl}/api/v1/account/courses`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then(data => {
                setCourseData(data?.data?.data)
                localStorage.setItem("accountCourseData", JSON.stringify(data?.data?.data));
            })
            .catch((error) => console.log(error));
    }, [token]);

    useEffect(() => {

        fetch(`${baseUrl}/api/v1/enrollments`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then(data => {
                setEnrollItem(data?.data?.data)

            })
            .catch((error) => console.log(error));
    }, [token]);



    const [sliderSettings] = useState({
        touchThreshold: 100,
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <FaAngleRight />,
        prevArrow: <FaAngleLeft />,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots__hottype ">
                <div className="loading" />
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 2,
                    arrows: true,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },

        ]
    });






    const { data, isLoading, isError } = useGetPopularCoursesQuery();
    const { data: responseData } = data || {};
    const { data: popularCourses } = responseData || {};



    let popularCoursesItems = null;

    if (isLoading) {

        popularCoursesItems =
            <>
                <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                        <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                </div>
            </>

        sliderSettings.infinite = false
    } else if (!isLoading && isError) {
        popularCoursesItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && popularCourses?.length === 0) {
        popularCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && popularCourses?.length > 0) {
        popularCoursesItems = popularCourses.map(popularCourse => <PopularCourseItem key={popularCourse.id} popularCourse={popularCourse} popularCourses={popularCourses} courseData={courseData} enrollitem={enrollitem} />);

        if (
            (popularCourses.length <= 4 && sliderSettings.responsive[0].breakpoint === 1024) ||
            (popularCourses.length <= 3 && sliderSettings.responsive[1].breakpoint === 1023) ||
            (popularCourses.length <= 2 && sliderSettings.responsive[2].breakpoint === 899)
        ) {
            sliderSettings.infinite = false
        }


    }



    return (
        <div className='pb-7 max-w-[1265px]  mx-auto'>
            <div className=''>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    Popular Courses
                    <a className='float-right mr-5 font-normal normal-case' href="/popular-courses">
                        view all
                        <FaArrowRight className='inline h-3 mb-1' />
                    </a>
                </h1>

            </div>
            <div>
                <Slider {...sliderSettings}>
                    {
                        popularCoursesItems
                    }
                </Slider>
            </div>
        </div>
    );
}

export default PopularCourses