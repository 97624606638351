import axios from 'axios';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import { usePlayLessonQuery } from '../../../../features/playlists/playlistsAPI';
import SEO from '../../../ui/SEO';
import TextSkeleton from '../../../ui/Skeleton/TextSkeleton';
import VideoSkeleton from '../../../ui/Skeleton/VideoSkeleton';
import VideoSkeletonNotFound from '../../../ui/Skeleton/VideoSkeletonNotFound';
import LessonComment from './LessonComment';
import PDFViewer from './PDFViewer';
import QuizModal from './QuizModal';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';
import { setLessonId, setVideoFinished } from './VideoStatusSlice';

const Lesson = () => {
    const { slug, lessonId, is_completed } = useParams();
    const [currentTab, setCurrentTab] = useState('description');
    const [comment, setComment] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [quizResult, setQuizResult] = useState();
    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const { user } = auth || {};
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [countdown, setCountdown] = useState(duration);
    const dispatch = useDispatch();
    
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const pad = (value) => (value < 10 ? `0${value}` : value);

        return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
    }

    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const { data, isLoading, isError, refetch } = usePlayLessonQuery(lessonId);
    const { data: lesson } = data || {};
    const {
        id,
        title,
        video,
        attachment,
        course_id,
        description,
        is_video: isVideo,
        is_attachment: isAttachment,
        assignment_description: assignmentDescription,
        is_assignment: isAssignment,
        published_at: publishedAt,
        lesson_review,
        lesson_quiz,
        lesson_tracking,
        totalReview,
        
        url
    } = lesson || {};


    function calculateSecondsRemaining() {
        const now = new Date().getTime();
        const targetTime = moment().add(countdown, 'seconds');
        return Math.max(0, targetTime.diff(now, 'seconds'));
    }

    useEffect(() => {
        setCountdown(duration);
    }, [duration]);

    const startCountDown = () => {
        if (duration > 0) {
            setCountdown(calculateSecondsRemaining());
            const intervalId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown > 0) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(intervalId);
                        return 0;
                    }
                });
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }

    useEffect(() => {
        startCountDown();
    }, []);

    let lessonContent = null;

    useEffect(() => {
        setCurrentTab('description');
    }, [lessonId]);

    useEffect(() => {
        handleRefetch();
    }, []);

    const handleChange = (e) => {
        setComment(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        postData();
        postResult();
        setComment('');
        handleRefetch();
    };


// Rest of your component code...

    const postData = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/review`, {
                lesson_id: id,
                user_id: user?.id,
                comment: comment
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const postResult = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/quiz-result`, {
                lesson_id: id,
                student_id: user?.id,
                total_quiz: lesson_quiz?.length
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data.data.id);
            setQuizResult(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleRefetch = () => {
        refetch();
    };

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload'
            }
        },
    };
    const thresholdPercentage = 60;

    const timeThreshold = (duration * thresholdPercentage) / 100;
    const time = duration - timeThreshold;
console.log(time);

useEffect(() => {
    if (countdown <= time ) {
        sendLessonTracking(); 
        dispatch(setVideoFinished(true))
        dispatch(setLessonId(id))
    }
}, [countdown,]);

    
    const sendLessonTracking = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/lesson-tracking`, {
                lesson_id: id, 
                student_id: user?.id,
                course_id: course_id, 
                is_completed: 1,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log('Lesson tracking POST request successful:', response.data);
        } catch (error) {
            console.error('Error sending lesson tracking POST request:', error);
        }
    };

  
    
    if (isLoading) {
        lessonContent = <>
            <VideoSkeleton />
            <div className='flex flex-col gap-3'>
                <TextSkeleton height='30px' width='100%' />
                <TextSkeleton height='15px' />
            </div>
            <TextSkeleton height='20px' width='100%' />
            <TextSkeleton height='20px' width='100%' />
        </>
    } else if (!isLoading && isError) {
        lessonContent = <>
            <VideoSkeletonNotFound />
        </>
    } else if (!isLoading && !isError && data?.status === 1) {
        lessonContent = <>
            <SEO
                title={title}
                description={`Playing ${title}`}
                url={`/user/account/courses/${slug}/playlists/${lessonId}`}
            />
            <div className='flex flex-col gap-5'>
                {
                    isVideo
                        ? 
                        <div>
                        <ReactPlayer
                            url={video}
                            playing={true}
                            controls={true}
                            config={playerConfig}
                            width=""
                            height="400px"
                            onDuration={(duration) => setDuration(duration)}
                            onStart={()=>startCountDown()}
                        />
                        {duration > 0 && (
                            <div>
                                <p>Time Remaining: {formatTime(countdown)}</p>
                            </div>
                        )}
                        </div>
                        :
                        (
                            isAttachment && attachment != null
                                ? <PDFViewer attachment={url} />
                                : <></>
                        )
                }
                <div className='flex flex-col gap-3'>
                    <h2 className='text-2xl font-medium'>{title}</h2>
                </div>
                <ul className="flex items-center gap-2 text-sm font-medium text-center text-black border-b border-gray-200 mt-4">
                    <li onClick={() => setCurrentTab('description')} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'description' ? 'bg-blue-100' : ''}`}>
                        Description
                    </li>
                    {
                        isAssignment
                            ?
                            <li onClick={() => setCurrentTab('assignment')} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'assignment' ? 'bg-blue-100' : ''}`}>
                                Assignment
                            </li>
                            :
                            <></>
                    }

                    {
                        lesson_quiz ? 
                        <>
                        <button onClick={() => { setCurrentTab('quiz'); postResult(); setShowModal(true)}} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'quiz' ? 'bg-blue-100' : ''}`}>Quiz</button>
                        </>
                         :
                        <></>
                    }
                </ul>
                {
                    currentTab === 'description' && (
                        <div>
                        <form action="" onSubmit={handleSubmit}>
                            <label htmlFor="comment"></label>
                            <input type="text" className="py-2 w-full rounded-lg border outline-none px-2" value={comment} onChange={handleChange} />
                            <button type="submit" className='bg-orange-500 py-2 px-3 text-white mt-2 mb-2 rounded-md'>submit</button>
                        </form>
                    </div>
                    )
                }
                {
                    currentTab === 'assignment' && isAssignment
                        ?
                        <div className='prose'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assignmentDescription) }}
                        />
                        :
                        <></>
                }
                {
                    currentTab === 'quiz' && lesson_quiz 
                        ?
                        <QuizModal showModal={showModal} quizResultId={quizResult} handleShowModal={() => setShowModal(false)} lesson_quiz={lesson_quiz}/>
                        :
                        <></>
                }
            </div>

            {
                totalReview && 
                <div>
                    <h1>Comments {totalReview}</h1>
                    <hr/>
                </div>
            }
            {
                lesson_review?.map(comments => <LessonComment key={comments?.id} comments={comments} lessonId={id} />)
            }
        </>;
    }

    return lessonContent;
}

export default Lesson;
