import React, { useState } from 'react'
import { useGetCourseTopicsQuery } from '../../../../features/courses/coursesAPI';
import Topic from './Topic';
import { useParams } from 'react-router-dom';

const Topics = () => {

    const { slug } = useParams();

    const { data, isLoading, isError } = useGetCourseTopicsQuery(slug);
    const { data: courseTopicsData } = data || {};
    const { data: courseTopics } = courseTopicsData || {};

    let topicContent = null;

    if (isLoading) {
        topicContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
    } else if (!isLoading && isError) {
        topicContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseTopics?.length === 0) {
        topicContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No topics found!</div>;
    } else if (!isLoading && !isError && courseTopics?.length > 0) {
        topicContent = courseTopics.map(topic => <Topic key={topic.id} topic={topic} />)
    }


    return (
        <div className='mt-10'>
            <h1 className='text-2xl text-semibold'>Course Content</h1>

            <div className=' mt-4'>
                <div className="w-full space-y-2 border border-gray-300">
                    {
                        topicContent
                    }
                </div>
            </div>
        </div>
    )
}

export default Topics