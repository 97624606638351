import React from 'react'
import { useGetShowInMenuCourseCategoriesByParentQuery } from '../../../../features/courseCategories/courseCategoriesAPI';
import ChildCategory from './ChildCategory';
import style from './Category.module.css';

const ChildCategories = ({parentId}) => {

    const { data, isLoading, isError } = useGetShowInMenuCourseCategoriesByParentQuery(parentId);
    const { data: responseData } = data || {};
    const { data: showInMenuCourseCategoriesByParent } = responseData || {};

    let content = null;

    if (!isLoading && !isError && showInMenuCourseCategoriesByParent?.length > 0) {
        content = showInMenuCourseCategoriesByParent.map(category => <ChildCategory key={category?.id} category={category} />);
    }

    return (
        <ul 
            className={`${style.child}  text-[#292d60] z-10`}
        >
            {
                content
            }
        </ul>
    )
}

export default ChildCategories