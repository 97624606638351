import { apiSlice } from "../api/apiSlice";



export const authAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createInstructor: builder.mutation({
            query: (data) => ({
                url: "/api/v1/instructor/create",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
        }),
        getSingleInstructors: builder.query({
            query: (userName) => `/api/v1/instructor/${userName}`,
        }),
        getInstructors: builder.query({
            query: (page = 1) => `/api/v1/instructor?page=${page}`,
        }),
       
    })
});

export const { 
 
useCreateInstructorMutation,
useGetInstructorsQuery,
useGetSingleInstructorsQuery,

} = authAPI;