import React from 'react';
import { Link, useParams } from 'react-router-dom';
import InvoiceItems from '../../../components/account/enrollment/invoice/InvoiceItems';
import { useShowEnrollmentDetailsQuery } from '../../../features/enrollments/enrollmentsAPI';
import { useSelector } from 'react-redux';
import { BsFileEarmarkText } from 'react-icons/bs';
import SEO from '../../../components/ui/SEO';
import paid from '../../../assets/images/paid.jpg';
import unPaid from '../../../assets/images/unpaid.jpg';





const Invoice = () => {

    const { invoiceNo } = useParams();
    const {
        siteName,
        address,
        phone,
        email,
    } = useSelector(state => state?.siteInfo);

    const { data } = useShowEnrollmentDetailsQuery(invoiceNo);
    const { data: enrollment } = data || {};
    const {
        student,
        date,
        enrollment_items: enrollmentItems,
        subtotal,
        total_vat_amount: totalVatAmount,
        item_total_discount_amount: itemTotalDiscountAmount,
        coupon_discount_amount: couponDiscountAmount,
        coupon_discount_percentage: cartCouponAmount,
        grand_total: grandTotal,
        payment_status

    } = enrollment || {};





    return (
        <>
            <SEO
                title={`Invoice - ${student?.first_name} ${student?.last_name}`}
                url="/invoice"
            />


            <div className='flex justify-between w-[21cm] mb-1 mt-10 m-auto'>
                <Link to={'/user/account/enrollments'} className='bg-[#292d60] text-white py-2 px-3 hover:rounded-full hover:duration-300 ease-in-out '>
                    Back to Enrollment</Link>
                <Link to={'/user/account/dashboard'} className='bg-[#292d60] text-white py-2 px-3 hover:rounded-full hover:duration-300 ease-in-out'>Back to Dashboard</Link>
            </div>
            <div className='flex flex-col w-[21cm] h-[29.7cm] m-auto mb-[0.5cm] shadow-md mt-10 bg-white'>

                <div className='flex-grow'>
                    <div className='bg-[#292d60] flex items-center justify-between text-white px-10 py-5'>
                        <div className='flex flex-col gap-2'>
                            <BsFileEarmarkText className='w-20 h-20' />
                            <h1 className='text-2xl'>Invoice</h1>
                        </div>
                        <div>
                            <h2 className='text-xl font-semibold mb-2'>{siteName}</h2>
                            <p>{address}</p>
                            <a href={`tel${phone}`}>{phone}</a><br />
                            <a href={`mailto${email}`}>{email}</a>
                        </div>


                    </div>


                    <div className='flex justify-between text-black px-10 py-5'>
                        <div>
                            <small className='text-normal'>Bill To</small>
                            <h3 className='text-lg'>{student?.first_name} {student?.last_name}</h3>
                            <p className='text-sm'>{student?.address}</p>
                        </div>
                        <div>
                            <p className='font-semibold uppercase'>invoice <br />
                                <span className='text-sm font-normal'>#{invoiceNo}</span>
                            </p>
                            <p className='font-semibold uppercase mt-1'>Date <br />
                                <span className='font-normal text-sm'>{date}</span>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <InvoiceItems enrollmentItems={enrollmentItems} />
                    <div className="grid grid-cols-5 text-sm px-4">
                        <div className='col-span-4 text-right'>
                            Subtotal :
                        </div>
                        <div className='col-span-1 text-right mx-1'>
                            ৳{subtotal}
                        </div>
                    </div>
                    <div className="grid grid-cols-5 text-sm px-4">
                        <div className='col-span-4 text-right'>
                            VAT :
                        </div>
                        <div className='col-span-1 text-right mx-1'>
                            ৳{totalVatAmount}
                        </div>
                    </div>
                    <div className="grid grid-cols-5 text-sm px-4">
                        <div className='col-span-4 text-right'>
                            Item Total Discount Amount :
                        </div>
                        <div className='col-span-1 text-right mx-1'>
                            ৳{itemTotalDiscountAmount}
                        </div>
                    </div>
                    <div className="grid grid-cols-5 text-sm px-4">
                        <div className='col-span-4 text-right'>
                            Coupon Discount Amount :
                        </div>
                        <div className='col-span-1 text-right mx-1'>
                            ৳{couponDiscountAmount<=0 ?cartCouponAmount:couponDiscountAmount}
                        </div>
                    </div>
                    <div className="grid grid-cols-5 text-sm px-4">
                        <div className='col-span-4 text-right'>
                            Grand Total :
                        </div>
                        <div className='col-span-1 text-right mx-1'>
                            ৳{grandTotal}
                        </div>
                    </div>

                    <div>
                        {
                            payment_status === 'Paid' ?
                                <>
                                    <img src={paid} className='w-40 h-40 m-auto' alt="" />
                                </>
                                :
                                <>
                                    <img src={unPaid} className='w-42 h-40 m-auto' alt="" />
                                </>
                        }
                    </div>
                </div>


                <div className='text-center py-2'>
                    <p>Powered by: <Link to='/' className='font-semibold text-[#292d60]'>{siteName}</Link></p>
                </div>
            </div>
        </>
    );
};

export default Invoice;