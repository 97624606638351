import React from 'react'
import Courses from '../../../components/account/course/Courses'
import Search from '../../../components/account/course/Search'
import SEO from '../../../components/ui/SEO'
import { coursesAPI, useGetAccountCoursesQuery } from '../../../features/courses/coursesAPI'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import debounceHander from '../../../utils/debounceHandler'
import Pagination from '../../../components/ui/Pagination'


const CourseList = () => {

    const [search, setSearch] = useState('');
    const [paginatePage, setPaginatePage] = useState(1);
    const dispatch = useDispatch();


    const { courses, currentPage, perPage, totalCourses } = useGetAccountCoursesQuery({ search, page: paginatePage }, {
        selectFromResult: ({ data }) => ({
            courses: data?.courses,
            currentPage: data?.currentPage,
            perPage: data?.perPage,
            totalCourses: data?.totalCourses,
        })
    });











    const doSearch = (query) => {
        setSearch(query);
        dispatch(coursesAPI.endpoints.getAccountCourses.initiate({ search: query, page: 1 }));
    }

    const searchHandler = debounceHander(doSearch, 50);





    const handlePaginate = (page) => {
        setPaginatePage(page);
        dispatch(coursesAPI.endpoints.getAccountCourses.initiate({ search, page }));
    }





    return (
        <>
            <SEO
                title="Course List"
                description="Course List"
                url="/user/account/courses"
            />


            <div className='flex flex-col gap-4'>
                <h3 className='text-xl text-black font-medium'>Course List</h3>
                <Search search={search} searchHandler={searchHandler} />
                <Courses courses={courses} />
                {
                     perPage < totalCourses &&
                    <Pagination currentPage={currentPage} perPage={perPage} totalItems={totalCourses} handlePaginate={handlePaginate} />
                }

            </div>
        </>
    )
}

export default CourseList