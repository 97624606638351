import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModalFreeVideo: false,
  };



const FreeVideoShowSlice = createSlice({
    name: 'showModalFreeVideo',
    initialState,
    reducers: {
      handleFreeVideoShow: (state) => {
        state.showModalFreeVideo = !state.showModalFreeVideo;
      },
    },
  });

  export const { handleFreeVideoShow } = FreeVideoShowSlice.actions;

export default FreeVideoShowSlice.reducer;