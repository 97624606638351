import React from 'react';
import { useGetParentExamCategoriesQuery } from '../../../../features/exams/examsAPI';
import ExamFilterSkeleton from '../../../ui/Skeleton/ExamFilterSkeleton';
import ExamCategory from './ExamCategory';

const ExamCategories = () => {
    const { data, isLoading, isError } = useGetParentExamCategoriesQuery();
    const { data: responseData } = data || {};
    const { data: parentExamCategories } = responseData || {};

    let content = null;

    if (isLoading) {
        content = <>
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                    <ExamFilterSkeleton />
                </>
    } else if (!isLoading && !isError && parentExamCategories?.length === 0) {
        content = <li className="flex flex-col mb-4 text-red-500 font-medium">No Categories Found!</li>;
    } else if (!isLoading && !isError && parentExamCategories?.length > 0) {
        content = parentExamCategories.map(parentExamCategory => <ExamCategory key={parentExamCategory.id} parentExamCategory={parentExamCategory} />)
    }
   
    return (
        <ul className='mt-3 -mb-4 max-h-[300px] overflow-y-scroll'>
            {
                content 
            }
        </ul>
    );
};

export default ExamCategories;