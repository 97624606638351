import React from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa'
import DefaultThumbnailImage from '../../assets/images/course5.jpeg';
import { addToCart, calculateCartAmounts } from '../../features/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CgEye } from 'react-icons/cg';
import { useGetAccountCourseQuery } from '../../features/courses/coursesAPI';
import EnrollNow from '../Enrollment/EnrollNow';
import { useState } from 'react';

const CourseItem = ({course}) => {

    const navigate = useNavigate();
    const { cartItems } = useSelector(state => state.cart);
    
    const [ overlayToggle, setOverlayToggle ] = useState(true);


    const { data:courseData } = useGetAccountCourseQuery([]);
  

    const { 
        id,
        category,
        title,
        slug,
        regularFee,
        discountAmount,
        courseFee,
        thumbnailImage,
        averageRating,
        totalLecture,
        totalDuration
    } = course || {};

    const thumbnailBg = process.env.REACT_APP_BASE_URL + '/' + thumbnailImage;
    const dispatch = useDispatch();




    const existCourse = courseData?.data?.data?.find((exist) => exist?.id === id);
    const itemIndex = cartItems?.findIndex(item => item.id === id);





    const addToCartHandler = () => {
        dispatch(addToCart({
            id, 
            title, 
            slug, 
            categoryName: category?.name,
            regularFee,
            discountAmount,
            courseFee, 
            thumbnailImage, 
            averageRating, 
            totalLecture, 
            totalDuration,
            quantity: 1,
        }));

        dispatch(calculateCartAmounts());
    };





    // const handleEnrollNow = () => {
    //     addToCartHandler();
    //     navigate('/cart');
    // }





    return (
        <div
            className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30 rounded-2xl h-[245px] w-[100%] sm:w-[90%] lg:w-[90%]">
            <div className=" h-[245px]">
                <div className='ml-[-3px] absolute z-20'>
                    <p className='mr-3 group-hover:hidden block w-[90px] pl-2 pr-2 py-1 rounded-lg'>
                        <span className='before:block before:absolute before:rounded-tl-xl before:rounded-br-xl before:-inset-1 before:bg-[#292d60] relative inline-block'>
                            <span className="relative text-white pl-2 pr-2">{courseFee > 0 ? 'Premium' : 'Free'}</span>
                        </span>
                    </p>
                </div>
                <img className="relative h-fit w-fit rounded-2xl object-cover transition-transform duration-500"
                    src={thumbnailImage && thumbnailBg}
                    alt="" />
            </div>
            <div
                className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70 rounded-2xl h-[245px]">
            </div>
            <div className={`absolute inset-0 flex flex-col mt-5 px-4 transition-all duration-500 ${ overlayToggle ? 'translate-y-0' : 'translate-y-[50%]' } group-hover:translate-y-0 rounded-2xl h-[245px]`}>
                <div className="h-screen flex flex-col justify-between pb-10">
                    <div className=''>
                        <h1 className=" text-2xl font-bold text-white rounded-2xl opacity-0 group-hover:opacity-100"><Link to={`/courses/${id ? slug : (slug ? slug : '')}`}>{title}</Link></h1>
                        <p className='text-white opacity-0 group-hover:opacity-100 mt-2'>{courseFee > 0 ? 
                       (
                        discountAmount > 0 ? <><span className='line-through text-[#caca]'>৳ {regularFee} </span> ৳ {courseFee} </> : courseFee
                       )
                         : 
                         'Free'}</p>
                    </div>
                    <div className=' '>
                        <div className=' flex justify-between opacity-0 group-hover:opacity-100 mt-auto'>
                            {
                                existCourse 
                                ? 
                                    <Link to={`/user/account/courses/${slug}`} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-white transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100">
                                        <span className=" text-xs">
                                            Go To Course
                                            <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                                        </span>
                                    </Link>
                                : (
                                    itemIndex !== -1 
                                    ?
                                        <Link to={`/cart`} className="mt-3 relative border-2 rounded-full border-[#292d60] bg-transparent py-1 px-2 font-medium uppercase text-white transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100">
                                            <span className=" text-xs">
                                                View In Cart
                                                <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                                            </span>
                                        </Link>
                                    :   <EnrollNow course={course} />
                                )
                            }

                            <div className='text-white mt-4 space-x-3 float-right'>
                                <Link to={`/courses/${id ? slug : (slug ? slug : '')}`} className='inline-block'>
                                    <CgEye className='w-6 h-6 ' />
                                </Link>
                                {
                                    existCourse ?
                                        <>

                                        </>
                                        :
                                        itemIndex === -1
                                        &&
                                        <button onClick={addToCartHandler} className=' '>
                                            <AiOutlineShoppingCart title='Add To Cart' className='hover:text-indigo-800 w-6 h-6' />
                                        </button>
                                }
                                {/* <button className=''>
                                <AiOutlineHeart title='Add To Wishlist' className='hover:text-indigo-800 w-6 h-6' />
                            </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseItem