import React from 'react'
import { useGetPaymentMethodsQuery } from '../../../features/checkout/checkoutAPI';
import PaymentMethod from './PaymentMethod';

const PaymentMethods = ({setPaymentMethodId}) => {


    const { data, isLoading, isError } = useGetPaymentMethodsQuery();
    const { data: paymentMethods } = data || {};

    let paymentMethodContents = null;

    if (isLoading) {
        paymentMethodContents = <p>Loading...</p>
    } else if (!isLoading && !isError && paymentMethods?.length > 0) {
        paymentMethodContents = paymentMethods.map(paymentMethod => <PaymentMethod key={paymentMethod.id} paymentMethod={paymentMethod} setPaymentMethodId={setPaymentMethodId} />)
    }

    return (
        <div className='pr-5'>
            <h2 className='text-xl font-medium mb-2'>Payment Information</h2>
            <hr />
            {
                paymentMethodContents
            }
        </div>
    )
}

export default PaymentMethods