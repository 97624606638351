import React, { useEffect, useState } from 'react';
import breadcamCover from '../../assets/images/takeexambread.jpg';
import { examsAPI, useGetExamsQuery } from '../../features/exams/examsAPI';
import Exam from '../../components/Exam/Exam';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExamSkeleton from '../../components/ui/Skeleton/ExamSkeleton';
import { useSearchParams } from 'react-router-dom';
import Filter from '../../components/Exam/Filter/Filter';
import SEO from '../../components/ui/SEO';



const Exams = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const examFilterData = useSelector(state => state.exam);
    const { categorySlugs, examYears, feeTypes } = examFilterData || {};
    const { data } = useGetExamsQuery({ categorySlugs, examYears, feeTypes });
    const { exams, perPage, totalExams } = data || {};


// console.log(exams);


    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const dispatch = useDispatch();





    const fetchMore = () => {
        setPage((prevPage) => prevPage + 1);
    };





    useEffect(() => {
        if (page > 1) {
            dispatch(
                examsAPI.endpoints.getMoreExams.initiate({
                    categorySlugs,
                    examYears,
                    feeTypes,
                    page,
                })
            );
        }
    }, [categorySlugs, examYears, feeTypes, page, dispatch]);





    useEffect(() => {
        if (totalExams > 0) {
            const more = Math.ceil(totalExams / perPage) > page;
            setHasMore(more);
        }
    }, [totalExams, page]);





    useEffect(() => {
        setPage(1);
        dispatch(examsAPI.endpoints.getExams.initiate({ categorySlugs, examYears, feeTypes }));
    }, [categorySlugs, examYears, feeTypes, dispatch]);





    let examContents = <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-5'>
        <ExamSkeleton />
        <ExamSkeleton />
        <ExamSkeleton />
    </div>;

    if (exams !== undefined && exams?.length > 0) {
        examContents = <InfiniteScroll
            dataLength={exams.length}
            next={fetchMore}
            hasMore={hasMore}
            style={{ overflow: 'hidden', paddingBlock: '15px' }}
            loader={<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-5'>
                <ExamSkeleton />
                <ExamSkeleton />
                <ExamSkeleton />
            </div>}
        >
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-5'>
                {
                    exams?.map((exam) => (
                        <Exam key={exam.id} exam={exam} />
                    ))
                }
            </div>
        </InfiniteScroll>;
    } else if (exams !== undefined && exams?.length == 0) {
        examContents = <h2 className='text-center text-xl text-red-500 font-medium'>No Exams Found</h2>
    }

    return (
        <>
            <SEO
                title="Exam Page"
                url="/exams"
            />
            <div className='mt-10'>
                <div className='p-10' style={{ backgroundImage: `url(${breadcamCover})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
                    <h1 className='text-center text-3xl font-semibold text-white'>Take Your Exam</h1>
                </div>
                <div className='mx-20 mt-10'>
                    <div className='grid lg:grid-cols-4 gap-5'>
                        <Filter />
                        <div className='lg:col-span-3'>
                            {
                                examContents
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Exams;