import React, { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import SEO from '../../components/ui/SEO';
import { useGetFaqsQuery } from '../../features/faqs/faqsAPi';
import data from './faqsData';
import FaqsItem from './FaqsItem';

const Faqs = () => {

    const { data, isLoading, isError } = useGetFaqsQuery();
    const { data: faqsdata } = data || {};
    const { data: faqs } = faqsdata || {};

    let faqsItems = null;

    if (isLoading) {

        faqsItems = <div className='text-center text-blue-700 text-lg p-4 font-medium'>loading.....</div>;
    } else if (!isLoading && isError) {
        faqsItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && faqs?.length === 0) {
        faqsItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && faqs?.length > 0) {
        faqsItems = faqs.map(faq => (
            <FaqsItem key={faq.id} faq={faq} faqs={faqs}
            />
        ))
    }

    return (
        <>
           <SEO
                title={`FAQ - frequently asked questions`}
                url={`/faqs`}
            />
        <div>
            <div className="relative z-20 overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
                <div className="container mx-auto">
                    <div className="-mx-4 flex flex-wrap">
                        <div className="w-full px-4">
                            <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20">

                                <h2
                                    className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]"
                                >
                                    Any Questions? Look Here
                                </h2>

                            </div>
                        </div>
                    </div>
                    <div className="container m-auto px-10">
                        <div className="sm:p-2 ">
                            {
                                faqsItems
                            }

                        </div>

                    </div>
                </div>

            </div>


        </div>
        </>
    );
};

export default Faqs;