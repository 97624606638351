import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useGetHighlightedCourseCategoriesQuery } from '../../features/courseCategories/courseCategoriesAPI';
import CourseSkeleton from '../../components/ui/Skeleton/CourseSkeleton';
import CourseCategoryItem from '../../components/Home/CourseCategory/CourseCategoryItem';
import { useState } from 'react';




const CategoryPage = () => {


    const { data, isLoading, isError } = useGetHighlightedCourseCategoriesQuery();
    const { data: responseData } = data || {};
    const { data: highlightedCourseCategories } = responseData || {};


    
    let courseCategoryItems = null;

    if (isLoading) {
        courseCategoryItems =
            <>
                <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                </div>
            </>

    } else if (!isLoading && isError) {
        courseCategoryItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && highlightedCourseCategories?.length === 0) {
        courseCategoryItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && highlightedCourseCategories?.length > 0) {
        courseCategoryItems = highlightedCourseCategories.map(category => <CourseCategoryItem key={category.id} category={category} />);
    }





    return (
        <div className='max-w-[1265px] mx-auto'>
            <div className='pb-7 text-[#292d60]'>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    Highlighted Category

                </h1>
                <div className='mx-20 grid grid-cols-5 gap-5'>
                    {
                        courseCategoryItems
                    }
                </div>

                <div>
     
             
    </div>
            </div>
        </div>
    );
};

export default CategoryPage;