import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showNav: false,
  };



const sideNavSlice = createSlice({
    name: 'sideNav',
    initialState,
    reducers: {
      handleSidebar: (state) => {
        state.showNav = !state.showNav;
      },
    },
  });

  export const { handleSidebar } = sideNavSlice.actions;

export default sideNavSlice.reducer;