import React from 'react';
import { useEffect } from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import SEO from '../../../components/ui/SEO';

const SubscriberVerifyFailed = () => {

    const {subscriberVerifying} = useSelector(state => state.subscriber);
    const navigate = useNavigate();

    useEffect(() => {
        if (!subscriberVerifying) {
            navigate('/login');
        }    
    }, [subscriberVerifying, navigate])

    return (
        <>
            <SEO 
                title="Verify Failed"
                description="Subscription verify has been failed!"
            />
            <div style={{ backgroundColor: '#6DB3F2' }}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <div className='flex flex-col items-start gap-3'>
                            <div className='flex items-center justify-center gap-2 text-2xl md:text-3xl'>
                                <IoMdCloseCircle className='text-red-500' />
                                <span className="text-red-500 font-semibold"> Verify Failed! </span>
                            </div>
                            <Link to='/' className='flex items-center gap-1'>Go to Home <BsArrowRight /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriberVerifyFailed