import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeExamYear, setExamYears } from '../../../../features/exams/examsSlice';

const ExamYear = ({examYear}) => {

    const [isChecked, setIsChecked] = useState(false);
    const { year } = examYear || {};
    const dispatch = useDispatch();





    useEffect(() => {
        if (isChecked) {
            dispatch(setExamYears(year));
        } else {
            dispatch(removeExamYear(year));
        }
    }, [isChecked, year, dispatch]);





    return (
        <li className="flex items-center mb-4">
            <input 
                type="checkbox" 
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded cursor-pointer" 
                onChange={() => setIsChecked(!isChecked)}
                value={year || ''}
                id={year} 
                checked={isChecked}
            />
            <label 
                htmlFor={year} 
                className="ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >
                {year}
            </label>
        </li>
    );
};

export default ExamYear;