import { apiSlice } from "../api/apiSlice";

export const enrollmentsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnrollments: builder.query({
            query: ({ search, page }) => {

                let query = '';

                if (search !== '' || search !== null || search !== undefined) {
                    query +=`search=${search}`;
                }

                if (page !== '' || page !== null || page !== undefined) {
                    query +=`&page=${page}`;
                }

                return `/api/v1/enrollments?${query}`;
            },
            providesTags: ["Enrollments"],
            transformResponse(apiResponse) {
                const { data, status } = apiResponse || {};
                const { data: enrollments } = data || {};

                if (status) {
                    return {
                        enrollments: enrollments,
                        currentPage: data?.current_page,
                        perPage: data?.per_page,
                        totalEnrollments: data?.total,
                    };
                }
            },
        }),
        getEnrollData: builder.query({
            query: () => '/api/v1/enrollments',
        }),
        showEnrollmentDetails: builder.query({
            query: (invoiceNo) => `/api/v1/enrollments/${invoiceNo}`,
        }),
    })
});

export const { 
    useGetEnrollmentsQuery,
    useGetEnrollDataQuery,
    useShowEnrollmentDetailsQuery,
} = enrollmentsAPI;