import React from 'react';
// import '../../../components/SilkSlider/additionalcss.css';
import '../../../components/SilkSlider/slick.css';
import '../../../components/SilkSlider/slick-theme.css';
import { FaAngleLeft, FaAngleRight, FaArrowRight } from 'react-icons/fa';
import Slider from 'react-slick';
import { useGetHighlightedCourseCategoriesQuery } from '../../../features/courseCategories/courseCategoriesAPI';
import CourseCategoryItem from './CourseCategoryItem';
import CourseSkeleton from '../../ui/Skeleton/CourseSkeleton';

const CourseCategories = () => {

    const settings = {
        dots: true,
        infinite: true,
        easing: 'linear',
        touchThreshold: 100,
        arrows: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        nextArrow: <FaAngleRight />,
        prevArrow: <FaAngleLeft />,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots__category_slider ">
                <div className="loading" />
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                    nextArrow: <FaAngleRight />,
                    prevArrow: <FaAngleLeft />,

                }
            }
        ]
    };





    const { data, isLoading, isError } = useGetHighlightedCourseCategoriesQuery();
    const { data: responseData } = data || {};
    const { data: highlightedCourseCategories } = responseData || {};


    let courseCategoryItems = null;

    if (isLoading) {
        courseCategoryItems =
            <>
                <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                    <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                </div>
            </>
        settings.infinite = false
    } else if (!isLoading && isError) {
        courseCategoryItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && highlightedCourseCategories?.length === 0) {
        courseCategoryItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && highlightedCourseCategories?.length > 0) {
        courseCategoryItems = highlightedCourseCategories.map(category => <CourseCategoryItem key={category.id} category={category} />);

        if (
            (highlightedCourseCategories.length <= 6 && settings.responsive[0].breakpoint >= 1024) ||
            (highlightedCourseCategories.length <= 3 && settings.responsive[1].breakpoint === 800) ||
            (highlightedCourseCategories.length <= 2 && settings.responsive[2].breakpoint === 600)
        ) {
            settings.infinite = false
        }

    }





    return (
        <div className='max-w-[1265px] mx-auto'>
            <div className='pb-7 text-[#292d60]'>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    Highlighted Category
                    <a className='float-right mr-5 font-normal normal-case' href="/category-page">
                        view all <FaArrowRight className='inline h-3 mb-1' />
                    </a>
                </h1>
                <div className='mx-20'>
                    <Slider {...settings}>
                        {
                            courseCategoryItems
                        }
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default CourseCategories;