import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaArrowRight } from 'react-icons/fa';
import LatestCourseItem from './LatestCourseItem';

import CourseSkeleton from '../../../ui/Skeleton/CourseSkeleton';
import { useGetLatestCoursesQuery } from '../../../../features/courses/coursesAPI';
import Pagination from '../../../ui/Pagination';



const LatestCourseAll = () => {
    const [page, setPage] = useState(1);


    const { data, isLoading, isError } = useGetLatestCoursesQuery(page);
    const { data: responseData } = data || {};
    const { data: latestCourses, meta } = responseData || {};

    const { current_page, per_page, total } = meta || {};

    const perPageItems = per_page;
    const totalItem = total;
    const currentPage = current_page;
   

    const handlePaginate = (page) => {
        setPage(page)
    }

    let latestCoursesItems = null;

    if (isLoading) {
        latestCoursesItems = <>
            <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
                <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            </div>
        </>

    } else if (!isLoading && isError) {
        latestCoursesItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && latestCourses?.length === 0) {
        latestCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && latestCourses?.length > 0) {
        latestCoursesItems = latestCourses.map(latestCourse => <LatestCourseItem key={latestCourse.id} latestCourse={latestCourse} />);


    }





    return (
        <div className='pb-7 mx-auto max-w-[1265px]'>
            <div className='text-[#292d60]'>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    Latest Courses

                </h1>
            </div>
            <div className="grid grid-cols-4 gap-4">
                {
                    latestCoursesItems
                }
            </div>
            {
                latestCourses?.length > 0 &&
                (
                    perPageItems < totalItem &&
                    <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate} />
                )
            }
        </div>
    );
};

export default LatestCourseAll;