import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    accessToken: undefined,
    user: undefined,
    accountVerifying: false,
    accountVerifyStatus: 0,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.user = action.payload.user;
        },
        userLoggedOut: (state) => {
            state.accessToken = undefined;
            state.user = undefined;
        },
        setAccountVerifyInfo: (state, action) => {
            state.accountVerifying = action.payload?.accountVerifying;
            state.accountVerifyStatus = action.payload?.accountVerifyStatus;
        }
    }
});

export const { 
    userLoggedIn, 
    userLoggedOut,
    setAccountVerifyInfo,
} = authSlice.actions;

export default authSlice.reducer;