import { apiSlice } from "../api/apiSlice";

export const examsAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getExams: builder.query({
            query: ({categorySlugs, examYears, feeTypes}) => {

                let categorySlugQuery = '';

                if (categorySlugs?.length > 0) {
                    categorySlugs.map((slug, index) => {
                        if (index === 0) {
                            categorySlugQuery += `category_slug[]=${slug}`;
                        } else {
                            categorySlugQuery += `&category_slug[]=${slug}`;
                        }
                    });

                    categorySlugQuery += '&';
                }


                let examYearQuery = '';

                if (examYears?.length > 0) {
                    examYears.map((year, index) => {
                        if (index === 0) {
                            examYearQuery += `exam_year[]=${year}`;
                        } else {
                            examYearQuery += `&exam_year[]=${year}`;
                        }
                    });

                    examYearQuery += '&';
                }


                let feeTypeQuery = '';

                if (feeTypes?.length > 0) {
                    feeTypes.map((feeType, index) => {
                        if (index === 0) {
                            feeTypeQuery += `fee_type[]=${feeType}`;
                        } else {
                            feeTypeQuery += `&fee_type[]=${feeType}`;
                        }
                    });

                    feeTypeQuery += '&';
                }

                return `api/v1/exams?${categorySlugQuery + examYearQuery + feeTypeQuery}`
            },
            transformResponse(apiResponse) {
                const { status, data } = apiResponse || {};
                const { data: exams, meta } = data || {};

                if (status) {
                    return {
                        exams: exams,
                        currentPage: meta?.current_page,
                        lastPage: meta?.last_page,
                        perPage: meta?.per_page,
                        totalExams: meta?.total,
                    };
                }
            },
            providesTags: ["Exams"],
        }),
        getMoreExams: builder.query({
            query: ({categorySlugs, examYears, feeTypes, page}) => {

                let categorySlugQuery = '';

                if (categorySlugs?.length > 0) {
                    categorySlugs.map((slug, index) => {
                        if (index === 0) {
                            categorySlugQuery += `category_slug[]=${slug}`;
                        } else {
                            categorySlugQuery += `&category_slug[]=${slug}`;
                        }
                    });

                    categorySlugQuery += '&';
                }


                let examYearQuery = '';

                if (examYears?.length > 0) {
                    examYears.map((year, index) => {
                        if (index === 0) {
                            examYearQuery += `exam_year[]=${year}`;
                        } else {
                            examYearQuery += `&exam_year[]=${year}`;
                        }
                    });

                    examYearQuery += '&';
                }


                let feeTypeQuery = '';

                if (feeTypes?.length > 0) {
                    feeTypes.map((feeType, index) => {
                        if (index === 0) {
                            feeTypeQuery += `fee_type[]=${feeType}`;
                        } else {
                            feeTypeQuery += `&fee_type[]=${feeType}`;
                        }
                    });

                    feeTypeQuery += '&';
                }

                return `api/v1/exams?${categorySlugQuery + examYearQuery + feeTypeQuery}&page=${page}`
            },
            async onQueryStarted({categorySlugs, examYears, feeTypes}, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const { data: examData } = data || {};
                    const { data: exams } = examData || {};

                    if (exams?.length > 0) {
                        dispatch(
                            apiSlice.util.updateQueryData('getExams', {categorySlugs, examYears, feeTypes}, (draft) => {
                                return {
                                    exams: [
                                        ...draft?.exams,
                                        ...exams,
                                    ],
                                    currentPage: Number(draft?.currentPage),
                                    lastPage: Number(draft?.lastPage),
                                    perPage: Number(draft?.perPage),
                                    totalExams: Number(draft?.totalExams),
                                };
                            })
                        )
                    }
                } catch (err) {}
            },
            providesTags: ["MoreExams"],
        }),
        getParentExamCategories: builder.query({
            query: () => `/api/v1/exam-categories/parent`,
        }),
        getExamCategoriesByParent: builder.query({
            query: (parentCategoryId) => `/api/v1/exam-categories/parent/${parentCategoryId}`,
        }),
        getExamYears: builder.query({
            query: () => '/api/v1/exam-years',
        }),
        getExamDetails : builder.query({
           query: (slug) => `api/v1/exams/${slug}`
        }),
        getChapterDetails : builder.query({
            query: ({chapterSlug, page = 1}) => `api/v1/exam/quizzes-by-chapter/${chapterSlug}?page=${page}`,
        }),
        getQuizTestQuizzes : builder.query({
            query: ({quizTestId, page = 1}) => `api/v1/exam/exam-quiz-test-quizzes/${quizTestId}?page=${page}`,
        }),
        createExamQuizTest: builder.mutation({
            query: (data) => ({
                url: "/api/v1/exam/exam-quiz-test",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
        }),
    })
});


export const { 
    useGetParentExamCategoriesQuery,
    useGetExamCategoriesByParentQuery,
    useGetExamYearsQuery,
    useGetExamsQuery,
    useGetMoreExamsQuery,
    useGetExamDetailsQuery,
    useGetChapterDetailsQuery,
    useGetQuizTestQuizzesQuery,
    useCreateExamQuizTestMutation
 } = examsAPI;