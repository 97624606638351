import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showSearch: false,
  };



const showSearchSlice = createSlice({
    name: 'showSearch',
    initialState,
    reducers: {
      handleSearch: (state) => {
        state.showSearch = !state.showSearch;
      },
    },
  });

  export const { handleSearch } = showSearchSlice.actions;

export default showSearchSlice.reducer;