import React from 'react'

const PaymentMethod = ({paymentMethod, setPaymentMethodId}) => {

    const { id, name, logo } = paymentMethod || {};
    const paymentMethodLogo = process.env.REACT_APP_BASE_URL + '/' + logo;

    return (
        <label htmlFor={id} className='my-4 border p-4 flex justify-between cursor-pointer'>
            <div className='flex items-center gap-2'>
                <input type="radio" name="paymentMethod" id={id} value={id || ''} onChange={() => setPaymentMethodId(id)} />
                <span>{name}</span>
            </div>
            <img src={logo && paymentMethodLogo} className="h-[25px]" alt="logo" />
        </label>
    )
}

export default PaymentMethod