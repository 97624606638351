import { apiSlice } from "../api/apiSlice";

export const coursesAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPopularCourses: builder.query({
            query: (page = 1) => `/api/v1/courses/popular?page=${page}`,
        }),
        getLatestCourses: builder.query({
            query: (page = 1) => `/api/v1/courses/latest?page=${page}`,
        }),
        getTrendingCourses: builder.query({
            query: (page = 1) => `/api/v1/courses/trending?page=${page}`,
        }),
        getCoursesByCategory: builder.query({
            query: ({categorySlug = '', feeType = ''}) => `api/v1/${categorySlug}/courses?fee_type=${feeType}`,
            transformResponse(apiResponse) {
                const { data, status } = apiResponse || {};
                const { data: courses } = data || {};

                if (status) {
                    return {
                        courses: courses,
                        currentPage: data?.meta?.current_page,
                        lastPage: data?.meta?.last_page,
                        perPage: data?.meta?.per_page,
                        totalCourses: data?.meta?.total,
                    };
                }
            },
            providesTags: ["CoursesByCategory"],
        }),
        getMoreCoursesByCategory: builder.query({
            query: ({categorySlug, feeType, page}) => `api/v1/${categorySlug}/courses?fee_type=${feeType}&page=${page}`,
            async onQueryStarted({categorySlug, feeType}, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const { data: coursesData } = data || {};
                    const { data: courses } = coursesData || {};

                    if (courses?.length > 0) {
                        dispatch(
                            apiSlice.util.updateQueryData('getCoursesByCategory', {categorySlug, feeType}, (draft) => {
                                return {
                                    courses: [
                                        ...draft?.courses,
                                        ...courses,
                                    ],
                                    currentPage: Number(draft?.currentPage),
                                    lastPage: Number(draft?.lastPage),
                                    perPage: Number(draft?.perPage),
                                    totalCourses: Number(draft?.totalCourses),
                                };
                            })
                        )
                    }
                } catch (err) {}
            },
            providesTags: ["MoreCoursesByCategory"],
        }),
        getCourseTags: builder.query({
            query: () => '/api/v1/course-tags',
        }),
        getCoursesByTag: builder.query({
            query: (tagId) => `/api/v1/courses-by-tag/${tagId}`,
        }),
        getCourseDetails: builder.query({
            query: (slug) => `/api/v1/courses/${slug}`,
        }),
        getCourseIntroductions: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/introductions`,
        }),
        getCourseInstructors: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/instructors`,
        }),
        getCourseOutcomes: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/outcomes`,
        }),
        getCourseFAQs: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/faqs`,
        }),
        getCourseTopics: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/topics`,
        }),
        getCourseTopicLessons: builder.query({
            query: (topicId) => `/api/v1/courses/topic/${topicId}/lessons`,
        }),
        getCourseLessons: builder.query({
            query: (slug) => `/api/v1/courses/${slug}/lessons`,
        }),
        getAccountCourses: builder.query({
            query: ({ search, page }) => {

                let query = '';

                if (search !== '' || search !== null || search !== undefined) {
                    query +=`title=${search}`;
                }

                if (page !== '' || page !== null || page !== undefined) {
                    query +=`&page=${page}`;
                }

                return `/api/v1/account/courses?${query}`;
            },
            providesTags: ["Courses"],
            transformResponse(apiResponse) {
                const { data, status } = apiResponse || {};
                const { data: courses } = data || {};

                if (status) {
                    return {
                        courses: courses,
                        currentPage: data?.current_page,
                        perPage: data?.per_page,
                        totalCourses: data?.total,
                    };
                }
            },
        }),
        getAccountCourse: builder.query({
            query: () => '/api/v1/account/courses',

        }),
        getAllCourses: builder.query({
            query: (page = 1) => `/api/v1/courses/all-courses?page=${page}`,
        }),

    })
});

export const { 
    useGetPopularCoursesQuery, 
    useGetLatestCoursesQuery, 
    useGetTrendingCoursesQuery,
    useGetCoursesByCategoryQuery,
    useGetCourseTagsQuery,
    useGetCoursesByTagQuery,
    useGetCourseDetailsQuery,
    useGetCourseIntroductionsQuery,
    useGetCourseInstructorsQuery,
    useGetCourseOutcomesQuery,
    useGetCourseFAQsQuery,
    useGetCourseTopicsQuery,
    useGetCourseLessonsQuery,
    useGetCourseTopicLessonsQuery,
    useGetAccountCoursesQuery,
    useGetAccountCourseQuery,
    useGetAllCoursesQuery
} = coursesAPI;