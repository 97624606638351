import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 courseData: '',

};

const coursesSlice = createSlice({
    name: "accountCourse",
    initialState,
    reducers: {
      
    }
});

export const { 
    setAccountCourse, 
} = coursesSlice.actions;

export default coursesSlice.reducer;