import { apiSlice } from "../api/apiSlice";
import { setAccountVerifyInfo } from "./authSlice";

export const authAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (data) => ({
                url: "/api/v1/register",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            }),
        }),
        accountVerify: builder.mutation({
            query: ({verifyToken, hashEmail}) => ({
                url: `/api/v1/account/verify/${verifyToken}/${hashEmail}`,
                method: "POST",
                headers: {
                  accept: 'application/json',
                },  
            }),
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const { data } = await queryFulfilled || {};
                    const { status } = data || {};

                

                    dispatch(setAccountVerifyInfo({
                        accountVerifying: true,
                        accountVerifyStatus: status,
                    }));
                } catch (error) {
                    // CATCH FROM UI
                }
            }
        }),
        forgotPassword: builder.mutation({
            query: ({phone}) => ({
                url: `/api/v1/account/forgot-password/${phone}`,
                method: "POST",
                headers: {
                  accept: 'application/json',
                },  
            })
        }),
        resetPassword: builder.mutation({
            query: (data) => ({
                url: `/api/v1/account/reset-password/${data?.phone}`,
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },  
            })
        }),
        login: builder.mutation({
            query: (data) => ({
                url: "/api/v1/login",
                method: "POST",
                body: data,   
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: "/api/v1/logout",
                method: "POST", 
            })
        }) 
    })
});

export const { 
    useLoginMutation, 
    useRegisterMutation,
    useAccountVerifyMutation,
    useLogoutMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation
} = authAPI;