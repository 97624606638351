import React from 'react'
import { FaYoutube } from "react-icons/fa";

const VideoSkeleton = ({ height = '400px', width = '100%' }) => {
    return (
        <div className='bg-gray-300 flex items-center justify-center animate-pulse' style={{ height, width }}>
            <FaYoutube className='text-[70px] text-gray-400' />
        </div>
    )
}

export default VideoSkeleton