import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoiceNo: '',
};

const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setInvoiceNo: (state, action) => {
            state.invoiceNo = action.payload;
        },
    }
});

export const {
    setInvoiceNo
} = checkoutSlice.actions;

export default checkoutSlice.reducer;