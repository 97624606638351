import React, { useEffect, useState } from 'react'
import { BsLock } from 'react-icons/bs';
import { FaVideo } from 'react-icons/fa';
import { GrAttachment } from 'react-icons/gr';

import ViewVideoModal from './ViewVideoModal';


const Lesson = ({ lesson }) => {

    const [openModal, setOpenModal] = useState(false)



    const handleModal = () => {
        setOpenModal(!openModal)
    }


    const { title, duration, isFree, isVideo, isAttachment } = lesson || {};

  
  


    // useEffect(() => {
    // if (
    //    openModal === true && isFree === 1
    // ) {
    // document.body.style.overflow = "hidden";
    // return () => (document.body.style.overflow = "scroll");
    // }
    // });

    return (
        <>
            {/* <li className='space-x-2 my-1' onClick={()=> dispatch(handleFreeVideoShow())}> */}
            <li className='space-x-2 my-1 cursor-pointer hover:bg-[#1e2158] hover:text-white p-2 hover:rounded-md duration-200' onClick={handleModal}>

                {
                    !isFree
                        ?
                        <BsLock className='inline-block mr-2' />
                        : (
                            isVideo
                                ?
                                <FaVideo className='inline-block mr-2' />
                                : (
                                    isAttachment
                                        ?
                                        <GrAttachment className='inline-block mr-2' />
                                        : ''
                                )
                        )
                }
                {title}
                <span className='float-right'>{duration}</span>

            </li>



            {openModal && (isFree === 1 && isVideo === 1 ) ? (
                
                <ViewVideoModal lesson={lesson} handleModal={handleModal}  />
               
            ) : null}

        </>
    )
}

export default Lesson