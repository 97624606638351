import React from 'react';
import defaultImage from '../../assets/images/instructor.jpg';
import { Link } from 'react-router-dom';

const InstructorListItems = ({ instructor }) => {

    const { first_name, last_name, username, image,address_1, phone,email, user_social_link } = instructor || {};
    const instructorImage = process.env.REACT_APP_BASE_URL + '/' + image;

    return (
        <div className='justify-self-stretch self-stretch'>
            {/* <div className='max-w-full min-h-full'> */}
                <div className="bg-white shadow-xl rounded-lg py-3 min-h-full">
                    <div className="photo-wrapper p-2">
                        <img class="w-32 h-32 rounded-full mx-auto" src={(image === 'assets/img/avatar.png' ||image === null ) ? defaultImage : instructorImage} alt="John Doe" />
                    </div>
                    <div className="p-2">
                        <h3 class="text-center text-xl text-gray-900 font-medium leading-8">{first_name} </h3>
                        <div className="text-center text-gray-400 text-xs font-semibold">
                        {last_name}
                        </div>
                        <table class="text-xs my-3">
                            <tbody><tr>
                                <td class="px-2 py-2 text-gray-500 font-semibold">Address</td>
                                <td class="px-2 py-2">{address_1}</td>
                            </tr>
                                <tr>
                                    <td class="px-2 py-2 text-gray-500 font-semibold">Phone</td>
                                    <td class="px-2 py-2">{phone}</td>
                                </tr>
                                <tr>
                                    <td class="px-2 py-2 text-gray-500 font-semibold">Email</td>
                                    <td class="px-2 py-2">{email}</td>
                                </tr>
                            </tbody></table>

                        {
                            user_social_link?.length > 0 && 
                            <div className='w-fit m-auto space-x-2'>
                                {
                                    user_social_link?.map(social => (
                                        <>

                                       <Link to={social.url} target="_blank">
                                        <span style={{ color: `${social.foreground_color}`}}>
                                            <ion-icon size="large" name={social.icon} ></ion-icon>
                                        </span></Link>

                                        </>
                                    ))
                                }
                            </div>
                        }

                        <div className="text-center my-3">
                            <Link class="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium" to={`/instructors/${username}`}>View Profile</Link>
                        </div>

                    </div>
                </div>
            {/* </div> */}

        </div>
    );
};

export default InstructorListItems;