import React, { useState, useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import instuctor from '../../assets/images/instructor.png';
import TermsAndCondition from '../../pages/Instructor/TermsAndCondition';
import ImageSkeleton from '../ui/Skeleton/ImageSkeleton';
import CourseSkeleton from '../ui/Skeleton/CourseSkeleton';
import { Link } from 'react-router-dom';



const BecomeInstructor = () => {
    // const [showTerms, setShowTerms] = useState(false);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        setIsLoading(true);
        fetch(`${baseUrl}/api/v1/become-instructor`)
          .then(response => response.json())
          .then(data => {
            setData(data);
            setIsLoading(false);
          })
          .catch(error => {
            setError(error);
            setIsLoading(false);
          });
      }, []);
    
      if (isLoading) {
        return <div><CourseSkeleton/></div>;
      }
    
      if (error) {
        return <div>Error: {error.message}</div>;
      }
    
      if (!data) {
        return null;
      }
    

      const {id, title, image, short_description} = data?.data || {};

      const Image = process.env.REACT_APP_BASE_URL + '/' + image;



    return (
        <div className='pt-10 mb-10 mx-auto max-w-[1265px]'>
            <div className='lg:flex gap-10'>
                <div className='lg:w-1/2 mx-auto'>
                    <div>
                       {
                        isLoading ? <><p>loading hoitace re vai...</p></>
                        :
                        <img src={Image} alt="" className='h-fit w-fit  mx-auto mb-10 lg:mb-0' />
                       }
                    </div>
                </div>
                <div className="lg:w-1/2 m-auto">
                    <div className='w-full text-center lg:text-left text-[#292d60]'>
                        <h1 className='text-4xl font-semibold uppercase'>{title}</h1>
                        <p className='text-gray-400 mt-5'>{short_description}</p>
                        <button className="mt-10 relative border-2 rounded-full border-[#292d60] bg-transparent py-2 px-3 font-medium uppercase  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-[#292d60] before:transition-transform before:duration-300 before:content-[''] before:rounded-full hover:text-white before:hover:scale-x-100">


                            <span className="">
                            <Link to={"/instructor-registration"}>Become Instructor</Link>
                                <FaArrowRight className='ml-2 inline animate-animation-bounch-left ' />
                            </span>


                        </button>
                      
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomeInstructor;