import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


const PDFViewer = ({attachment}) => {

	const defaultLayoutPluginInstance = defaultLayoutPlugin();

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.js">
			<div style={{ height: '100vh' }}>
				<Viewer
					fileUrl={`https://admin.edubd.tv/api/v1/courses/topics/lessons/${attachment}`}
					plugins={[
						defaultLayoutPluginInstance,
					]}
				/>
			</div>
		</Worker>
	)
};


export default PDFViewer;