import React, { useEffect, useState } from 'react';
import SEO from '../../components/ui/SEO';
import style from './TermsAndCondition.module.css';
import DOMPurify from 'dompurify';
import TextSkeleton from '../../components/ui/Skeleton/TextSkeleton';


const TermsAndCondition = () => {
    const [data, setData] = useState('');
    const [isLoading, setIsLoading] = useState(true);


    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        fetch(`${baseUrl}/api/v1/terms-and-condition`)
          .then(response => response.json())
          .then(data => {
            setData(data?.data);
            setIsLoading(false); // set isLoading to false when data is fetched
          })
          .catch(error => console.log(error));
      }, []);
    
      if (isLoading) {
        return <> 
        <div className="bg-white rounded-lg shadow p-4">
          <div className="animate-pulse flex space-x-4">
            <div className=" bg-gray-400 h-12 w-12"></div>
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-gray-400 rounded w-3/4"></div>
              <div className="space-y-2">
                <div className="h-4 bg-gray-400 rounded"></div>
                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
              </div>
            </div>
          </div>
        </div>
        </>;
      }



    //   console.log(data);
    return (
        <>
            <SEO
                title="Terms And Condition"
                url="/terms-and-condition"
            />
            <div className={`mt-10 container m-auto max-w-[1260px] px-5`}>

                <div className={` ${style.size}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.description) }}
                />
            </div>


        </>
    );
};

export default TermsAndCondition;