import React from 'react';
import { BsCameraVideo } from 'react-icons/bs';

const VideoModalDataLIst = ({ videos, setCurrentVideo, setCurrentTitle,currentTitle, currentVideo }) => {

    const { title, duration, video, isVideo } = videos || {};


    return (
        <>
        {
isVideo === 1 && 
<li className={`${ video === currentVideo ? 'bg-[#494A4C] text-blue': ''} rounded-md text-white text-md`} onClick={() => setCurrentVideo(video)}>
                <div className={`px-4 py-3  flex justify-between items-center`}>
                    <div className='flex items-center gap-4'>
                        <BsCameraVideo className='w-5 h-5'/>
                        <h1 onClick={() => setCurrentTitle(title)}>{title}</h1>
                    </div>
                    <p>{duration}</p>
                </div>
            </li>
        }

            
        </>
    );
};

export default VideoModalDataLIst;