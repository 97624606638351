import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mobileCategory: false,
    
  };



const showMobileCategorySlice = createSlice({
    name: 'mobileCategory',
    initialState,
    reducers: {
      handleMobileCategory: (state) => {
        state.mobileCategory = !state.mobileCategory;

      },

    },
  });

  export const { handleMobileCategory } = showMobileCategorySlice.actions;

export default showMobileCategorySlice.reducer;