import React from 'react';
import course1 from '../../assets/images/course1.jpg';
import course2 from '../../assets/images/course2.jpg';
import course3 from '../../assets/images/course3.jpeg';
const WishList = () => {
return (
<div>
    <div className='px-10 divide-y'>
        <div className='text-center'>
            <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>WishList</h1>

        </div>

    </div>
    <div className="flex flex-col px-10">
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden ">
                    <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                        <thead className="bg-gray-100 dark:bg-gray-700">
                            <tr>

                                <th scope="col"
                                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                    Image
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                    Course Name
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                                    Price
                                </th>
                                <th scope="col"
                                    className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400">
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">

                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <img className='lg:w-[200px] lg:h-[150px]' src={course1} alt="" /></td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                    Web Development</td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    $60</td>
                                <td className="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white text-center">
                                    <button
                                        className='bg-[#192b3d] text-white pl-3 pr-3 py-1 ml-3 rounded-full w-[110px] mb-3'>Add
                                        To Cart</button>
                                        <button
                                        className='bg-[#d3372f] text-white pl-3 pr-3 py-1 ml-3 rounded-full'>Remove</button>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">

                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <img className='lg:w-[200px] lg:h-[150px]' src={course2} alt="" /></td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                    Web Development</td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    $60</td>
                                <td className="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white text-center">
                                    <button
                                        className='bg-[#192b3d] text-white pl-3 pr-3 py-1 ml-3 rounded-full w-[110px] mb-3'>Add
                                        To Cart</button>
                                        <button
                                        className='bg-[#d3372f] text-white pl-3 pr-3 py-1 ml-3 rounded-full'>Remove</button>
                                </td>
                            </tr>
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">

                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <img className='lg:w-[200px] lg:h-[150px]' src={course3} alt="" /></td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap dark:text-white">
                                    Web Development</td>
                                <td
                                    className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    $60</td>
                                <td className="py-4 px-6 text-sm font-medium text-gray-900 dark:text-white text-center">
                                    <button
                                        className='bg-[#192b3d] text-white pl-3 pr-3 py-1 ml-3 rounded-full w-[110px] mb-3'>Add
                                        To Cart</button>
                                        <button
                                        className='bg-[#d3372f] text-white pl-3 pr-3 py-1 ml-3 rounded-full'>Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
);
};

export default WishList;