import axios from 'axios';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { LoginSocialGoogle } from 'reactjs-social-login';

const SocialLoginGoogle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['EduTvAuth']);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const prevPathname = localStorage.getItem('prevPathname');
  const prevPathParse = JSON.parse(prevPathname);
  const sendDataToBackend = async (userData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${baseUrl}/api/v1/google-signin`, userData);
      // console.log(response.data);
      setCookie('EduTvAuth', { accessToken: response?.data?.accessToken, user: response?.data?.user }, { path: '/' }); // Handle the response from the backend
      setIsLoading(false);
      navigate(0);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>

      <LoginSocialGoogle
        client_id={"483460150773-pdrotlk9pdctdhobhderqmjolpiilvhe.apps.googleusercontent.com"}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={({ data }) => {
          const userData = {
            first_name: data?.given_name,
            last_name: data?.family_name,
            email: data?.email,
            is_verified: data?.email_verified === true ? 1 : 0,
            image: data?.picture,
            access_token: data?.access_token
          };
          sendDataToBackend(userData);
          // console.log(userData);


        }}
        onReject={(err) => {
          // console.log(err);
        }}
      >
        {
          isLoading ?
            <>
              <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Sign in with Google
              </button>

            </>
            :
            <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
              <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M896 786h725q12 67 12 128 0 217-91 387.5t-259.5 266.5-386.5 96q-157 0-299-60.5t-245-163.5-163.5-245-60.5-299 60.5-299 163.5-245 245-163.5 299-60.5q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65t-173.5 176.5-64 243.5 64 243.5 173.5 176.5 238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78h-436v-264z">
                </path>
              </svg>
              Sign in with Google
            </button>
        }
      </LoginSocialGoogle>
    </>
  );
};

export default SocialLoginGoogle;