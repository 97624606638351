import React from 'react'
import { Outlet, useParams } from 'react-router-dom';
import Topics from '../../../components/account/course/topic/Topics';

const CurrentPlaying = () => {

    const { slug } = useParams();


    return (
        <>
            <div className='grid lg:grid-cols-3 gap-5'>
                <div className='lg:col-span-2'>
                    <div className='flex flex-col gap-2'>
                        <Outlet />
                    </div>
                </div>
                <div className='flex flex-col '>
                    <Topics slug={slug} />
                </div>
            </div>
        </>
    );
}

export default CurrentPlaying