import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categorySlugs: [],
    examYears: [],
    feeTypes: [],
};

const examsSlice = createSlice({
    name: "exam",
    initialState,
    reducers: {
        setCategorySlugs: (state, action) => {
            const itemIndex = state.categorySlugs.findIndex(item => item === action.payload);

            if (itemIndex === -1) {
                state.categorySlugs = [...state.categorySlugs, action.payload];
            }
        },
        removeCategorySlug: (state, action) => {
            state.categorySlugs = state.categorySlugs.filter(item => item !== action.payload);
        },
        setExamYears: (state, action) => {
            const itemIndex = state.examYears.findIndex(item => item === action.payload);

            if (itemIndex === -1) {
                state.examYears = [...state.examYears, action.payload];
            }
        },
        removeExamYear: (state, action) => {
            state.examYears = state.examYears.filter(item => item !== action.payload);
        },
        setFeeTypes: (state, action) => {
            const itemIndex = state.feeTypes.findIndex(item => item === action.payload);

            if (itemIndex === -1) {
                state.feeTypes = [...state.feeTypes, action.payload];
            }
        },
        removeFeeType: (state, action) => {
            state.feeTypes = state.feeTypes.filter(item => item !== action.payload);
        },
    }
});

export const {
    setCategorySlugs,
    removeCategorySlug,
    setExamYears,
    removeExamYear,
    setFeeTypes,
    removeFeeType
} = examsSlice.actions;

export default examsSlice.reducer;