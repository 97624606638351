import React from 'react'
import { IoIosArrowDown } from 'react-icons/io';
import { Link } from 'react-router-dom';
import ChildCategories from './ChildCategories';
import style from './Category.module.css';

const ParentCategory = ({category}) => {

    const { id, name,slug, childCount } = category || {};
   

    return (
        <li className={`${style.parent}`}>
            <span 
                className="py-4 px-2 text-[13.5px] font-semibold hover:text-[#292d60] duration-300 flex space-x-4 items-center "
            >
                <Link to={`/${slug}/courses`}>{name}</Link>
               { childCount > 0 ?
                <IoIosArrowDown 
                className={`${style.arrowbutton}`} 
            /> 
            :
            <></>

               }
            </span>
           {
            childCount > 0 ?
           <>
             <ChildCategories parentId={id} />
             </>
            :
            <></>
           }
        </li>
    )
}

export default ParentCategory