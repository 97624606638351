import React from 'react'
import { useGetShowInMenuCourseCategoriesByParentQuery } from '../../../../features/courseCategories/courseCategoriesAPI';
import ChildCategory from './ChildCategory';
import style from './MobileCategory.module.css';



const ChildCategories = ({ parentId, isOpen, isSubOpen }) => {


    const { data, isLoading, isError } = useGetShowInMenuCourseCategoriesByParentQuery(parentId);
    const { data: responseData } = data || {};
    const { data: showInMenuCourseCategoriesByParent } = responseData || {};

    let content = null;

    if (!isLoading && !isError && showInMenuCourseCategoriesByParent?.length > 0) {
        content = showInMenuCourseCategoriesByParent.map(category => <ChildCategory key={category?.id} category={category}  />);
    }


    if(isOpen === true){
        isSubOpen = false
       
    }else if(isSubOpen === true )
    {
        isOpen = true
       
    }
    


    return (
        <>
        
            { 
                content
            }
            
        
        </>
    )
}

export default ChildCategories