import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginBackground from '../../assets/images/coursebanner.jpg';
import { VscEyeClosed } from 'react-icons/vsc';
import { VscEye } from 'react-icons/vsc';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../features/auth/authAPI';
import { userLoggedIn } from '../../features/auth/authSlice';
import AlertMessage from '../../components/ui/AlertMessage';
import SEO from '../../components/ui/SEO';
import SocialLoginGoogle from './SocialLoginGoogle';





const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['EduTvAuth']);

    const { btoa } = window;


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const prevPathname = localStorage.getItem('prevPathname');
    const prevPathParse = JSON.parse(prevPathname);






    const [login, { data, isLoading, error }] = useLoginMutation();





    useEffect(() => {

        if (error?.data?.errors?.password) {

            setAlertMessage(error?.data?.errors?.password);
            setAlertMessageType('error');

        } else if (error?.data?.errors?.email) {

            setAlertMessage(error?.data?.errors?.email);
            setAlertMessageType('error');

        } else if (data?.status === 1) {

            setAlertMessage(data?.message);
            setAlertMessageType('success');
            setEmail('');
            setPassword('');
            setCookie('EduTvAuth', { accessToken: data?.accessToken, user: data?.user }, { path: '/' });
            navigate(prevPathParse || 0);

            navigate(0);



        } else if (data?.message === "Account is not verify! we sent a verify email and sms otp!") {
            setAlertMessage(data?.message);
            setAlertMessageType('error');

            const phone = btoa(data?.user_phone);
            window.location.href = `account/verify-otp/${phone}`;
            removeCookie('EduTvAuth', { path: '/' });
        } else {
            if (data?.status === 0) {
                setAlertMessage(data?.message);
                setAlertMessageType('error');
            }
        }

        // setTimeout(() => setAlertMessage(''), 5000);

    }, [data, error, setCookie, removeCookie, dispatch, userLoggedIn]);


    // console.log(data?.message);



    const handleSubmit = (e) => {
        e.preventDefault();

        setAlertMessage('');
        setAlertMessageType('');

        login({
            email,
            password,
        })
    }

    return (
        <>
            <SEO
                title="Login"
                url="/login"
            />
            <div style={{ backgroundImage: `url(${LoginBackground})`, backgroundSize: 'cover' }}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <h1 className="text-3xl font-semibold text-center text-[#192b3d] ">
                            Sign in
                        </h1>
                        {
                            alertMessage !== '' && <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage} /></div>
                        }
                        <form onSubmit={handleSubmit} className="mt-6">
                            <div className="mb-4">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-semibold text-gray-800"
                                >
                                    Email/Phone <sup className='text-red-500'>*</sup>
                                </label>
                                <input
                                    type="text"
                                    className={`${error?.data?.errors?.email||error?.data?.errors?.phone || data?.status === 0 ? 'border-red-500' : '' } block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40`}
                                    id='email'
                                    name='email'
                                    value={email || ''}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="relative mb-2">
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-semibold text-gray-800"
                                >
                                    Password <sup className='text-red-500'>*</sup>
                                </label>
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    className={`${error?.data?.errors?.email ||error?.data?.errors?.phone|| data?.status === 0 ? 'border-red-500' : '' } relative block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40`}
                                    id='password'
                                    name='password'
                                    value={password || ''}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                                {
                                    passwordShown
                                        ? <VscEyeClosed onClick={() => setPasswordShown(!passwordShown)} className="absolute right-3 top-10" />
                                        : <VscEye onClick={() => setPasswordShown(!passwordShown)} className="absolute right-3 top-10" />
                                }
                            </div>
                            <Link
                                to="/account/forgot-password/"
                                className="text-xs text-[#192b3d] hover:underline"
                            >
                                Forget Password?
                            </Link>

                            <div className="mt-6">
                                {
                                    isLoading
                                        ?
                                        <button
                                            type="button"
                                            className="w-full flex items-center justify-center gap-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                            disabled
                                        >
                                            <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span> Login ... </span>
                                        </button>
                                        :
                                        <button
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                        >
                                            Login
                                        </button>
                                }
                            </div>
                        </form>

                        <p className="mt-8 text-xs font-light text-center text-gray-700">
                            {" "}
                            Don't have an account?{" "}
                            <Link
                                to="/register"
                                className="font-medium text-[#192b3d] hover:underline"
                            >
                                Sign up
                            </Link>
                        </p>
<hr />


                      <div className="mt-2">
                      <SocialLoginGoogle/>
                      {/* <SocialLoginFacebook/> */}
                      </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;