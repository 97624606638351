import React, { useState } from 'react'
import ChildCategories from './ChildCategories';
import { IoIosArrowForward } from 'react-icons/io';
import style from './MobileCategory.module.css';




const ParentCategory = ({ category }) => {

    const [isOpen, setIsOpen] = useState(false)


    const handleIsOpen = () =>{
        setIsOpen(!isOpen)
    }


    const { id, name } = category || {};

    return (
        <>
            <li className={`flex w-full flex-col ${style.parent}`} onClick={handleIsOpen}>
                <span className={`py-3 w-full font-base px-5 hover:bg-blue-600 hover:text-white`}>
                    {name}
                    {
                        <IoIosArrowForward className={`inline 'rotate-90' : ''}  float-right`} />
                    }
                </span>
               {
                isOpen && 
                 <ul className={`${style.parentChild}`}>
                <ChildCategories parentId={id} isOpen={isOpen} />
                </ul>
               }
            </li>
            <hr />
        </>
    )
}

export default ParentCategory