import React from 'react'


const TestimonialItem = ({testimonial}) => {

    const { name, description, image  } = testimonial || {};
    const testimonialImage = process.env.REACT_APP_BASE_URL + '/' + image;

    return (
        <div className='mt-3 cursor-pointer '>
            <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
                <div className="flex justify-center md:justify-end -mt-16">
                    <img src={testimonialImage} className="h-[80px] w-[80px] rounded-full" alt="testimonial" />
                </div>
                <p className="mt-2 text-gray-600 h-40">{description}</p>
                <div className="flex justify-end mt-4">
                    <p className="text-xl font-medium text-indigo-500">{name}</p>
                </div>
            </div>
        </div>
    )
}

export default TestimonialItem