import React from 'react';

const QuizResult = ({result}) => {
    return (
        <div>
            
        </div>
    );
};

export default QuizResult;