import React from 'react'
import SEO from '../../../components/ui/SEO'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Enrollments from '../../../components/account/enrollment/Enrollments';
import Pagination from '../../../components/ui/Pagination';
import { enrollmentsAPI, useGetEnrollmentsQuery } from '../../../features/enrollments/enrollmentsAPI';
import Search from '../../../components/account/enrollment/Search';
import debounceHander from '../../../utils/debounceHandler';

const EnrollmentList = () => {




    const [search, setSearch] = useState('');
    const [paginatePage, setPaginatePage] = useState(1);
    const dispatch = useDispatch();





    const { enrollments, currentPage, perPage, totalEnrollments } = useGetEnrollmentsQuery({search, page: paginatePage}, {
        selectFromResult: ({data}) => ({
            enrollments: data?.enrollments,
            currentPage: data?.currentPage,
            perPage: data?.perPage,
            totalEnrollments: data?.totalEnrollments,
        })
    });


    // console.log(totalEnrollments)





    const doSearch = (query) => {
        setSearch(query);
        dispatch(enrollmentsAPI.endpoints.getEnrollments.initiate({search: query, page: 1}));
    }

    const searchHandler = debounceHander(doSearch, 50);

    



    const handlePaginate = (page) => {
        setPaginatePage(page);
        dispatch(enrollmentsAPI.endpoints.getEnrollments.initiate({search, page}));
    }





    return (
        <>
            <SEO 
                title="Enrollment List"
                description="Enrollment List"
                url="/user/account/enrollments"
            />
            <div className='flex flex-col gap-4'>
                <h3 className='text-xl text-black font-medium'>Enrollment List</h3>
                <Search search={search} searchHandler={searchHandler} />
                <Enrollments enrollments={enrollments} />
                {
                     perPage < totalEnrollments &&
                    <Pagination currentPage={currentPage} perPage={perPage} totalItems={totalEnrollments} handlePaginate={handlePaginate} />
                }
               
            </div>
        </>
    )
}

export default EnrollmentList