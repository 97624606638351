import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiFillFilePdf } from 'react-icons/ai';
import { useCookies } from 'react-cookie';
import { FaSpinner } from 'react-icons/fa';

const CertificateItem = ({ snNo, certificate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [cookies] = useCookies(['EduTvAuth']);

  const auth = cookies?.EduTvAuth;
  const token = auth?.accessToken;

  const {
    id,
    enrollment,
    course,
  } = certificate || {};

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const downloadPdf = async () => {
    setIsLoading(true);
    try {
      // Make an API request to download the PDF here
      const response = await fetch(`${baseUrl}/api/v1/account/lesson/download-certificate/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Define 'token' or retrieve it from somewhere
        },
      });

      if (response.ok) {
        // Successfully downloaded the PDF
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a download link and trigger the download
        const downloader = document.createElement("a");
        downloader.href = url;
        downloader.download = `Certificate-${id}.pdf`;
        downloader.click();

        window.URL.revokeObjectURL(url);
      } else {
        // Handle the case when the API request fails
        console.error('Failed to download PDF');
        // Set your error state or show an alert here
      }
    } catch (error) {
      console.error('Error downloading PDF', error);
      // Set your error state or show an alert here
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <tr className="border-b">
      <td className="py-4 whitespace-nowrap text-sm font-medium text-center text-gray-900">
        {snNo}
      </td>
      <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-center">
        {enrollment.student?.first_name} {enrollment.student?.last_name}
      </td>
      <td className="text-sm text-gray-900 font-light py-4 px-1 whitespace-nowrap text-center">
        {course?.title}
      </td>
      <td className={"py-4 px-1 flex items-center justify-center  "+(isLoading?"animate-spin ":"")}>
        <button disabled={isLoading} onClick={downloadPdf}>
          <span style={{ color: "#c50215", fontSize:"30px",}} >
          {!isLoading?<AiFillFilePdf  />
          :<FaSpinner  />}
          </span>
        </button>
      </td>
    </tr>
  );
}

export default CertificateItem;
