import React from 'react'

const LessonSkeleton = ({ height = '60px' }) => {
    return (
        <div className='flex items-start gap-2 bg-slate-300 py-3 px-5 animate-pulse w-full rounded' style={{ height }}>
            <div className='w-5 h-5 rounded-full bg-slate-100'></div>
            <div className='flex flex-col gap-1.5'>
                <h5 className='h-5 w-32 bg-slate-100 rounded-lg'></h5>
                <small className='h-3 w-10 bg-slate-100 rounded-lg'></small>
            </div>
        </div>
    )
}

export default LessonSkeleton