import React from 'react';
import backgroundTiming from '../../assets/images/backgroundtiming.jpg';


const ExamPage = () => {
    return (
        <div>
            {/* breadcam Timing */}
            <div className='p-10' style={{ backgroundImage: `url(${backgroundTiming})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%' }}>
                <h1 className='text-center text-3xl font-semibold text-white'><span className='font-bold text-orange-400'><ion-icon name="alarm-outline"></ion-icon> Time :</span> 00:24:00</h1>
            </div>
            {/* quiz */}
            <div className='grid lg:grid-cols-4 gap-4 mt-8 p-10'>
                {/* quiz left part */}
                <div className=' lg:col-span-3'>
                    <div className=' rounded-lg bg-white shadow'>
                        <div className='p-3'>
                            <h2 className='font-bold text-2xl'>Question 1:</h2>
                            <h3 className='p-4'>Distance Between Two Stafts shall not be less than____________?</h3>
                        </div>
                        <ul>
                            <li className=' shadow p-4'>
                                <input type="checkbox" className="checkbox checkbox-xs" />
                                <button className='ml-2'> HTML</button>
                            </li>
                            <li className=' shadow p-4'>
                                <input type="checkbox" className="checkbox checkbox-xs" />
                                <button className='ml-2'>CSS</button>
                            </li>
                            <li className=' shadow p-4'>
                                <input type="checkbox" className="checkbox checkbox-xs" />
                                <button className='ml-2'> JavaScript</button>
                            </li>
                            <li className=' shadow p-4'>
                                <input type="checkbox" className="checkbox checkbox-xs" />
                                <button className='ml-2'> Jquery</button>
                            </li>
                        </ul>


                    </div>
                    <div className='mt-5 flex justify-between'>
                        <button className='bg-blue-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6'>Previous</button>
                        <button className='bg-green-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6'>Save and Next</button>
                    </div>
                </div>
                {/* quiz right part */}
                <div>
                    <div className='bg-white shadow rounded-md lg:p-10'>
                        <div className='grid grid-cols-5 gap-3' >
                            <p className='p-3 bg-blue-600 text-white rounded-lg'>Q1</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q2</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q3</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q4</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q5</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q6</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q7</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q8</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q9</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q10</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q11</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q12</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q13</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q14</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q15</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q16</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q17</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q18</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q19</p>
                            <p className='p-3 bg-gray-300 text-black rounded-lg'>Q20</p>
                        </div>

                       <div className=' mt-10'>
                    <ul>
                        <li className='text-gray-400 font-bold'>Not Answered</li>
                        <li className='text-blue-700 font-bold'>Answered</li>
                    </ul>
                       
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamPage;