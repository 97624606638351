import { apiSlice } from "../api/apiSlice";
import { setSubscriberVerifyInfo } from "./subscribersSlice";

export const subscribersAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        storeSubscriber: builder.mutation({
            query: (data) => ({
                url: "/api/v1/subscribers/store",
                method: "POST",
                body: data,
                headers: {
                  accept: 'application/json',
                },   
            }),
        }),
        subscriberVerify: builder.mutation({
            query: ({verifyToken, hashEmail}) => ({
                url: `/api/v1/subscribers/verify/${verifyToken}/${hashEmail}`,
                method: "POST",
                headers: {
                  accept: 'application/json',
                },  
            }),
            async onQueryStarted(arg, {queryFulfilled, dispatch}) {
                try {
                    const { data } = await queryFulfilled || {};
                    const { status } = data || {};

                    dispatch(setSubscriberVerifyInfo({
                        subscriberVerifying: true,
                        subscriberVerifyStatus: status,
                    }));
                } catch (error) {
                    // CATCH FROM UI
                }
            }
        })
    })
});

export const { 
    useStoreSubscriberMutation, 
    useSubscriberVerifyMutation
} = subscribersAPI;