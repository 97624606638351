import React from 'react'
import { FaClock, FaRegListAlt, FaRegQuestionCircle, FaRegWindowRestore, FaVideo } from 'react-icons/fa'
import { useParams } from 'react-router-dom';
import { useGetCourseIntroductionsQuery } from '../../../../features/courses/coursesAPI';

const Introductions = () => {

    const { slug } = useParams();

    const { data, isLoading, isError } = useGetCourseIntroductionsQuery(slug);
    const { data: courseIntroductionData } = data || {};
    const { data: courseIntroductions } = courseIntroductionData || {};

    let introductionContent = null;

    if (isLoading) {
        introductionContent = <div className='text-center text-blue-200 text-lg p-4 font-medium'>Loading...</div>
    } else if (!isLoading && isError) {
        introductionContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>Something went wrong!</div>;
    } else if (!isLoading && !isError && courseIntroductions?.length === 0) {
        introductionContent = <div className='text-center text-red-200 text-lg p-4 font-medium'>No faqs found!</div>;
    } else if (!isLoading && !isError && courseIntroductions?.length > 0) {
        introductionContent = courseIntroductions.map(introduction => {

            const { icon, text } = introduction || {};

            return (
                <p className='flex items-center gap-2'>
                    <ion-icon name={icon} className='inline mr-3'></ion-icon>
                    {text}
                </p>
            )
        })
    }
    return (
        <>
            {/* <p className='mt-10 mb-4'>5000+ people are doing this course</p> */}
            <div className='flex flex-col gap-3 mt-5'>
                {
                    introductionContent
                }
            </div>
        </>
    )
}

export default Introductions